import web3 from 'hooks/web3';
import CopycatLeaderFactoryABI from './abi/CopycatLeaderFactory.json';
import { uniq } from 'lodash';
import { fromWei } from 'utils/unitConversion';
import CopycatLeaderStorage from './CopycatLeaderStorage';
import { getEnv } from 'utils/env';
// import { Contract } from 'web3-eth-contract';

export let BLOCK_PAGINATION = parseInt(getEnv('BLOCK_PAGINATION'));

export default class CopycatLeaderFactory {
  contract: any;
  from: any;

  constructor(from) {
    const COPYCAT_LEADER_FACTORY_ADDRESS = getEnv("LEADER_FACTORY_CONTRACT");

    this.contract = new web3.eth.Contract(
      CopycatLeaderFactoryABI as any,
      COPYCAT_LEADER_FACTORY_ADDRESS
    );
    this.from = from;
  }

  async deployLeader(
    depositFee,
    depositPercentageFee,
    level,
    tokenName,
    tokenSymbol,
    description,
    imageUrl
  ) {
    console.log(
      depositFee,
      depositPercentageFee,
      level,
      tokenName,
      tokenSymbol,
      description,
      imageUrl
    );

    return await this.contract.methods
      .deployLeader(
        depositFee,
        depositPercentageFee,
        level,
        tokenName,
        tokenSymbol,
        description,
        imageUrl
      )
      .send({ from: this.from, value: web3.utils.toWei('0.0001') });
  }

  async deployLeaderGame(
    depositFee,
    depositPercentageFee,
    level,
    tokenName,
    tokenSymbol,
    description,
    imageUrl,
    baseToken,
    depositLimit
  ) {
    console.log(
      depositFee,
      depositPercentageFee,
      level,
      tokenName,
      tokenSymbol,
      description,
      imageUrl
    );

    // TODO: Fix forget baseToken adding

    return await this.contract.methods
      .deployLeaderGame(
        depositFee,
        depositPercentageFee,
        level,
        tokenName,
        tokenSymbol,
        description,
        imageUrl,
        baseToken,
        depositLimit
      )
      .send({ from: this.from, value: web3.utils.toWei('0.0001') });
  }
}

export async function loadOwnedLeaderAddresses(wallet) {
  let contract = new CopycatLeaderFactory(wallet).contract;
  let events = await contract.getPastEvents('NewCopycatLeaderContract', {
    filter: {
      leader: wallet,
    },
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  return events.map((c) => c.returnValues[0]);
}

export async function loadLastLeaderAddresses(mode = 'all') {
  let contract = new CopycatLeaderFactory(null).contract;
  let storage = new CopycatLeaderStorage(null).contract;

  let events = await storage.getPastEvents('NewDeposit', {
    filter: {},
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  let events2 = await contract.getPastEvents('NewCopycatLeaderContract', {
    filter: {},
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  return uniq([
    ...events.map((c) => c.returnValues[0]),
    ...events2.map((c) => c.returnValues[0]),
  ]);
}

export async function loadDepositedLeaderAddresses(wallet) {
  let contract = new CopycatLeaderFactory(null).contract;
  let storage = new CopycatLeaderStorage(null).contract;

  let events = await storage.getPastEvents('NewDeposit', {
    filter: {
      depositer: wallet,
    },
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  let events2 = await contract.getPastEvents('NewCopycatLeaderContract', {
    filter: {
      leader: wallet,
    },
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  return uniq([
    ...events.map((c) => c.returnValues[0]),
    ...events2.map((c) => c.returnValues[0]),
  ]);
}

export async function loadAllLeaderAddresses(mode = 'all') {
  let contract = new CopycatLeaderStorage(null).contract;

  let events: any = await contract.getPastEvents('NewDeposit', {
    filter: {},
    fromBlock: (await web3.eth.getBlockNumber()) - BLOCK_PAGINATION,
    // fromBlock: 0,
    toBlock: 'latest',
  });

  let contractsRaw = events
    .map((c) => ({
      leaderContract: c.returnValues[0],
      level: c.returnValues[1],
      amount: fromWei(c.returnValues[3].toString()),
    }))
    .sort((a, b) =>
      a.level === b.level ? b.amount - a.amount : b.level - a.level
    );

  let contracts: { tier: number; leaderContract: string }[] = [];
  let contractTier = {};
  let masterTierLimit = {};

  for (let contract of contractsRaw) {
    let masterTier = contract.level;

    if (mode === 'star' && masterTier < 1) continue;
    if (mode === 'amateur' && masterTier >= 1) continue;

    if (!(contract.leaderContract in contractTier)) {
      contractTier[contract.leaderContract] = contract.tier;

      if (!masterTierLimit[masterTier]) {
        masterTierLimit[masterTier] = 0;
      }
      masterTierLimit[masterTier] += 1;

      if (
        masterTierLimit[masterTier] <= 25 ||
        (masterTier === 0 && masterTierLimit[masterTier] <= 50)
      ) {
        contracts.push(contract);
      }
    }
  }

  return contracts;
}
