import gameImgConfig from 'config/gameImg';
import React from 'react';

function Gamelist({ data }: { data: string[] }) {
  return (
    <div className="w-full px-2 py-1 bg-bg-dark-light rounded-md flex flex-wrap justify-start">
      {data.map((e) => (
        <a
          key={e}
          href={gameImgConfig[e].link}
          target={'_blank'}
          rel={'noreferrer'}
        >
          <div className="flex justify-center items-center flex-col rounded-md hover:bg-bg-dark-dark transition-all cursor-pointer">
            <img
              className="object-contain h-[4rem]"
              src={gameImgConfig[e].img}
              alt={e}
            />
            <span className="text-lg">{e}</span>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Gamelist;
