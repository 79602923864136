// eslint-disable-next-line import/no-anonymous-default-export
export default {
  multicall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    1: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    3: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    4: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    5: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    42: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    43113: '0x950fc889c239e4140d4f39d73d2c2400409e5483',
    80001: '0xe9939e7Ea7D7fb619Ac57f648Da7B1D425832631',
  },
};
