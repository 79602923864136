import { RPC_URL_LIST } from "utils/rpc";
import Web3 from "web3"

const WEB3_LIST = {};

for (let chainId in RPC_URL_LIST) {
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL_LIST[chainId]));

  WEB3_LIST[chainId] = web3;
}

export default WEB3_LIST;