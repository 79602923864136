import { useWeb3React } from '@web3-react/core';
import { SimpleComponent } from 'interface';
import React from 'react';
import { RiWallet3Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import addressParse from 'utils/addressParse';
import Web3 from 'web3';

interface Props extends SimpleComponent {}

function ConnectWalletBtn(props: Props) {
  // web3
  const context = useWeb3React<Web3>();
  const { account, active } = context;
  return (
    <Link to="/wallet">
      <div
        className={`text-4xl flex items-center justify-center custom-gradient-secondary-hover py-2 px-4 rounded-xl cursor-pointer ${props.className}`}
        style={props.style}
      >
        <RiWallet3Fill className="custom-gradient-text-primary" />
        <div className="ml-5 font-bold text-2xl">
          {active && account ? addressParse(account) : 'Connect Wallet'}
        </div>
      </div>
    </Link>
  );
}

export default ConnectWalletBtn;
