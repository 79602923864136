import { SelectBoxWrapper } from './SelectBoxStyled';

import { Select } from 'antd';

const { Option } = Select;

interface ValueLabel {
  value: string;
  label: string;
}

interface Props {
  value?: string;
  onChange?: (value: any) => any;
  placeholder?: string;
  option: any[];
  style?: React.CSSProperties;
}

const SelectBox = (props: Props) => {
  const handleChange = (value: any) => {
    console.log(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <SelectBoxWrapper style={props.style}>
      <Select
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleChange}
      >
        {props.option.map((e) => {
          return (
            <Option
              key={e.value || e}
              style={{ fontSize: '1.6rem' }}
              value={e.value || e}
            >
              {e.label || e}
            </Option>
          );
        })}
      </Select>
    </SelectBoxWrapper>
  );
};

export default SelectBox;
