import { Spin } from "antd";
import SelectBox from "components/SelectBox/SelectBox";
import { LeaderView, loadLeaders } from "contracts/CopycatLeader";
import { loadOwnedLeaderAddresses } from "contracts/CopycatLeaderFactory";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useOnSwitchChain } from "hooks/web3Hooks";
import { uniq } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "utils/api";
import { getLocal, removeLocal, setLocal } from "utils/localStorage";

export default function MasterSelect({ onChange }) {
  const { account } = useActiveWeb3React();
  const [leaders, setLeaders] = useState<LeaderView[]>([]);
  const [selectedLeader, setSelectedLeader] = useState(getLocal("SELECTED_MASTER") || null);
  const [loading, setLoading] = useState(true);

  const fetchLeaders = useCallback(async () => {
    if (!account) return;

    setLoading(true);

    let apiData = (await api.get('/cache/mymasters/' + account)).data;
    let apiAddresses = apiData.map((c) => c.address);
    let eventAddresses = await loadOwnedLeaderAddresses(account);
    let addresses = uniq([...apiAddresses, ...eventAddresses]);
    console.log(addresses);

    let rawCopyListData = await loadLeaders(addresses, account);
    
    setLeaders(rawCopyListData);

    // Test for leader existance
    let leaderExisted = false;
    if (selectedLeader) {
      for (let leader of rawCopyListData) {
        if (leader.address.toLowerCase() == selectedLeader.toLowerCase()) {
          leaderExisted = true;
          break;
        }
      }
    }

    if (!leaderExisted) {
      removeLocal("SELECTED_MASTER");
      setSelectedLeader("");
    }

    setLoading(false);
  }, [account]);

  useEffect(() => {
    fetchLeaders();
  }, [account]);

  useEffect(() => {
    if (!loading) {
      setLocal("SELECTED_MASTER", selectedLeader)
      onChange(selectedLeader);
    }
  }, [selectedLeader, loading])

  useOnSwitchChain(() => fetchLeaders());

  if (loading) {
    return (
      <Spin size="large"></Spin>
    )
  }

  return (
    <SelectBox 
      placeholder={loading ? "Loading..." : "Select master"}
      option={
        leaders.map(leader => ({
          value: leader.address,
          label: leader.tokenName,
        }))
      }
      onChange={(leader) => setSelectedLeader(leader)}
      value={selectedLeader}
    />
  )
}