// import { InjectedConnector } from '@web3-react/injected-connector';

// // let supportChain_list: any[] = [];

// export const injected = new InjectedConnector({ supportedChainIds: [supportChain_number] });

import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ACTIVE_CHAIN_ID } from 'utils/env';
// import { WalletLinkConnector } from '@web3-react/walletlink-connector';
// import { FrameConnector } from '@web3-react/frame-connector';
// import { AuthereumConnector } from '@web3-react/authereum-connector';
// import { FortmaticConnector } from '@web3-react/fortmatic-connector';
// import { PortisConnector } from '@web3-react/portis-connector';
// import { TorusConnector } from '@web3-react/torus-connector';
import getRpcUrl from 'utils/getRpcUrl';
import { RPC_URL_LIST } from 'utils/rpc';
import { SUPPORTED_CHAIN_LIST } from 'utils/supportedChains';
// import { ethers } from 'ethers';

// const supportChain: any = ACTIVE_CHAIN_ID;
// const supportChain_number = Number(supportChain);
// const rpcUrl = getRpcUrl();

// const POLLING_INTERVAL = 12000;
// const RPC_URLS: { [chainId: number]: string } = {
//   1: process.env.RPC_URL_1 as string,
//   4: process.env.RPC_URL_4 as string,
// };

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_LIST.map(x => x.chainId),
});

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URL_LIST,
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
});

// export const walletlink = new WalletLinkConnector({
//   url: rpcUrl,
//   appName: 'web3-react example',
//   supportedChainIds: [supportChain_number],
// });

// export const frame = new FrameConnector({ supportedChainIds: [1] });

// export const authereum = new AuthereumConnector({ chainId: 42 });

// export const fortmatic = new FortmaticConnector({ apiKey: process.env.FORTMATIC_API_KEY as string, chainId: 4 });

// export const portis = new PortisConnector({ dAppId: process.env.PORTIS_DAPP_ID as string, networks: [1, 100] });

// export const torus = new TorusConnector({ chainId: 1 });
