import { pathnerList } from 'config/mockdata';
import React from 'react';

function Partner() {
  return (
    <div className="w-full md:px-32 px-10">
      <h1 className="text-3xl md:text-5xl text-center border-y-2 py-6 my-6">
        Our Partners
      </h1>
      <div className="w-full flex justify-around flex-wrap mt-12">
        {pathnerList.map((e) => (
          <div className="transition-all hover:-translate-y-4" key={e.key}>
            <img className="h-40" src={e.img} alt={e.name} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Partner;
