import ButtonStyled from 'components/ButtonStyled';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import React from 'react';

function Test() {
  const popup = usePopup();
  const confirm = useConfirm();
  const testList = [
    {
      key: 'loading',
      name: 'Popup Loading',
      click: () => {
        popup.loading({
          title: 'Transaction',
          text: 'Waiting Transaction Loading',
        });
      },
    },
    {
      key: 'success',
      name: 'Popup success',
      click: () => {
        popup.success({
          title: 'Transaction',
          text: 'Waiting Transaction Complete',
        });
      },
    },
    {
      key: 'error',
      name: 'Popup error',
      click: () => {
        popup.error({
          title: 'Transaction',
          text: 'Waiting Transaction Failed',
        });
      },
    },
    {
      key: 'Confirm',
      name: 'Confirm',
      click: async () => {
        const isConfirm = await confirm.isConfirmed({
          text: 'Input value?',
          title: 'Test Confirm',
        });
        console.log(isConfirm);
        popup.success({
          title: 'Result',
          text: `${isConfirm.confirm ? 'confirm' : 'reject'}`,
        });
      },
    },
    {
      key: 'ConfirmwithInput',
      name: 'Confirm with Input',
      click: async () => {
        const isConfirm = await confirm.isConfirmed({
          text: 'Are u sure?',
          title: 'Test Confirm',
          input: true,
          type: 'number',
        });
        console.log(isConfirm);
        popup.success({
          title: 'Result',
          text: `${
            isConfirm.confirm ? 'value = ' + isConfirm.value : 'reject'
          }`,
        });
      },
    },
  ];
  return (
    <div className="w-full px-[20%] mt-10 text-4xl flex flex-col space-y-4">
      <h1>Todo List</h1>
      {testList.map((e) => (
        <ButtonStyled
          key={e.key}
          className="w-full text-3xl"
          disabled={false}
          onClick={e.click}
        >
          {e.name}
        </ButtonStyled>
      ))}
    </div>
  );
}

export default Test;
