import web3 from 'hooks/web3';
import web3ReadOnly from 'hooks/web3ReadOnly';
import CopygameLeaderABI from './abi/CopygameLeader.json';
import CopygameBridgePluginABI from './abi/CopygameBridgePlugin.json';
import { ACTIVE_CHAIN_ID, getEnv } from 'utils/env';

function estimateGas() {
  switch (ACTIVE_CHAIN_ID) {
    case '3': return 0.02;
    case '97': return 0.1;
    case '43113': return 20;
    case '80001': return 10;
  }

  return 0.1;
}

export default class CopygameBridgePlugin {
  contract: any;
  leaderContract: any;

  contractDest: any;

  leader: any;
  from: any;

  toChainId: any;
  toWeb3: any;

  constructor(leader, from, toChainId) {
    const BRIDGE_PLUGIN_ADDRESS = getEnv("BRIDGE_PLUGIN_CONTRACT");

    this.contract = new web3.eth.Contract(
      CopygameBridgePluginABI as any,
      BRIDGE_PLUGIN_ADDRESS
    );

    this.leaderContract = new web3.eth.Contract(
      CopygameLeaderABI as any,
      leader,
    );

    this.leader = leader;
    this.from = from;

    this.toChainId = toChainId;

    this.contractDest = new web3ReadOnly[toChainId].eth.Contract(
      CopygameBridgePluginABI as any,
      getEnv("BRIDGE_PLUGIN_CONTRACT", toChainId)
    );
  }

  async getOriginalLeader() {
    const originalLeader = await this.contract.methods.originalLeaders(this.leader).call();

    const originalChainId = originalLeader.chainId || ACTIVE_CHAIN_ID;
    const originalLeaderAddress = !originalLeader.leaderAddress || originalLeader.leaderAddress == '0x0000000000000000000000000000000000000000' ? this.leader : originalLeader.leaderAddress;

    return {
      chainId: originalChainId,
      leaderAddress: originalLeaderAddress,
    }
  }

  async getDestLeaderAddress() {
    // Get original leader chainId and address first;
    const originalLeader = await this.getOriginalLeader();

    return await this.contractDest.methods.mirroredLeaders(originalLeader.chainId, originalLeader.leaderAddress).call();
  }

  async checkEnabled() {
    try {
      return (await this.getDestLeaderAddress()) != "0x0000000000000000000000000000000000000000";
    } catch (err) {
      console.error('Enable error', err);
      return false;
    }
  }

  async enable() {
    const BRIDGE_AXELAR_ADDRESS = getEnv("BRIDGE_AXELAR_CONTRACT");

    return await this.contract.methods
      .enable(BRIDGE_AXELAR_ADDRESS, this.toChainId, this.leader)
      .send({
        from: this.from,
        value: web3.utils.toWei(estimateGas().toString()), // TODO
      });
  }

  async approve(tokenAddress) {
    const BRIDGE_PLUGIN_ADDRESS = getEnv("BRIDGE_PLUGIN_CONTRACT");
    return await this.leaderContract.methods.pluginRequestAllowance(tokenAddress, BRIDGE_PLUGIN_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').send({from: this.from})
  }

  // Use this instead for security
  async approveLimited(tokenAddress, amount) {
    const BRIDGE_PLUGIN_ADDRESS = getEnv("BRIDGE_PLUGIN_CONTRACT");
    return await this.leaderContract.methods.pluginRequestAllowance(tokenAddress, BRIDGE_PLUGIN_ADDRESS, amount).send({from: this.from})
  }

  async tokenBridge(tokenSymbol, amount) {
    const BRIDGE_AXELAR_ADDRESS = getEnv("BRIDGE_AXELAR_CONTRACT");

    return await this.contract.methods
      .tokenBridge(BRIDGE_AXELAR_ADDRESS, this.toChainId, this.leader, tokenSymbol, amount)
      .send({
        from: this.from,
        value: web3.utils.toWei(estimateGas().toString()), // TODO
      });
  }

  async deposit(amount, percentage, finalPercentage, minShare: any = 0) {
    // Simulate to find possible percentage

    return await this.contract.methods
      .buy(this.from, this.leader, percentage, finalPercentage, minShare)
      .send({
        from: this.from,
        value: amount,
      });
  }

  async withdrawSimulate(amount, minBnb: any = 0) {
    return await this.contract.methods
      .sell(this.from, this.leader, amount, minBnb)
      .call({ from: this.from });
  }

  async withdraw(amount, minBnb: any = 0) {
    return await this.contract.methods
      .sell(this.from, this.leader, amount, minBnb)
      .send({ from: this.from });
  }
}
