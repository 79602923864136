import React, { useState, useEffect } from 'react';
import { fetchBnbPrice, getBnbPrice } from 'utils/bnbPrice';
import useInterval from './useInterval';

export function useBnbPrice() {
  const [bnbPrice, setBnbPrice] = useState(0);

  async function loadBnbPrice() {
    setBnbPrice(await getBnbPrice());
  }

  useEffect(() => {
    loadBnbPrice();
  }, []);

  return bnbPrice;
}

export function useBnbPricePolling() {
  const [bnbPrice, setBnbPrice] = useState(0);

  async function loadBnbPrice() {
    setBnbPrice(await getBnbPrice());
  }

  useInterval(() => {
    loadBnbPrice();
  }, 60000);

  return bnbPrice;
}

export function useFetchBnbPrie() {
  fetchBnbPrice();
  setInterval(fetchBnbPrice, 50000);
}
