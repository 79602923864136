import React from 'react';
import { add, format, differenceInCalendarDays } from 'date-fns';
import { AreaChart, Tooltip, Area, ResponsiveContainer } from 'recharts';

const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
const fillTicksData = (_ticks, data) => {
  const ticks = [..._ticks];
  const filled = [];
  let currentTick = ticks.shift();
  let lastData = null;
  for (const it of data) {
    if (ticks.length && it.date > currentTick && lastData) {
      filled.push({ ...lastData, ...{ date: currentTick } });
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }

    filled.push(it);
    lastData = it;
  }

  return filled;
};

const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className="area-chart-tooltip">
        <p>
          {currData ? format(new Date(currData.date), 'yyyy-MM-dd') : ' -- '}
        </p>
        <p>
          {'value : '}
          <em>{currData ? currData.val : ' -- '}</em>
        </p>
      </div>
    );
  }

  return null;
};

interface chartDataIn {
  date: number;
  val: number;
}

function MasterChart({
  data,
  height,
}: {
  data: chartDataIn[];
  height?: number;
}) {
  const startDate =
    data && data.length > 0 ? new Date(data[0].date) : new Date();
  const endDate =
    data && data.length > 0 ? new Date(data[data.length - 1].date) : new Date();

  const ticks = getTicks(startDate, endDate, 5);
  const filledData = fillTicksData(ticks, data);

  // console.log(filledData);
  return (
    <div>
      <ResponsiveContainer width="100%" height={height || 400}>
        <AreaChart data={filledData}>
          <defs>
            <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="3%" stopColor={'rgba(150, 246, 240, 0.5)'} />
              <stop offset="50%" stopColor={'transparent'} />
            </linearGradient>
          </defs>

          {/* <XAxis
            dataKey="date"
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={domain}
            ticks={ticks}
          /> */}
          {/* <YAxis tickCount={7} /> */}
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="val"
            stroke={'#00d3c5'}
            strokeWidth="3"
            fillOpacity="1"
            fill="url(#MyGradient)"
            dot
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MasterChart;
