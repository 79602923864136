import ButtonStyled from 'components/ButtonStyled';
import Modal from 'components/ModalPopup/Modal';
import SelectBox from 'components/SelectBox/SelectBox';
import { TokenView } from 'config/tokens';
import CopycatLeader from 'contracts/CopycatLeader';
import CopycatToken from 'contracts/CopycatToken';
import CopygameLeader from 'contracts/CopygameLeader';
import useCallbackFunction from 'hooks/callbackLoadingFn';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import React, { useState } from 'react';
import { formatNumber4 } from 'utils/formatNumber';
import { fromWei, toWei } from 'utils/unitConversion';
import { CopyListData } from 'views/MasterList/copyListData';
import InputInvest from '../InputInvest';

interface Props {
  tokens: TokenView[];
  baseTokenAddress: string;
  data: CopyListData;
  baseTokenSymbol: string;
}

function MasterDistribute(props: Props) {
  const { call } = useCallbackFunction();
  let tokens: TokenView[] = [...(props.tokens || [])];
  const { account } = useActiveWeb3React();
  // Implement
  const [distributedReward, setDistributedReward] = useState(0);
  const [baseTokenBalance, setBaseTokenBalance] = useState(0);
  const [depositLimit, setDepositLimit] = useState(0);

  const [distributeAmount, setDistributeAmount] = useState('');
  const [depositLimitInput, setDepositLimitInput] = useState('');

  const [popUpDistributeReward, setPopUpDistributeReward] = useState(false);
  const [popUpSetDepositLimit, setPopUpSetDepositLimit] = useState(false);

  const [distributeToken, setDistributeToken] = useState<TokenView>(
    tokens.find((x) => x.tokenAddress == props.baseTokenAddress)
  );

  const ClickDistributeReward = async (e) => {
    e.stopPropagation();
    setDistributeAmount('');
    if (distributeToken) {
      setDistributeToken(
        tokens.find((x) => x.tokenAddress == props.baseTokenAddress)
      );
      setPopUpDistributeReward(true);
    } else {
      await new CopycatLeader(props.data?.address, account).addToken(
        props.baseTokenAddress
      );
      window.alert('Please click distribute reward again afetr refresh');
      window.location.reload();
    }
  };

  const ClicksetDepositLimit = (e) => {
    e.stopPropagation();
    setDepositLimitInput(formatNumber4(depositLimit));
    setPopUpSetDepositLimit(true);
  };

  if (account) {
    const f2 = async () => {
      setDistributedReward(
        fromWei(
          await new CopygameLeader(
            props.data?.address,
            account
          ).distributedBalance(props.baseTokenAddress)
        )
      );
    };
    f2();

    const f3 = async () => {
      setBaseTokenBalance(
        fromWei(
          await new CopycatToken(account, props.baseTokenAddress).balanceOf(
            props.data?.address
          )
        )
      );
    };
    f3();

    const f4 = async () => {
      setDepositLimit(
        fromWei(
          await new CopygameLeader(props.data?.address, account).depositLimit()
        )
      );
    };
    f4();

    // await props.refreshData(props.data?.address);
  }

  return (
    <div className="w-full flex flex-col space-y-2">
      <div className="w-full grid grid-cols-2 gap-2 bg-bg-dark-light py-3 rounded-md">
        <div className="w-full px-3 flex flex-col items-center border-r-2 border-secondary1">
          <h4 className="text-lg">Distributed</h4>
          <h2 className="text-lg">${formatNumber4(distributedReward)}</h2>
          <ButtonStyled
            style={{ width: '100%' }}
            disabled={false}
            className="w-full text-xl"
            onClick={ClickDistributeReward}
          >
            Distribute Reward
          </ButtonStyled>
        </div>

        <div className="w-full px-3 flex flex-col items-center">
          <h4 className="text-xl">Deposit Limit</h4>
          <h2 className="text-xl">${formatNumber4(depositLimit)}</h2>
          <ButtonStyled
            style={{ width: '100%' }}
            disabled={false}
            className="w-full text-xl"
            onClick={ClicksetDepositLimit}
          >
            Set Deposit Limit
          </ButtonStyled>
        </div>
      </div>
      {/* <div className="w-full grid grid-cols-2 gap-2 bg-bg-dark-light py-3 rounded-md">
      </div> */}
      {/*  */}
      <Modal
        show={popUpDistributeReward}
        onClose={() => {
          setPopUpDistributeReward(false);
        }}
      >
        <div className="popup-container">
          <div className="w-full flex justify-between">
            <div className="flex space-x-6 text-4xl">Distribute Reward</div>
          </div>
          <div className="w-full my-4 h-1 bg-bg-dark-light"></div>
          <SelectBox
            placeholder="Select token"
            option={tokens.map((c) => ({
              value: c.tokenAddress,
              label: c.tokenSymbol,
            }))}
            onChange={(address) => {
              setDistributeToken(tokens.find((c) => c.tokenAddress == address));
            }}
            value={distributeToken?.tokenAddress}
          />

          <InputInvest
            token={props.baseTokenSymbol}
            amount="To distribute"
            inputValue={distributeAmount}
            inputLabel="Balance"
            inputCurrency={props.baseTokenSymbol}
            labelValue={formatNumber4(baseTokenBalance)}
            onChangeValue={(value) => setDistributeAmount(value)}
          />

          <ButtonStyled
            className="w-full text-xl"
            onClick={(e) => {
              call(
                () =>
                  new CopygameLeader(
                    props.data?.address,
                    account
                  ).distributeReward(
                    distributeToken.tokenAddress,
                    toWei(distributeAmount)
                  ),
                () => {},
                'Distribute Reward'
              );
            }}
          >
            Distribute Reward
          </ButtonStyled>
        </div>
      </Modal>
      {/*  */}
      <Modal
        show={popUpSetDepositLimit}
        onClose={() => {
          setPopUpSetDepositLimit(false);
        }}
      >
        <div className="popup-container">
          <div className="w-full flex justify-between">
            <div className="flex space-x-6 text-4xl">Set Deposit Limit</div>
          </div>
          <div className="w-full my-4 h-1 bg-bg-dark-light"></div>
          <InputInvest
            token={props.baseTokenSymbol}
            amount="Limit"
            inputValue={depositLimitInput}
            inputLabel="Current limit"
            inputCurrency={props.baseTokenSymbol}
            labelValue={formatNumber4(depositLimit)}
            onChangeValue={(value) => setDepositLimitInput(value)}
          />
          <ButtonStyled
            className="w-full text-xl"
            onClick={(e) => {
              call(
                () =>
                  new CopygameLeader(
                    props.data?.address,
                    account
                  ).setDepositLimit(toWei(depositLimitInput)),
                () => {},
                'Set Limit'
              );
            }}
          >
            Set Limit
          </ButtonStyled>
        </div>
      </Modal>
      {/*  */}
    </div>
  );
}

export default MasterDistribute;
