import { createGlobalStyle } from 'styled-components/macro';

// font-family: 'Montserrat', sans-serif;
// font-family: 'Roboto', sans-serif;

const GlobalStyle = createGlobalStyle`
  body{
    background-color: #001321;
    font-family: 'Manrope', sans-serif !important;
    overflow: overlay;
    outline: none;
  list-style: none;
  box-sizing: border-box;
  line-height: normal;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}



html {
  background-color: #001321;
  max-width: 100%;
  /* overflow-x: hidden; */
  font-size: 64.5%;
  scroll-behavior: smooth;
  *{
    color:#ffffff;
  }
}



/* body {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100vw;
  min-height: 100vh;
  height: auto;
  height: 100%;
  font-family: 'Prompt', sans-serif !important;
  transition: all 0.2s ease-in-out;
} */
.enableScroll{
  padding-right: 1rem;
}

/* end of defalte setting */
@media (max-width: 1535px)  {
  html {
    font-size: 64.5%;
  }
  .enableScroll{
  padding-right: 0;
  }
}
@media (max-width: 1279px)  {
  html {
    font-size: 64.5%;
  }
  .enableScroll{
  padding-right: 0;
  }
}
@media (max-width: 1023px)  {
  html {
    font-size: 64.5%;
  }
  .enableScroll{
  padding-right: 0;
  }
}
@media (max-width: 767px) {
  html {
    font-size:60%;
  }
  .enableScroll{
  padding-right: 0;
  }
}

@media (max-width: 639px) {
  html {
    font-size: 40%;
  }
  .enableScroll{
  padding-right: 0;
  }
} 

/* @media (max-width: 550px) {
  html {
    font-size: 30%;
  }
  .enableScroll{
  padding-right: 0;
  }
}  */


::-webkit-scrollbar {
  height: 0.1px;
  position: absolute;
  width: 1rem;
}
::-webkit-scrollbar-thumb {
  height: 0.1px;
  border-radius: 1rem;
}

.hover-pointer:hover {
  cursor: pointer;
}



.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-appear-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


/* antd */
/* calendar */
// AntD global≈

.icon-main{
  *{
    color: white;
  }
  stroke: white;
  svg{
    color: white;
  }
}
`;

export default GlobalStyle;
