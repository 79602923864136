import bglanding from 'assets/images/bg-landing.jpeg';
import cartoon from 'assets/images/main_avatar.png';
import imag150x200 from 'assets/images/150x200.png';
import img2401 from 'assets/images/2401-01.png';
import img2402 from 'assets/images/2402-01.png';
import img2403 from 'assets/images/2403-01.png';
import img2404 from 'assets/images/2404-01.png';
import img2405 from 'assets/images/2405-01.png';
import img2406 from 'assets/images/2406-01.png';
import imag60x60 from 'assets/images/60x60.webp';

// aunction asset
import Avatar1 from 'assets/images/avatar/catSwag.svg';
import Avatar2 from 'assets/images/avatar/fox.svg';
import imgtest from 'assets/images/240x240_copygame.png';

// img pathner
import copycat from 'assets/images/partners/copycat.webp';
import apeswap from 'assets/images/partners/apeswap.webp';
import binancelab from 'assets/images/partners/binancelab.webp';
import copycatlabs from 'assets/images/partners/copycatlabs.webp';
import peckshield from 'assets/images/audits/peckshield.webp';

export const homeData = {
  top: {
    main1: 'Enable Cross-Chain',
    main2: 'GameFi Earning Solution',
    dis: "Let's Unlock Innovative GameFi Earning Solutions",
    bg: bglanding,
    cartoon,
    btn: [
      {
        name: 'Explore Now',
        key: 'explore-btn',
        theme: 'secondary',
        link: '/master',
      },
      {
        name: 'Game Master',
        key: 'create-store',
        theme: 'primary',
        link: '/apply',
      },
    ],
    slider: [
      [
        {
          key: 1,
          img: imag150x200,
        },
        {
          key: 2,
          img: imag150x200,
        },
        {
          key: 3,
          img: imag150x200,
        },
        {
          key: 4,
          img: imag150x200,
        },
        {
          key: 5,
          img: imag150x200,
        },
        {
          key: 6,
          img: imag150x200,
        },
      ],
      [
        {
          key: 1,
          img: imag150x200,
        },
        {
          key: 2,
          img: imag150x200,
        },
        {
          key: 3,
          img: imag150x200,
        },
        {
          key: 4,
          img: imag150x200,
        },
        {
          key: 5,
          img: imag150x200,
        },
        {
          key: 6,
          img: imag150x200,
        },
      ],
    ],
  },
  getstart: {
    title: 'Get Started Being Cross-Chain Pro Gamers With Copygame Finance',
    item: [
      {
        key: 1,
        title: 'Set Up Your Master Account',
        dec: "Once you've set up your master account of your choice. You can select your basic avatar, nametag, and etc.",
        img: imag60x60,
      },
      {
        key: 2,
        title: 'Gameplay Tag',
        dec: 'Selecting Gameplay tag is one proper option for pro players to show their playing skills in games. One master gamer can play more than one games.',
        img: imag60x60,
      },
      {
        key: 3,
        title: 'Fixed Fees or Profit-Sharing',
        dec: 'Master account can select to specify the fees charging from those investors. There are 2 models; fixed fees or profit-sharing ones.',
        img: imag60x60,
      },
      {
        key: 4,
        title: 'Leverage Cross-Chain Gaming',
        dec: 'Master token can be bridged across multi-chains. This means investors from different blockchain can invest on their using protocol.',
        img: imag60x60,
      },
    ],
  },
};

export const footerData = {
  update: 'Get The Copycat Gaming Updates',
  join: 'Join the Community',
  community: [
    { key: 'twitter', link: '#' },
    { key: 'discord', link: '#' },
    { key: 'facebook', link: '#' },
  ],
  menu: [
    [
      { key: 'Explore', link: '#' },
      { key: 'How it works', link: '#' },
      { key: 'Support', link: '#' },
    ],
    [
      { key: 'Game', link: '#' },
      { key: 'Master', link: '#' },
      { key: 'Register', link: '#' },
    ],
  ],
  foot: 'All Rights Reserved © Copygame Finance 2022',
};

export const auctionsData = {
  title: 'Master Gamers',
  master: [
    {
      key: 'master01',
      name: 'ExoGamers',
      avatar: Avatar1,
      verify: true,
      game: img2402,
      feeCpc: 100,
      feePercent: 0.15,
      tvl: 25000,
      favorite: true,
      roi: '30+ ',
    },
    {
      key: 'master02',
      name: 'ProEndeavor',
      avatar: Avatar2,
      verify: true,
      game: img2404,
      feeCpc: 50,
      feePercent: 5,
      tvl: 12000,
      favorite: false,
      roi: '40+',
    },
    {
      key: 'master03',
      name: 'JamesTheGod',
      avatar: Avatar1,
      verify: true,
      game: img2405,
      feeCpc: 1000,
      feePercent: 5000,
      tvl: 32475,
      favorite: true,
      roi: '60+',
    },
    {
      key: 'master04',
      name: 'YieldRust',
      avatar: Avatar2,
      verify: true,
      game: img2406,
      feeCpc: 1000,
      feePercent: 12,
      tvl: 32240,
      favorite: false,
      roi: '45',
    },
    {
      key: 'master05',
      name: 'ExactSniper',
      avatar: Avatar1,
      verify: true,
      game: img2401,
      feeCpc: 100,
      feePercent: 15,
      tvl: 10500,
      favorite: true,
      roi: '120+',
    },
    {
      key: 'master06',
      name: 'PudgeGG',
      avatar: Avatar2,
      verify: true,
      game: img2403,
      feeCpc: 500,
      feePercent: 4.75,
      tvl: 22435,
      favorite: false,
      roi: '99',
    },
  ],
};

export const pathnerList = [
  {
    key: 'copycatlab',
    name: 'COPYCAT LABS',
    img: copycatlabs,
  },
  {
    key: 'apeswap',
    name: 'APESWAP',
    img: apeswap,
  },
  {
    key: 'copycat',
    name: 'COPYCAT',
    img: copycat,
  },
  {
    key: 'binancelab',
    name: 'Binance LABS',
    img: binancelab,
  },
];

export const auditsList = [
  {
    key: 'peckshield',
    name: 'Peckshiled',
    img: peckshield,
  },
];
