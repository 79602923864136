import web3 from 'hooks/web3';
import { getEnv } from 'utils/env';
import CopycatLeaderStorageABI from './abi/CopycatLeaderStorage.json';
// import { uniq } from 'lodash';
// import { fromWei } from 'utils/unitConversion';
// import { Contract } from 'web3-eth-contract';

export default class CopycatLeaderStorage {
  contract: any;
  from: any;

  constructor(from) {
    const COPYCAT_LEADER_STORAGE_ADDRESS = getEnv("LEADER_STORAGE_CONTRACT");

    this.contract = new web3.eth.Contract(
      CopycatLeaderStorageABI as any,
      COPYCAT_LEADER_STORAGE_ADDRESS
    );
    this.from = from;
  }

  async getAdapters(leader) {
    return await this.contract.methods.getAdapters(leader).call();
  }

  async getAdaptersSpecificType(leader, adapterType) {
    return await this.contract.methods
      .getAdaptersSpecificType(leader, adapterType)
      .call();
  }
}
