import { Address } from 'config/types';
import addresses from 'config/contracts';
import { ACTIVE_CHAIN_ID } from './env';

export const getAddress = (address: Address): string => {
  const chainId = ACTIVE_CHAIN_ID;
  return address[chainId];
};

export const getMulticallAddress = (): string => {
  return getAddress(addresses['multicall']);
};
