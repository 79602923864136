import useQuery from 'hooks/useQuery';
import useResponsive from 'hooks/useResponsive';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'state';
import { CLOSESIDEBAR } from 'state/layout/reducer';

function ScrollToTop() {
  const { pathname } = useLocation();
  const resposive = useResponsive('mobileL');
  const query = useQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (resposive) {
      dispatch(CLOSESIDEBAR());
    }
    window.scrollTo(0, 0);
  }, [pathname, query]);

  return null;
}

export default ScrollToTop;
