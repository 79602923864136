import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { LanguageProvider } from 'context/Localization';
// import { RefreshContextProvider } from '@contextApi/RefreshContext';
import { getLibrary } from 'utils/web3React';
import store, { persistor } from 'state';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import MulticallUpdater from 'state/multicall/updater';
import { PopupProvider } from './Popup';
import ConfirmContextProvider from './Confirm';
import { MultichainContext } from 'hooks/web3Hooks';
import { useState } from 'react';
import { ACTIVE_CHAIN_ID } from 'utils/env';

interface Props {
  children: React.ReactNode;
}

function Updaters() {
  return (
    <>
      <MulticallUpdater />
    </>
  );
}

const Providers = (props: Props) => {
  const [chainId, setChainId] = useState(ACTIVE_CHAIN_ID);
  const [multichainInitialized, setMultichainInitialized] = useState(false);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MultichainContext.Provider value={{chainId, setChainId, initialized: multichainInitialized, setInitialized: setMultichainInitialized}}>
        <ReduxProvider store={store}>
          <Updaters />
          <PersistGate persistor={persistor}>
            <LanguageProvider>
              {/* <RefreshContextProvider> */}
              <ConfirmContextProvider>
                <PopupProvider>
                  {/* <HelmetProvider> */}
                  {props.children}
                  {/* </HelmetProvider> */}
                </PopupProvider>
              </ConfirmContextProvider>
              {/* </RefreshContextProvider> */}
            </LanguageProvider>
          </PersistGate>
        </ReduxProvider>
      </MultichainContext.Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
