const menuListData = [
  {
    name: 'Home',
    key: 'home',
    path: '/',
  },
  {
    name: 'Master',
    key: 'master',
    path: '/master',
  },
  {
    name: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'Apply Master',
    key: 'apply',
    path: '/apply',
  },
  {
    name: 'Bridge',
    key: 'bridge',
    path: '/bridge',
  },
];

export default menuListData;
