import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
  span:last-child {
    font-size: 1.3rem;
    margin: auto 0;
  }
  input {
    background: #00d3c5 !important;
  }
  .ant-checkbox .ant-checkbox-inner {
    background: #00d3c5 !important;
    border-color: #00d3c5 !important;
    border: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
    margin-right: 1rem;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #00d3c5 !important;
    border-color: #00d3c5 !important;
    border: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
  }
  .ant-checkbox-inner::after {
    width: 10.714286px;
    height: 20.142857px;
  }
`;

function CheckboxStyled({ text, onChange }) {
  return (
    <CheckBoxWrapper>
      <Checkbox onChange={onChange}>{text}</Checkbox>
    </CheckBoxWrapper>
  );
}

export default CheckboxStyled;
