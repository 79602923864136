import ConnectWalletBtn from 'components/ConnectWalletBtn';
import React from 'react';
import { Link } from 'react-router-dom';
import Menulist from './Menulist';
import Logo from 'assets/images/logo2.svg';
import SideNavbarIcon from './SideNavbarIcon';

function Navbar() {
  return (
    <div className="w-screen fixed top-0 flex justify-between items-center left-0 h-24 px-4 bg-bg-dark-medium z-40 shadow-md shadow-bg-dark-light">
      <div className="h-full items-center flex space-x-10">
        <Link to="/">
          <div className="h-full flex items-center pr-4 border-r-2">
            <img className="h-[4rem]" src={Logo} alt="copygame-logo" />
            {/* <h1 className="font-[800] text-3xl text-text-blue cursor-pointer">
              COPY GAMING
            </h1> */}
          </div>
        </Link>
        <Menulist />
      </div>
      <div className="flex space-x-4 items-center">
        <ConnectWalletBtn />
        <SideNavbarIcon />
      </div>
    </div>
  );
}

export default Navbar;
