import { LeaderViewExtended } from 'contracts/CopycatLeader';
import { useBnbPrice } from 'hooks/useBnbPrice';
import React from 'react';
import { formatCopyListData } from 'views/MasterList/copyListData';

function MasterStatus({ leaderData }: { leaderData: LeaderViewExtended }) {
  const bnbPrice = useBnbPrice();
  const copyListData7Days = leaderData
    ? formatCopyListData(leaderData, leaderData.apiData, '7d-usd')
    : null;
  const copyListData24Hours = leaderData
    ? formatCopyListData(leaderData, leaderData.apiData, '24h-usd')
    : null;

  const tvl = leaderData ? (leaderData.tvl * bnbPrice).toFixed(0) : '0%';

  // style
  const styleBox =
    'text-2xl w-[10rem] mr-3 mb-3 py-4 mt-2 rounded-md bg-bg-dark-medium flex flex-col items-center justify-center space-y-2 border-[1px] border-bg-light-dark';

  return (
    <div className="w-full flex flex-wrap">
      <div className={styleBox}>
        <p>Current TVL</p>
        <b>{tvl}$</b>
      </div>
      {leaderData.leaderType == 'trade' && <>
        <div className={styleBox}>
          <p>Profit 24H</p>
          <b>{copyListData24Hours.profit}</b>
        </div>
        <div className={styleBox}>
          <p>Profit 7D</p>
          <b>{copyListData7Days.profit}</b>
        </div>
        <div className={styleBox}>
          <p>Profit Lifetime</p>
          <b>{leaderData.apiData.profit}</b>
        </div>
      </>}
    </div>
  );
}

export default MasterStatus;
