import { useWeb3React } from "@web3-react/core";
import { Input } from "antd";
import BridgeTokenSelect from "components/BridgeTokenSelect";
import ButtonStyled from "components/ButtonStyled";
import ChainSelect from "components/ChainSelect";
import MasterSelect from "components/MasterSelect"
import CopycatToken from "contracts/CopycatToken";
import CopygameBridgePlugin from "contracts/CopygameBridgePlugin";
import usePopup from "hooks/usePopup";
import { useOnSwitchChain, useSwitchChain } from "hooks/web3Hooks";
import { IBridgeToken } from "interface/IBridgeToken";
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components";
import { ACTIVE_CHAIN_ID, getEnv } from "utils/env";
import { formatNumber4 } from "utils/formatNumber";
import { fromWei, toWei } from "utils/unitConversion";
import { wait } from "utils/wait";

const BridgeWrapper = styled.div`
  .custom-input {
    border-color: #ffffff1e;
    border-width: 0.1rem;
    border-radius: 1rem;
    padding: 13px;
  }

  .swap-root-container {
    padding: 3.5rem;
  }

  .token-input-container {
    display: grid;
    grid-template-columns: 1fr 200px;
    column-gap: 1rem;
  }

  .swap-item-container {
    background-color rgba(0,0,0,0.4);
    padding: 2.5rem 2rem;
    padding-top: 1rem;
    border-radius: 1rem;
  }
`;

export default function Bridge() {
  const { account } = useWeb3React();
  
  const [selectedMaster, setSelectedMaster] = useState("");

  const [sourceChainId, _setSourceChainId] = useState(ACTIVE_CHAIN_ID);
  const [destinationChainId, setDestinationChainId] = useState(ACTIVE_CHAIN_ID);
  const [selectedToken, setSelectedToken] = useState<IBridgeToken | null>(null);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState("");

  const [contract, setContract] = useState(null);

  const [isEnabled, setIsEnabled] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [loading, setLoading] = useState(false);

  const popup = usePopup();

  const switchChain = useSwitchChain();

  const setSourceChainId = useCallback((newChainId) => {
    switchChain(newChainId);
  }, [_setSourceChainId]);

  useOnSwitchChain((newChainId) => _setSourceChainId(newChainId));

  const refreshApproval = useCallback(async () => {
    if (selectedToken) {
      const tokenContract = new CopycatToken(selectedMaster, selectedToken.tokenAddress);
      if (amount) {
        console.log('Approval', selectedMaster, getEnv("BRIDGE_PLUGIN_CONTRACT"), toWei(amount, selectedToken.decimals))
        setIsApproved(await tokenContract.checkAllowance(selectedMaster, getEnv("BRIDGE_PLUGIN_CONTRACT"), toWei(amount, selectedToken.decimals)));
      }
      setBalance(fromWei(await tokenContract.balanceOf(selectedMaster), selectedToken.decimals));
    }
  }, [selectedMaster, sourceChainId, selectedToken, amount])

  const refreshData = useCallback(async () => {
    if (!account || !selectedMaster) {
      setLoading(true);
      return;
    }

    setLoading(true);

    const bridgeContract = new CopygameBridgePlugin(selectedMaster, account, destinationChainId);
    
    setContract(bridgeContract);

    setIsEnabled(await bridgeContract.checkEnabled());

    console.log("AAA", selectedToken.tokenAddress);

    await refreshApproval();

    setLoading(false);
  }, [account, selectedMaster, sourceChainId, destinationChainId, selectedToken, amount]);

  const approve = useCallback(async () => {
    try {
      popup.loading({
        title: 'Approving',
        text: 'Waiting for transaction',
        disable: true,
      });

      await contract.approveLimited(selectedToken.tokenAddress, toWei(amount, selectedToken.decimals));

      popup.clear();
    } catch (err) {
      console.error(err);
      popup.error({
        title: 'Approve failed!',
      });
    } finally {
      refreshData();
    }
  }, [amount, selectedToken, popup, contract]);

  const enable = useCallback(async () => {
    try {
      popup.loading({
        title: 'Enabling',
        text: 'Waiting for transaction',
        disable: true,
      });

      await contract.enable();

      while (!(await contract.checkEnabled())) {
        await wait(3000);
      }

      popup.clear();
    } catch (err) {
      console.error(err);
      popup.error({
        title: 'Enable failed!',
      });
    } finally {
      refreshData();
    }
  }, [amount, popup, contract]);

  const bridge = useCallback(async () => {
    try {
      popup.loading({
        title: 'Bridging',
        text: 'Waiting for transaction',
        disable: true,
      });

      await contract.tokenBridge(selectedToken.axelarSymbol, toWei(amount, selectedToken.decimals));

      popup.success({
        title: 'Submitted to Axelar',
        text: 'Waiting for Axelar to Unlock',
      })
    } catch (err) {
      console.error(err);
      popup.error({
        title: 'Bridge failed!',
      });
    } finally {
      refreshData();
    }
  }, [amount, popup, selectedToken, contract]);

  useEffect(() => {
    refreshData();
  }, [account, selectedMaster, sourceChainId, destinationChainId, selectedToken])

  useEffect(() => {
    refreshApproval();
  }, [amount])

  return (
    <BridgeWrapper>
      <div className="container mx-auto p-5">
        <div style={{ marginBottom: "4rem", marginTop: "4rem" }}>
          <div className="text-3xl mb-5">Select Master</div>
          <MasterSelect onChange={master => setSelectedMaster(master)}></MasterSelect>
        </div>

        <div className="rounded-lg bg-slate-800 swap-root-container">
          <div className="mb-5">
            <div className="flex items-center mb-5">
              <div className="text-3xl mr-5">From</div>
              <ChainSelect onChange={chainId => setSourceChainId(chainId)}></ChainSelect>
            </div>
            <div className="swap-item-container">
              <div className="flex items-center justify-between my-4 mb-5">
                <div className="text-2xl">Send:</div>
                {!loading && selectedToken && <div className="text-2xl cursor-pointer underline" onClick={() => setAmount(formatNumber4(balance))}>Max: {formatNumber4(balance)}</div>}
              </div>
              <div className="token-input-container">
                <div>
                  <input className="custom-input" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div>
                  <BridgeTokenSelect value={selectedToken} onChange={token => setSelectedToken(token)} chainId={sourceChainId}></BridgeTokenSelect>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "4rem" }}>
            <div className="flex items-center mb-5">
              <div className="text-3xl mr-5">To</div>
              <ChainSelect onChange={chainId => setDestinationChainId(chainId)}></ChainSelect>
            </div>
            <div className="swap-item-container">
              <div className="flex items-center justify-between my-4 mb-5">
                <div className="text-2xl">Receive:</div>
              </div>
              <div className="token-input-container">
                <div>
                  <input className="custom-input" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div>
                  <BridgeTokenSelect value={selectedToken} onChange={token => setSelectedToken(token)} chainId={sourceChainId}></BridgeTokenSelect>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-4">
              <ButtonStyled
                className={`w-full text-3xl`}
                onClick={() => enable()}
                disabled={!selectedToken || !amount || isEnabled || loading}
              >
                Enable
              </ButtonStyled>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <ButtonStyled
                className={`w-full text-3xl`}
                onClick={() => approve()}
                disabled={!selectedToken || !amount || !isEnabled || isApproved || loading}
              >
                Approve
              </ButtonStyled>

              <ButtonStyled
                className={`w-full text-3xl`}
                onClick={() => bridge()}
                disabled={!selectedToken || !amount || !isEnabled || !isApproved || loading}
              >
                Bridge
              </ButtonStyled>
            </div>
          </div>
        </div>
      </div>
    </BridgeWrapper>
  )
}