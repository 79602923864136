import React from 'react';
import usePopup from './usePopup';

// export default async function callbackLoadingFn(
//   fn: () => Promise<any>,
//   text: string = ''
// ) {
//   try {
//     // if (setStateApp) {
//     //   setStateApp(true);
//     // }
//     // setState({
//     //   type: 'load',
//     //   text: 'Waiting For Confirmation',
//     //   message: text,
//     //   descrip: 'Confirm this transaction in your wallet',
//     // });
//     let res = await fn();
//     // if (setStateApp2) {
//     //   setStateApp2(true);
//     // }
//     // setState({
//     //   type: 'success',
//     //   text: 'Transaction Completed!!',
//     //   message: null,
//     //   descrip: null,
//     // });
//     // await callbackwhenFinal();
//     return res;
//   } catch (err: any) {
//     // setState({
//     //   type: 'error',
//     //   text: 'Transaction Failed',
//     //   message: err.message,
//     //   descrip: null,
//     // });
//     // if (setStateApp2) {
//     //   setStateApp2(false);
//     // }
//     // await callBackFailed();
//     return err;
//   } finally {
//     // if (setStateApp) {
//     //   setStateApp(false);
//     // }
//   }
// }
const useCallbackFunction = () => {
  const popup = usePopup();

  const call = async (
    fn: () => Promise<any>,
    callback: () => any = () => {},
    text = ''
  ) => {
    try {
      popup.loading({
        title: 'Transaction',
        text: `Waiting confirm`,
        disable: true,
      });
      let res = await fn();
      await callback();
      popup.success({
        title: 'Transaction',
        text: `Transaction ${text} success!`,
      });
      return res;
    } catch {
      popup.error({ title: 'Transaction', text: `Transaction ${text} failed` });
    } finally {
      await callback();
    }
  };

  return { call };
};

export default useCallbackFunction;
