import { ENV } from "config/envData";

// function buildRpcUrlList() {
//   const result = {};

//   for (const key in process.env) {
//     if (key.startsWith("REACT_APP_") && key.endsWith("_RPC_URL")) {
//       const chainId = key.split('_')[2];
//       result[chainId] = process.env[key];
//     }
//   }

//   return result;
// }

function buildRpcUrlList() {
  const result = {};

  for (const chainId in ENV) {
    result[chainId] = ENV[chainId].RPC_URL;
  }

  return result;
}

export const RPC_URL_LIST = buildRpcUrlList();

// export const RPC_URL_LIST = {
//   3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
//   56: "https://bsc-dataseed1.binance.org",
//   97: "https://data-seed-prebsc-2-s3.binance.org:8545",
//   80001: "https://matic-mumbai.chainstacklabs.com",
//   43113: "https://api.avax-test.network/ext/bc/C/rpc",
//   4002: "https://rpc.testnet.fantom.network/",
//   1287: "https://rpc.testnet.moonbeam.network/",
// }