import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import './styles/tailwind.css';
import 'antd/dist/antd.min.css';
import { BrowserRouter } from 'react-router-dom';
import Providers from 'context/Providers';

const root = createRoot(document.getElementById('root')!);

const MyApp = () => {
  return (
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  );
};

root.render(<MyApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
