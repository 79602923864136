import React from 'react';
import GlobalStyle from 'styles/globalStyled';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from 'views/Home';
import Layouts from 'Layouts';
import WalletConnect from 'views/WalletConnect';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useReconnect } from 'hooks/web3Hooks';
import MasterList from 'views/MasterList';
import { useFetchBnbPrie } from 'hooks/useBnbPrice';
import ApplyMaster from 'views/ApplyMaster';
import Test from 'views/Test';
import PopupBar from 'components/Popup';
import ConfirmModal from 'components/ConfirmModal';
import MasterById from 'views/MasterById';
import ScrollToTop from 'context/ScrollToTop';
import MasterDashboard from 'views/MasterDashboard';
import Bridge from 'views/Bridge';

function App() {
  const location = useLocation();
  useReconnect();
  useFetchBnbPrie();
  return (
    <div className="relative">
      <GlobalStyle />
      <PopupBar />
      <ConfirmModal />
      <ScrollToTop />
      <Layouts>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/wallet" element={<WalletConnect />} />
              <Route path="/master" element={<MasterList />} />
              <Route path="/dashboard" element={<MasterDashboard />} />
              <Route path="/master/:leaderAddress" element={<MasterById />} />
              <Route path="/apply" element={<ApplyMaster copygame={true} />} />
              <Route path="/bridge" element={<Bridge />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </Layouts>
    </div>
  );
}

export default App;
