import useActiveWeb3React from 'hooks/useActiveWeb3React';
import React, { useState } from 'react';

const ImageWithFallback = (props) => {
  const { src, fallbackSrc, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <img
      className={`object-contain ${props.className}`}
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
      alt={props.alt}
    />
  );
};

function TokenImg({
  name,
  address,
  url,
  className,
}: {
  name: string;
  address?: string;
  url?: string;
  className?: string;
}) {
  const { chainId } = useActiveWeb3React();

  let src = address
    ? `/images/tokens/${chainId}/${address.toLocaleLowerCase()}.png`
    : url;
  if (name.toLocaleLowerCase() === 'copycat') {
    src = '/images/token/copycat.svg';
  }
  return (
    <ImageWithFallback
      className={className}
      alt={name}
      src={src}
      fallbackSrc={'/images/token/unknown.svg'}
    />
  );
}

export default TokenImg;
