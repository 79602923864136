import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { useAppDispatch } from 'state';
import { TOGGLESIDEBAR } from 'state/layout/reducer';

function SideNavbarIcon() {
  const dispatch = useAppDispatch();
  const toggle = () => {
    dispatch(TOGGLESIDEBAR());
  };
  return (
    <div className="relative md:hidden">
      <FiMenu onClick={toggle} className="text-[4rem]" />
    </div>
  );
}

export default SideNavbarIcon;
