import React from 'react';
import { BsCheckLg } from 'react-icons/bs';
import ButtonStyled from 'components/ButtonStyled';
import { AiFillApi } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';
import styled from 'styled-components';

const MasterItemWrapper = styled.div`
  .hearth {
    * {
      color: #ff7c6e;
    }
  }
`;

function MasterItem({ data }) {
  return (
    <MasterItemWrapper>
      <div className="w-full h-[auto] flex flex-col space-y-4 p-4 rounded-md bg-bg-dark-medium border-[1px] border-bg-dark-light shadow-md shadow-bg-dark-light">
        {/* header */}
        <div className="w-full flex justify-between items-center">
          <div className="relative">
            <img className="w-20 h-20" src={data.avatar} alt="avatar-1" />
            <div className="w-8 h-8 text-sm flex items-center justify-center border-2 border-bg-dark-medium custom-gradient-secondary rounded-full absolute -right-1 -bottom-1">
              <BsCheckLg />
            </div>
          </div>
          <h3 className="text-3xl">{data.name}</h3>
        </div>
        {/*  */}
        {/* body */}
        <div className="w-full h-[20rem] maxmd:h-[10rem]">
          <img
            className="w-full h-full object-contain"
            src={data.game}
            alt="game"
          ></img>
        </div>
        {/* body */}
        {/*  */}
        <div>
          <b className="text-3xl font-bold text-text-blue">
            ROI {data.roi} Days
          </b>
        </div>
        {/*  */}
        {/* fee */}
        <div className="flex flex-col items-center py-3 rounded-md border-[2px] border-secondary1">
          <p className="text-xl">Deposit Fees</p>
          <b className="text-secondary1 text-xl">
            {data.feeCpc} COPYCAT + {data.feePercent}%
          </b>
        </div>
        {/* fee */}
        {/* copy */}
        <div className="w-full">
          <ButtonStyled
            color="secondary"
            className="flex justify-center w-full"
          >
            <div className="flex items-center">
              <AiFillApi className="text-4xl text-bg-dark-dark mr-2" />
              <b>COPY</b>
            </div>
          </ButtonStyled>
        </div>
        {/* copy */}
        {/* foot */}
        <div className="flex justify-between items-center">
          <p className="text-lg">TVL: ${data.tvl}</p>
          <div className="text-3xl hearth">
            <FaHeart />
          </div>
        </div>
      </div>
    </MasterItemWrapper>
  );
}

export default MasterItem;
