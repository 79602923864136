import {
  LeaderViewExtended,
  loadLeaders,
  loadLeaderTokens,
} from 'contracts/CopycatLeader';
import { loadOwnedLeaderAddresses } from 'contracts/CopycatLeaderFactory';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import web3 from 'hooks/web3';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { api } from 'utils/api';
import { fromWei } from 'utils/unitConversion';
import bglanding from 'assets/images/bg-landing.jpeg';
import { formatCopyListData } from './MasterList/copyListData';
import Loading from 'components/Loading';
import MasterItemFull from 'components/MasterItemFull';
import { useBnbPrice } from 'hooks/useBnbPrice';
import MasterStatus from 'components/MasterItemFull/Details/MasterStatus';
import MasterGraph from 'components/MasterItemFull/Details/MasterGraph';
import MasterChart from 'components/MasterItemFull/Details/MasterChart';
import { Link } from 'react-router-dom';
import ButtonStyled from 'components/ButtonStyled';
import TokenAll from 'components/MasterItemFull/Details/TokenAll';
import MasterDistribute from 'components/MasterItemFull/Details/MasterDistribute';

function MasterDashboard() {
  const [leaders, setLeaders] = useState<LeaderViewExtended[]>([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const { account } = useActiveWeb3React();
  const bnbPrice = useBnbPrice();

  async function refreshData() {
    let apiData = (await api.get('/cache/mymasters/' + account)).data;
    let apiAddresses = apiData.map((c) => c.address);
    let eventAddresses = await loadOwnedLeaderAddresses(account);
    let addresses = uniq([...apiAddresses, ...eventAddresses]);
    console.log(addresses);
    let rawCopyListData = await loadLeaders(addresses, account);

    rawCopyListData = rawCopyListData.filter((x) => x.leaderType == 'game');

    let leadersFormatted: LeaderViewExtended[] = [];

    for (let leader of rawCopyListData) {
      // let tokenList = await loadUniswapV2AdapterTokens(await loadLeaderAdapterAddresses(leader.address));
      let tokenList = await loadLeaderTokens(leader.address);
      let bnbBalance = fromWei(await web3.eth.getBalance(leader.address));

      leadersFormatted.push({
        ...leader,
        tokens: tokenList,
        bnbBalance: bnbBalance,
        histories:
          apiData.find((c) => c.address == leader.address)?.histories || [],
        apiData: apiData ? formatCopyListData(leader, leader || {}) : null,
      });
    }

    setLeaders(leadersFormatted);
    setLoading(false);
  }

  async function refreshDataAndSetPopup() {
    refreshData();
    setPopup(true);
  }

  useEffect(() => {
    if (account) {
      refreshData();
    }
  }, [account]);

  const chartDataParse = (history) =>
    history?.map((c) => ({
      date: new Date(c.u).getTime(),
      val: c.s * c.b,
    })) || [];

  return (
    <div className="w-full relative">
      <div className="w-full mb-8">
        <div
          className={`w-full flex flex-col items-center justify-center bg-bg-dark-medium space-y-8 h-[15rem] mt-5 relative overflow-hidden bg-cover bg-center`}
          style={{ backgroundImage: `url(${bglanding})` }}
        >
          <h1 className="text-5xl relative text-primary1">My Master</h1>
        </div>
      </div>
      {loading ? (
        <Loading absolute={true} />
      ) : (
        <div className="w-full flex-col space-y-4 items-center">
          {leaders.map((leaderData) => (
            <div
              key={leaderData.address}
              className="w-[90%] mx-auto p-4 rounded-md bg-bg-dark-medium grid grid-cols-6 maxmd:grid-cols-2 place-content-start items-start gap-3"
            >
              {/*  */}
              <div className="col-span-1 maxmd:col-span-1 w-full flex flex-col items-center space-y-4">
                <img
                  className="w-20 h-20"
                  src={leaderData.avatar || '/images/avatar/fox.svg'}
                  alt="avatar"
                />
                <h1 className="text-3xl text-center relative text-primary1">
                  {leaderData.tokenName}
                </h1>
                <Link
                  style={{ width: '100%' }}
                  to={`/master/${leaderData.address}`}
                >
                  <ButtonStyled
                    style={{ width: '100%' }}
                    disabled={false}
                    className={'w-full'}
                  >
                    See More
                  </ButtonStyled>
                </Link>
              </div>
              {/*  */}
              <div className="col-span-1 maxmd:col-span-1 w-full">
                <MasterChart
                  height={150}
                  data={chartDataParse(leaderData.histories || [])}
                />
              </div>
              {/*  */}
              <div className="col-span-2 maxmd:col-span-1 flex-col space-y-2">
                <MasterStatus leaderData={leaderData} />
                <TokenAll
                  tokens={leaderData.tokens}
                  baseTokenAddress={leaderData.copygameBaseTokenAddress}
                />
              </div>
              {/*  */}
              <div className="col-span-2 maxmd:col-span-1 w-full flex flex-col space-y-2">
                <MasterItemFull
                  canCopy={
                    leaderData.address !==
                    '0xaf596EBEAF7b06571B39A5F88674B65832eaa6b8'
                  }
                  popup={popup}
                  isdetail={true}
                  data={leaderData.apiData}
                  onlyShow={false}
                  leaderName={leaderData.tokenName}
                  property={'+'}
                  description={leaderData.description}
                  depositFee={leaderData.depositCopycatFee.toString()}
                  depositPercentageFee={leaderData.depositPercentageFee.toString()}
                  tvl={
                    leaderData ? (leaderData.tvl * bnbPrice).toFixed(0) : '0%'
                  }
                  avatar={leaderData.avatar}
                  refreshData={refreshData}
                  hidePopup={() => setPopup(false)}
                />
                {leaderData.owner === account && (
                  <MasterDistribute
                    data={leaderData.apiData}
                    tokens={leaderData.tokens}
                    baseTokenAddress={
                      leaderData.copygameBaseTokenAddress
                    }
                    baseTokenSymbol={leaderData.copygameBaseTokenSymbol}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MasterDashboard;
