import { Spin } from "antd";
import SelectBox from "components/SelectBox/SelectBox";
import { LeaderView, loadLeaders } from "contracts/CopycatLeader";
import { loadOwnedLeaderAddresses } from "contracts/CopycatLeaderFactory";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { IBridgeToken } from "interface/IBridgeToken";
import { uniq } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "utils/api";
import { getLocal, removeLocal, setLocal } from "utils/localStorage";
import { BRIDGE_TOKEN, BRIDGE_DEFAULT_TOKEN } from "./config";

function getDefaultToken(chainId): IBridgeToken {
  const token = BRIDGE_TOKEN[BRIDGE_DEFAULT_TOKEN[chainId]];

  return {
    ...token,
    tokenAddress: token.tokenAddresses[chainId],
  };
}

export default function BridgeTokenSelect({ value = null, onChange, chainId }) {
  const { account } = useActiveWeb3React();
  const [tokens, setTokens] = useState<IBridgeToken[]>([]);
  const [selectedToken, setSelectedToken] = useState<IBridgeToken>(value ?? getDefaultToken(chainId));
  const [loading, setLoading] = useState(true);

  const loadTokens = useCallback(async () => {
    setLoading(true);

    const parsedTokens = [];

    for (let symbol in BRIDGE_TOKEN) {
      if (BRIDGE_TOKEN[symbol].tokenAddresses[chainId]) {
        parsedTokens.push({
          ...BRIDGE_TOKEN[symbol],
          tokenAddress: BRIDGE_TOKEN[symbol].tokenAddresses[chainId],
        })
      }
    }

    setTokens(parsedTokens)
    setSelectedToken(parsedTokens.find(x => x.symbol == selectedToken.symbol))

    setLoading(false);
  }, [chainId]);

  useEffect(() => {
    loadTokens();
  }, [chainId]);

  useEffect(() => {
    if (!loading) {
      onChange(selectedToken);
    }
  }, [selectedToken, loading])

  useEffect(() => {
    if (value) {
      setSelectedToken(value)
    }
  }, [value])

  if (loading) {
    return (
      <Spin size="large"></Spin>
    )
  }

  return (
    <SelectBox 
      placeholder={loading ? "Loading..." : "Select token"}
      option={
        tokens.map(token => ({
          value: token.tokenAddress,
          label: token.symbol,
        }))
      }
      onChange={(tokenAddress) => setSelectedToken(tokens.find(x => x.tokenAddress == tokenAddress))}
      value={selectedToken.tokenAddress}
    />
  )
}