import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    value: 'light',
  },
  reducers: {
    TOGGLETHEME: (state) => {
      state.value = state.value === 'light' ? 'dark' : 'light';
    },
  },
});
export const { TOGGLETHEME } = themeSlice.actions;
export default themeSlice.reducer;
