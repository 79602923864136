import TokenImg from 'components/TokenImg';
import React from 'react';
import { useState } from 'react';

interface Props {
  image: string;
  name: string;
  address: string;
  percentage: number;
}

function TokenAsset(props: Props) {
  const [popUp, setPopUp] = useState(false);
  const hoverA = () => {
    setPopUp(true);
  };
  const leaveA = () => {
    setPopUp(false);
  };
  return (
    <div className="p-2 bg-bg-dark-dark rounded-md border-2 mr-3 mb-3 relative">
      <a
        onMouseLeave={leaveA}
        onMouseEnter={hoverA}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://bscscan.com/token/${props.address}`}
      >
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10">
            <TokenImg address={props.address} name={props.name} />
          </div>
          <span className="text-lg">
            {(props.percentage * 100).toFixed(2)}%
          </span>
        </div>
      </a>
      {popUp && (
        <div className="absolute text-lg left-1/2 -translate-x-1/2 -top-[80%] bg-bg-dark-dark px-6 py-1 rounded-sm">
          {props.name}
        </div>
      )}
    </div>
  );
}

export default TokenAsset;
