import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { ACTIVE_CHAIN_ID } from './env';
// import getRpcUrl from './getRpcUrl';

const RPC_URL_LIST = {
  3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  97: "https://data-seed-prebsc-2-s3.binance.org:8545",
  80001: "https://matic-mumbai.chainstacklabs.com",
  43113: "https://api.avax-test.network/ext/bc/C/rpc",
  4002: "https://rpc.testnet.fantom.network/",
  1287: "https://rpc.testnet.moonbeam.network/",
}

function getRpcUrl(chainId): string {
  return RPC_URL_LIST[chainId] || RPC_URL_LIST[ACTIVE_CHAIN_ID];
}

// const RPC_URL = getRpcUrl();

const simpleRpcProvider = new ethers.providers.JsonRpcProvider(getRpcUrl(ACTIVE_CHAIN_ID));

export function useSimpleRpcProvider() {
  const [provider, setProvider] = useState(new ethers.providers.JsonRpcProvider(getRpcUrl(ACTIVE_CHAIN_ID)));

  const { chainId } = useWeb3React();

  useEffect(() => {
    setProvider(new ethers.providers.JsonRpcProvider(getRpcUrl(chainId)))
  }, [chainId]);

  return provider;
}
