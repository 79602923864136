import web3 from 'hooks/web3';
import CopycatLeader from './CopycatLeader';
import CopygameLeaderABI from './abi/CopygameLeader.json';
import CopygameStakerABI from './abi/CopygameStaker.json';

export default class CopygameLeader extends CopycatLeader {
  constructor(address, from) {
    super(address, from);
    this.contract = new web3.eth.Contract(CopygameLeaderABI as any, address);
  }

  async distributeReward(token, amount) {
    return await this.contract.methods
      .distributeReward(token, amount)
      .send({ from: this.from });
  }

  async execute(target, functionSignature, bnbValue = 0) {
    return await this.contract.methods
      .execute(target, bnbValue, functionSignature)
      .send({ from: this.from, value: bnbValue });
  }

  async stake(amount) {
    return await this.contract.methods.stake(amount).send({ from: this.from });
  }

  async unstake(amount) {
    return await this.contract.methods
      .unstake(amount)
      .send({ from: this.from });
  }

  async harvest() {
    return await this.contract.methods.harvest().send({ from: this.from });
  }

  async depositLimit() {
    return await this.contract.methods.depositLimit().call();
  }

  async setDepositLimit(depositLimit) {
    return await this.contract.methods.setDepositLimit(depositLimit).call();
  }

  async stakedBalance() {
    let stakerContract = new web3.eth.Contract(
      CopygameStakerABI as any,
      await this.contract.methods.staker().call()
    );
    return await stakerContract.methods.staked(this.from).call();
  }

  async distributedBalance(tokenAddress) {
    let stakerContract = new web3.eth.Contract(
      CopygameStakerABI as any,
      await this.contract.methods.staker().call()
    );
    return await stakerContract.methods.distributedReward(tokenAddress).call();
  }

  async baseToken() {
    return await this.contract.methods.baseToken().call();
  }
}
