export interface CopyListData {
  leaderName: string;
  tokenSymbol: string;
  description: string;
  level: number;
  property: string;
  profit: string;
  depositFee: string;
  depositPercentageFee: string;
  tvl: number; // in BNB
  totalSupply: number;
  type: string;
  reward: boolean;

  shareHolding: number;

  shareRatio: number;
  shareRatioUSD?: number;

  shareRatio24Hours?: number;
  shareRatioUSD24Hours?: number;

  shareRatio7Days?: number;
  shareRatioUSD7Days?: number;

  address: string;
  owner: string;

  avatar: string;

  histories?: any[];

  kyc?: any;

  copygameBaseTokenAddress?: string;
  copygameBaseTokenName?: string;
  copygameBaseTokenSymbol?: string;
  copygameBaseTokenDecimals?: number;
  copygameDepositLimit?: number;
}

export function resolveLeaderTypeFrontend(masterTier) {
  switch (masterTier) {
    case 0:
      return 'amateur';
    default:
      return 'professional';
  }
}

export function formatCopyListData(
  data,
  apiData: any = {},
  pnlMode = 'lifetime-usd'
): CopyListData {
  let pnl;
  let pnlRatio;

  if (apiData.bnbPrice) {
    apiData.shareRatioUSD = data.shareRatio * apiData.bnbPrice;
  }

  apiData.shareRatio = data.shareRatio;

  switch (pnlMode) {
    case 'lifetime-usd':
      pnlRatio = apiData.shareRatioUSD / apiData.bnbPriceStart;
      break;

    case '7d-usd':
      pnlRatio = apiData.shareRatioUSD / apiData.shareRatioUSD7Days;
      break;

    case '24h-usd':
      pnlRatio = apiData.shareRatioUSD / apiData.shareRatioUSD24Hours;
      break;

    case 'lifetime-bnb':
      pnlRatio = apiData.shareRatio / 1;
      break;

    case '7d-bnb':
      pnlRatio = apiData.shareRatio / apiData.shareRatio7Days;
      break;

    case '24h-bnb':
      pnlRatio = apiData.shareRatio / apiData.shareRatio24Hours;
      break;
  }

  // console.log(pnlRatio)

  if (!pnlRatio) {
    pnlRatio = data.shareRatio;
  }

  pnl = Math.abs(pnlRatio - 1) * 100;

  let result: CopyListData = {
    ...apiData,

    leaderName: data.tokenName,
    tokenSymbol: data.tokenSymbol,
    description: data.description,
    level: Math.floor(data.tier / 10),
    property: pnlRatio >= 1 ? '+' : '-',
    profit: `${pnlRatio >= 1 ? '+' : '-'} ${pnl.toFixed(2)}%`,
    depositFee: data.depositCopycatFee.toFixed(0),
    depositPercentageFee: (data.depositPercentageFee * 100).toString(),
    tvl: data.tvl,
    totalSupply: data.totalSupply,
    type: '',
    reward: true,
    shareHolding: data.shareHolding,
    shareRatio: data.shareRatio,
    address: data.address,
    owner: data.owner,
    avatar: data.avatar,
    histories: data.histories,
    copygameBaseTokenAddress: data.copygameBaseTokenAddress,
    copygameBaseTokenName: data.copygameBaseTokenName,
    copygameBaseTokenSymbol: data.copygameBaseTokenSymbol,
    copygameBaseTokenDecimals: data.copygameBaseTokenDecimals,
    copygameDepositLimit: data.copygameDepositLimit,
    
    kyc: apiData.kyc,
  };

  // console.log(result);

  return result;
}

export const copyListData = [
  {
    leaderName: 'Elon_Cat',
    description: 'My tweet kill all',
    level: '1',
    property: '+',
    profit: '+ 380.02 %',
    depositFee: '100',
    depositPercentageFee: '20',
    tvl: '80,300',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'MoonPool',
    description: 'Be my profit',
    level: '1',
    property: '+',
    profit: '+ 197.22 %',
    depositFee: '300',
    depositPercentageFee: '100',
    tvl: '12,500',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'Cat Never Sleep',
    description: 'Better than Randomwalk',
    level: '0',
    property: '+',
    profit: '+ 332.53 %',
    depositFee: '1000',
    depositPercentageFee: '100',
    tvl: '44,000',
    type: 'amateur',
    reward: false,
  },
  {
    leaderName: 'Prime Broker',
    description: 'Trading is my life',
    level: '0',
    property: '+',
    profit: '+ 227.82 %',
    depositFee: '20',
    depositPercentageFee: '400',
    tvl: '123,300',
    type: 'amateur',
    reward: false,
  },
  {
    leaderName: 'Richy Rich',
    description: 'Fibo Oreo but great',
    level: '2',
    property: '+',
    profit: '+ 420.76 %',
    depositFee: '100',
    depositPercentageFee: '100',
    tvl: '1,850',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'GuaranteeLoss',
    description: 'Kidding Quote, but real profit',
    level: '2',
    property: '+',
    profit: '+ 1,197.09 %',
    depositFee: '1000',
    depositPercentageFee: '1000',
    tvl: '55,300',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'WallStreet',
    description: '12 indicators are just the beginning',
    level: '2',
    property: '+',
    profit: '+ 3,397.99 %',
    depositFee: '100',
    depositPercentageFee: '100',
    tvl: '49,790',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'Master Yoda',
    description: 'Spread betting',
    level: '2',
    property: '+',
    profit: '+ 53.51 %',
    depositFee: '100',
    depositPercentageFee: '100',
    tvl: '12,880',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: '#new_wave',
    description: 'Never regret to follow me',
    level: '2',
    property: '+',
    profit: '+ 330.54 %',
    depositFee: '100',
    depositPercentageFee: '100',
    tvl: '9,300',
    type: 'professional',
    reward: true,
  },
  {
    leaderName: 'Best One',
    description: 'IP trading / Master robot',
    level: '2',
    property: '+',
    profit: '+ 1,333.46 %',
    depositFee: '100',
    depositPercentageFee: '100',
    tvl: '18,546',
    type: 'professional',
    reward: true,
  },
];
