import ButtonStyled from 'components/ButtonStyled';
import ConnectWalletBtn from 'components/ConnectWalletBtn';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useBnbPrice } from 'hooks/useBnbPrice';
import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { CopyListData } from 'views/MasterList/copyListData';

interface Props {
  data?: CopyListData;
  handleClickCopy: (e: any) => any;
  handleClickWithdraw: (e: any) => any;
  canCopy: boolean;
}

function CopyButton(props: Props) {
  const { account } = useActiveWeb3React();
  const bnbPrice = useBnbPrice();

  console.log(props.data.leaderName, props.data.shareHolding);

  return (
    <div className="w-full h-18 flex justify-center bg-bg-dark-light p-2 rounded-md">
      {account ? (
        !props.data || props.data?.shareHolding === 0 ? (
          <ButtonStyled
            onClick={props.handleClickCopy}
            disabled={!props.canCopy}
            className="h-18 w-full"
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            COPY
          </ButtonStyled>
        ) : (
          <div className="w-full h-18 flex justify-between">
            <ButtonStyled
              onClick={props.handleClickWithdraw}
              disabled={!props.canCopy}
              color={'primary'}
              className="h-full w-16 text-2xl flex justify-center items-center"
            >
              <FaMinus />
            </ButtonStyled>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '2rem', textAlign: 'center' }}>
                {(
                  props.data?.shareHolding *
                  props.data?.shareRatio *
                  bnbPrice
                ).toFixed(0)}{' '}
                $
              </div>

              <div style={{ fontSize: '1rem', textAlign: 'center' }}>
                {props.data?.shareHolding >= 0.1
                  ? props.data?.shareHolding.toFixed(2)
                  : props.data.shareHolding.toFixed(4)}{' '}
                {props.data.tokenSymbol}
              </div>
            </div>

            <ButtonStyled
              onClick={props.handleClickCopy}
              disabled={!props.canCopy}
              color={'secondary'}
              className="h-full w-16 text-2xl flex justify-center items-center"
            >
              <FaPlus />
            </ButtonStyled>
          </div>
        )
      ) : (
        <div style={{ marginTop: 12, width: '100%' }}>
          <ConnectWalletBtn
            style={{
              fontSize: '1.6rem',
              width: '100%',
              padding: '0',
              height: '4rem',
            }}
          ></ConnectWalletBtn>
        </div>
      )}
    </div>
  );
}

export default CopyButton;
