import web3 from 'hooks/web3';
import { getEnv } from 'utils/env';
import CopycatDepositerABI from './abi/CopycatDepositer.json';

export default class CopycatDepositer {
  contract: any;
  leader: any;
  from: any;

  constructor(leader, from) {
    const COPYCAT_DEPOSITER_ADDRESS = getEnv("DEPOSITER_CONTRACT");

    this.contract = new web3.eth.Contract(
      CopycatDepositerABI as any,
      COPYCAT_DEPOSITER_ADDRESS
    );
    this.leader = leader;
    this.from = from;
  }

  async depositSimulate(
    amount,
    percentage,
    finalPercentage,
    minShare: any = 0
  ) {
    return await this.contract.methods
      .buy(this.from, this.leader, percentage, finalPercentage, minShare)
      .call({
        from: this.from,
        value: amount,
      });
  }

  async deposit(amount, percentage, finalPercentage, minShare: any = 0) {
    // Simulate to find possible percentage

    return await this.contract.methods
      .buy(this.from, this.leader, percentage, finalPercentage, minShare)
      .send({
        from: this.from,
        value: amount,
      });
  }

  async withdrawSimulate(amount, minBnb: any = 0) {
    return await this.contract.methods
      .sell(this.from, this.leader, amount, minBnb)
      .call({ from: this.from });
  }

  async withdraw(amount, minBnb: any = 0) {
    return await this.contract.methods
      .sell(this.from, this.leader, amount, minBnb)
      .send({ from: this.from });
  }
}
