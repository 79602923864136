import menuListData from 'config/menuListData';
import React, { useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'state';
import { useSideBar } from 'state/layout/hooks';
import { CLOSESIDEBAR, TOGGLESIDEBAR } from 'state/layout/reducer';

function SideNavbar() {
  const dispatch = useAppDispatch();
  const open = useSideBar();
  const location = useLocation();
  const checkRoute = (e: string) => {
    if (e === 'home') {
      return location.pathname === '/';
    } else {
      return location.pathname.includes(e);
    }
  };

  const toggle = () => {
    dispatch(TOGGLESIDEBAR());
  };

  useEffect(() => {
    dispatch(CLOSESIDEBAR());
  }, [location]);
  return (
    <div
      className={`w-screen h-screen fixed left-0 top-0 transition-all ${
        open ? 'translate-x-0' : 'translate-x-full'
      }`}
      style={{ zIndex: 110 }}
    >
      <div className="w-full h-full absolute left-0 top-0 bg-bg-dark-dark opacity-75" />
      <div className="w-[75%] py-8 absolute h-full right-0 top-0 flex flex-col space-y-20 items-end bg-bg-dark-dark pr-20">
        <MdOutlineClose onClick={toggle} className="text-[5rem]" />
        {menuListData.map((e) => (
          <Link key={e.key} to={e.path}>
            <span
              className={`text-6xl transition-all hover:text-text-blue cursor-pointer ${
                checkRoute(e.key) ? 'text-text-blue' : 'text-text-gray'
              }`}
              key={e.key}
            >
              {e.name}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SideNavbar;
