import menuListData from 'config/menuListData';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Menulist() {
  const location = useLocation();
  const checkRoute = (e: string) => {
    if (e === 'home') {
      return location.pathname === '/';
    } else {
      return location.pathname.includes(e);
    }
  };
  return (
    <div className="hidden md:flex space-x-10 items-center">
      {menuListData.map((e) => (
        <Link key={e.key} to={e.path}>
          <span
            className={`text-3xl transition-all hover:text-text-blue cursor-pointer ${
              checkRoute(e.key) ? 'text-text-blue' : 'text-text-gray'
            }`}
            key={e.key}
          >
            {e.name}
          </span>
        </Link>
      ))}
    </div>
  );
}

export default Menulist;
