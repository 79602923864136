import { AbiItem } from 'web3-utils';
import { Interface } from '@ethersproject/abi';
import web3 from 'hooks/web3';
import MultiCallAbi from '../contracts/abi/Multicall.json';
import { ACTIVE_CHAIN_ID, getEnv } from './env';

function getMulticallAddress() {
  return {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    31337: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
  }[ACTIVE_CHAIN_ID || 56] || getEnv("MULTICALL_ADDRESS");
}

interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (exemple: balanceOf)
  params?: any[]; // Function params
}

export const multicall = async (abi, calls) => {
  const multi = new web3.eth.Contract(
    MultiCallAbi as unknown as AbiItem,
    getMulticallAddress()
  );
  const itf = new Interface(abi);
  let res = [];
  if (calls.length > 100) {
    let i = 0;
    while (i < calls.length / 100) {
      const newCalls = calls
        .slice(i * 100, 100 * (i + 1))
        .map((call) =>
          call.address ? [call.address, call.name, call.params] : call
        );
      const calldata = newCalls.map((call) => [
        call[0].toLowerCase(),
        itf.encodeFunctionData(call[1], call[2]),
      ]);
      const { returnData } = await multi.methods.aggregate(calldata).call();
      i++;
      res = res.concat(
        returnData.map((call, index) =>
          itf.decodeFunctionResult(newCalls[index][1], call)
        )
      );
    }
  } else {
    const newCalls = calls.map((call) =>
      call.address ? [call.address, call.name, call.params] : call
    );
    const calldata = newCalls.map((call) => [
      call[0].toLowerCase(),
      itf.encodeFunctionData(call[1], call[2]),
    ]);
    const { returnData } = await multi.methods.aggregate(calldata).call();
    res = returnData.map((call, i) =>
      itf.decodeFunctionResult(newCalls[i][1], call)
    );
  }
  return res;
};

export default multicall;
