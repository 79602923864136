import React from 'react';
import { auctionsData } from 'config/mockdata';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import MasterItem from 'components/MasterItem';

function Auctions() {
  return (
    <div className="w-full md:px-32 px-10">
      <div className="flex justify-between items-center">
        <h1 className="text-5xl">{auctionsData.title}</h1>
        <div className="flex space-x-2 p-2 px-4 rounded-lg bg-bg-dark-light">
          <div className="w-10 h-10 rounded-full flex items-center justify-center text-xl custom-gradient-secondary cursor-pointer">
            <AiOutlineLeft />
          </div>
          <div className="w-10 h-10 rounded-full flex items-center justify-center text-xl custom-gradient-primary cursor-pointer">
            <AiOutlineRight />
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 items-center 
      place-content-center gap-y-10 gap-x-10 mt-8"
      >
        {auctionsData.master.map((e) => (
          <MasterItem data={e} key={e.key} />
        ))}
      </div>
      {/*  */}
    </div>
  );
}

export default Auctions;
