import React from 'react';
import { homeData } from 'config/mockdata';

import { GiMasterOfArms, GiBlackBridge } from 'react-icons/gi';
import { IoGameController } from 'react-icons/io5';
import { MdOutlinePriceChange } from 'react-icons/md';

const icon = [
  <GiMasterOfArms className="text-[5rem] icon-main" />,
  <IoGameController className="text-[5rem] icon-main" />,
  <MdOutlinePriceChange className="text-[5rem] icon-main" />,
  <GiBlackBridge className="text-[5rem] icon-main" />,
];

function GetStart() {
  return (
    <div className="w-full md:px-32 px-10">
      <h1 className="text-3xl md:text-5xl text-center border-y-2 py-6 my-6">
        {homeData.getstart.title}
      </h1>
      <div className="w-full grid grid-cols-2 gap-8">
        {homeData.getstart.item.map((e, i) => (
          <div
            key={e.key + 'getstart'}
            className="rounded-md bg-bg-dark-medium border-[1px] border-bg-dark-light flex flex-col p-12
          items-center justify-between shadow-md shadow-bg-dark-light space-y-4"
          >
            {icon[i]}
            <h2 className="text-3xl text-center">{e.title}</h2>
            <p className="text-center text-2xl">{e.dec}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GetStart;
