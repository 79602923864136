import React from 'react';
import Auctions from 'views/Auctions';
import Audits from './Audits';
import GetStart from './GetStart';
import NewLanding from './NewLanding';
import Partner from './Partner';
// import TopLanding from './TopLanding';

function Home() {
  return (
    <div className="w-full flex flex-col space-y-10">
      {/* <TopLanding /> */}
      <NewLanding />
      <Auctions />
      <GetStart />
      <Partner />
      <Audits />
    </div>
  );
}

export default Home;
