import { useState, useEffect, createContext, useContext, useCallback, Dispatch, SetStateAction } from 'react';
import { getLocal } from 'utils/localStorage';
// import { useWallet } from '@binance-chain/bsc-use-wallet';
import { useWeb3React } from '@web3-react/core';
import { injected } from 'connectors';
import { ACTIVE_CHAIN_ID, setActiveChainId } from 'utils/env';
import usePopup from './usePopup';

interface MultichainContextType {
  chainId: string;
  setChainId: Dispatch<SetStateAction<string>>;

  initialized: boolean;
  setInitialized: Dispatch<SetStateAction<boolean>>;
}

export const MultichainContext = createContext<MultichainContextType>({
  chainId: ACTIVE_CHAIN_ID,
  setChainId: (chainId) => {},

  initialized: false,
  setInitialized: (x: boolean) => {},
});

export function useEagerConnect() {
  const { activate, active, chainId } = useWeb3React();

  const [tried, setTried] = useState(false);

  const context = useContext(MultichainContext);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized && getLocal('isLogin')) {
        activate(injected).catch((err) => {
          console.log(err);
          setTried(true);
        });
      } else {
        console.log('err');
        setTried(true);
      }
    });
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active && chainId) {
      setTried(true);

      // If ACTIVE_CHAIN_ID is empty or not correct then set it
      if (window.localStorage.getItem('ACTIVE_CHAIN_ID') != chainId.toString()) {
        window.localStorage.setItem('ACTIVE_CHAIN_ID', chainId.toString());
        context.setChainId(chainId.toString());
        setActiveChainId(chainId.toString());
      }

      context.setInitialized(true)
      console.log("Initialized")
    }
  }, [tried, active, chainId]);

  useEffect(() => {
    if (chainId && context.initialized) {
      // If ACTIVE_CHAIN_ID is empty or not correct then set it
      if (window.localStorage.getItem('ACTIVE_CHAIN_ID') != chainId.toString()) {
        window.localStorage.setItem('ACTIVE_CHAIN_ID', chainId.toString());
        context.setChainId(chainId.toString());
        setActiveChainId(chainId.toString());
      }
    }
  }, [chainId])

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (
      ethereum &&
      ethereum.on &&
      !active &&
      !error &&
      !suppress &&
      getLocal('isLogin')
    ) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}

interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

declare const window: any;

const networkData = {
  3: {
    chainId: '0x3',
    chainName: 'Ropsten Test Network',
    rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://ropsten.etherscan.io'],
  },
  56: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  97: {
    chainId: '0x61',
    chainName: 'Binance Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
  80001: {
    chainId: '0x13881',
    chainName: 'Polygon Mumbai Testnet',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  43113: {
    chainId: '0xA869',
    chainName: 'Avalanche FUJI Testnet',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
  },
  4002: {
    chainId: '0xFA2',
    chainName: 'Fantom Testnet',
    rpcUrls: ['https://rpc.testnet.fantom.network'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.ftmscan.com'],
  },
  1287: {
    chainId: '0x507',
    chainName: 'Moonbase Alpha Testnet',
    rpcUrls: ['https://rpc.testnet.moonbeam.network'],
    nativeCurrency: {
      name: 'GLMR Development',
      symbol: 'DEV',
      decimals: 18,
    },
    blockExplorerUrls: ['https://moonbase.moonscan.io'],
  },
};

export function useOnSwitchChain(fn) {
  const { chainId } = useWeb3React();
  const context = useContext(MultichainContext);

  useEffect(() => {
    console.log(chainId, parseInt(context.chainId))
    if (chainId == parseInt(context.chainId)) {
      fn(context.chainId)
    }
  }, [context.chainId, chainId]);
}

export function useSwitchChain() {
  const { chainId: rawChainId } = useWeb3React();
  const popup = usePopup();
  const context = useContext(MultichainContext);

  useOnSwitchChain(() => {
    console.log("Switch chain")
    popup.clear();
  })

  return useCallback(async (chainId = ACTIVE_CHAIN_ID) => {
    if (!context.initialized) return;

    try {
      const { ethereum } = window;

      if (context.chainId != chainId) {
        popup.loading({
          title: 'Switching chain',
          text: 'Please confirm in your wallet',
          disable: true,
        })
      }

      if (ethereum && networkData[chainId]) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networkData[chainId].chainId }]
          });
        } catch (err) {
          if (err.code === 4902) {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [networkData[chainId]],
            });
          } else {
            console.error(err);
          }
        }
      }

      context.setChainId(chainId);
      setActiveChainId(chainId);
    } catch (err) {
      console.error(err);
      popup.error({
        title: 'Switch chain',
        text: 'Please switch chain in your wallet',
      })
    }
  }, [rawChainId, context.initialized]);
}

export const switchChainFunction = async (chainId = ACTIVE_CHAIN_ID) => {
  if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
    if (networkData[chainId]) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkData[chainId].chainId }]
        });
      } catch (err) {
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkData[chainId]],
          });
        } else {
          console.error(err);
        }
      }
    }
  }

  // try {
  //   await window.ethereum.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{ chainId: process.env.REACT_APP_CHAIN_ID_HEX }],
  //   });
  // } catch (err) {
  //   console.log('add network');
  // }
};

export const useReconnect = () => {
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const context = useWeb3React();
  const { connector } = context;
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager && !!activatingConnector);
};

export function useSwitchToCurrentChain() {
  const { chainId } = useWeb3React();
  useSwitchChain()(chainId.toString());
}
