const avatarList = {
  blackCat: '/images/avatar/blackCat.svg',
  blackCat2: '/images/avatar/blackCat2.svg',
  catRich: '/images/avatar/catRich.svg',
  catSwag: '/images/avatar/catSwag.svg',
  fox: '/images/avatar/fox.svg',
  oldCat: '/images/avatar/oldCat.svg',
  orangeCat: '/images/avatar/orangeCat.svg',
  pinkCat: '/images/avatar/pinkCat.svg',
  pinkCat2: '/images/avatar/pinkCat2.svg',
  whitecat: '/images/avatar/whitecat.svg',
};
const avatarkey = [
  'blackCat',
  'blackCat2',
  'catRich',
  'catSwag',
  'fox',
  'oldCat',
  'orangeCat',
  'pinkCat',
  'pinkCat2',
  'whitecat',
];

interface Props {
  select: string;
  onChange: (url: string, key: string) => any;
}

const AvatarSelect = ({ select, onChange }) => {
  return (
    <div className="w-full flex">
      {avatarkey.map((e) => {
        let style =
          'w-[4rem] h-[4rem] transition-all cursor-pointer m-2 p-1 rounded-full bg-bg-dark-light hover:bg-secondary1 flex items-center justify-center';
        if (e === select) {
          style = style + ' bg-secondary1';
        }
        return (
          <div
            key={e}
            className={style}
            onClick={() => {
              onChange('https://copycat.finance' + avatarList[e], e);
            }}
          >
            <img className="object-cover" alt={e} src={avatarList[e]} />
          </div>
        );
      })}
    </div>
  );
};

export default AvatarSelect;
