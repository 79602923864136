import { ENV } from "config/envData";

// function buildSupportedChains() {
//   const result = {};

//   for (const key in process.env) {
//     if (key.startsWith("REACT_APP_") && key.endsWith("_CHAIN_NAME")) {
//       const chainId = key.split('_')[2];
//       if (!result[chainId]) result[chainId] = { chainId: parseInt(chainId) };
//       result[chainId].chainName = process.env[key];
//     }

//     if (key.startsWith("REACT_APP_") && key.endsWith("_RPC_URL")) {
//       const chainId = key.split('_')[2];
//       if (!result[chainId]) result[chainId] = { chainId: parseInt(chainId) };
//       result[chainId].rpcUrl = process.env[key];
//     }
//   }

//   const result2 = [];

//   for (const chainId in result) {
//     result2.push({
//       ...result[chainId],
//     })
//   }

//   return result2;
// }

function buildSupportedChains() {
  const result = [];

  for (const chainId in ENV) {
    result.push({
      chainId: parseInt(chainId),
      chainName: ENV[chainId].CHAIN_NAME,
      rpcUrl: ENV[chainId].RPC_URL,
    })
  }

  return result;
}

export const SUPPORTED_CHAIN_LIST = buildSupportedChains();