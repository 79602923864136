import React, { useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FaHeart } from 'react-icons/fa';
import styled from 'styled-components';
import { CopyListData } from 'views/MasterList/copyListData';
import { Link } from 'react-router-dom';
import { addBookmark, isBookmarked, removeBookmark } from 'utils/bookmark';
import PopUpMasterGame from './PopUpMasterGame';
import GameList from './Gamelist';
import Modal from 'components/ModalPopup/Modal';
import CopyButton from './CopyButton';
import ButtonStyled from 'components/ButtonStyled';

const MasterItemWrapper = styled.div`
  .hearth {
    transition: all 0.5s ease-in-out;
    :hover {
      * {
        color: #ff7c6e;
      }
    }
    * {
      transition: all 0.5s ease-in-out;
      color: #ffffff;
    }
  }
  .hearth-active {
    transition: all 0.5s ease-in-out;
    * {
      transition: all 0.5s ease-in-out;
      color: #ff7c6e;
    }
  }
`;

interface Props {
  onlyShow: boolean;
  canCopy: boolean;
  level?: string;
  property: string;
  leaderName: string;
  description?: string;
  profit?: string;
  depositFee: string;
  depositPercentageFee: string;
  tvl: string;
  avatar: string;
  isdetail?: boolean;

  popup?: boolean;
  data?: CopyListData;
  refreshData: (addr?: string) => any;
  hidePopup: () => any;
}

function MasterItemFull(props: Props) {
  const [popUp, setPopUp] = useState(props.popup ?? false);
  const [popUpType, setPopUpType] = useState('');
  const [bookmarked, setBookmarked] = useState(
    isBookmarked(props.data?.address)
  );

  const [gamelist] = useState(['2048']);

  const handleClickCopy = (e) => {
    // props.refreshData(props.data.address);
    // disableScroll();
    setPopUpType('invest');
    setPopUp(true);
    e.stopPropagation();
  };

  const handleClickWithdraw = (e) => {
    // props.refreshData(props.data.address);
    // disableScroll();
    setPopUpType('withdraw');
    setPopUp(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (!popUp) props.hidePopup();
    return () => {
      props.hidePopup();
    };
  }, [popUp]);

  const depositPercentageFee = parseFloat(props.depositPercentageFee);
  const DescriptionSlice = () => {
    if (props.description.length >= 30) {
      return (
        <span onClick={handleClickDescription} className="description_link">
          {props.description.slice(0, 26) + ' ...'}
        </span>
      );
    } else {
      return (
        <span className="description_link" onClick={handleClickDescription}>
          {props.description}
        </span>
      );
    }
  };

  const NameSlice = () => {
    if (props.leaderName.length >= 16) {
      return (
        <span onClick={handleClickDescription} className="description_link">
          {props.leaderName.slice(0, 13) + ' ...'}
        </span>
      );
    } else {
      return <span>{props.leaderName}</span>;
    }
  };
  const handleClickDescription = (e) => {
    // disableScroll();
    // setPopUpDescription(true);
    // e.stopPropagation();
    // console.log('click');
  };

  const handleClickBookmark = () => {
    if (bookmarked) {
      removeBookmark(props.data.address);
    } else {
      addBookmark(props.data.address);
    }
    setBookmarked(!bookmarked);
  };

  return (
    <MasterItemWrapper>
      <div
        className={`${
          props.isdetail
            ? 'w-full'
            : 'w-[25rem] rounded-md bg-bg-dark-medium p-4 border-[1px] border-bg-dark-light shadow-md shadow-bg-dark-light'
        } h-auto flex flex-col space-y-4 relative`}
      >
        {/* header */}
        {!props.isdetail && (
          <div className="w-full flex justify-between items-center">
            <div className="relative">
              <img
                className="w-20 h-20"
                src={props.avatar || '/images/avatar/fox.svg'}
                alt="avatar-1"
              />

              {props.data.kyc && (
                <div className="w-8 h-8 text-sm flex items-center justify-center border-2 border-bg-dark-medium custom-gradient-secondary rounded-full absolute -right-1 -bottom-1">
                  <BsCheckLg />
                </div>
              )}
            </div>

            <h3 className="text-3xl">{NameSlice()}</h3>
          </div>
        )}
        {!props.isdetail && <p className="text-xl">{DescriptionSlice()}</p>}
        <div className="w-full">
          <GameList data={gamelist} />
        </div>
        <div className="flex flex-col items-center py-3 rounded-md border-[2px] border-secondary1">
          <span className="text-lg">Deposit Fees</span>
          <b className="text-secondary1 text-lg">
            {props.depositFee} COPYCAT{' '}
            {depositPercentageFee > 0
              ? `+ ${depositPercentageFee.toPrecision(2)}%`
              : ''}
          </b>
        </div>
        {/* fee */}
        {/* copy */}
        <div className="w-full">
          <CopyButton
            data={props.data}
            canCopy={props.canCopy}
            handleClickCopy={handleClickCopy}
            handleClickWithdraw={handleClickWithdraw}
          />
        </div>
        {/* copy */}
        {/* foot */}
        {!props.isdetail && (
          <div className="flex justify-between items-center">
            <p className="text-lg">TVL: ${props.tvl}</p>
            <div className="flex space-x-2 items-center">
              <Link to={`/master/${props.data.address}`}>
                <div className="custom-gradient-secondary-hover text-xl font-bold px-2 py-1">
                  See more
                </div>
              </Link>
              <div
                className={`text-3xl cursor-pointer ${
                  bookmarked ? 'hearth-active' : 'hearth'
                } `}
                onClick={handleClickBookmark}
              >
                <FaHeart />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* popup */}
      <Modal
        show={popUp}
        onClose={() => {
          // enableScroll();
          setPopUp(false);
        }}
      >
        <PopUpMasterGame
          state={popUp}
          setstate={setPopUp}
          data={props.data}
          refreshData={props.refreshData}
          type={popUpType}
          baseTokenAddress={props.data.copygameBaseTokenAddress}
          baseTokenSymbol={props.data.copygameBaseTokenSymbol}
          depositLimit={0}
        />
      </Modal>
    </MasterItemWrapper>
  );
}

export default MasterItemFull;
