import axios from 'axios';
import { getEnv } from './env';

let BNB_PRICE = {};

export function getBnbPrice(): Promise<number> {
  const symbol = getEnv("TOKEN_SYMBOL");

  return new Promise((resolve, reject) => {
    if (BNB_PRICE[symbol] > 0) {
      resolve(BNB_PRICE[symbol]);
    } else {
      let interval = setInterval(() => {
        if (BNB_PRICE[symbol] > 0) {
          clearInterval(interval);
          resolve(BNB_PRICE[symbol]);
        }
      }, 500);
    }
  });
}

export async function fetchBnbPrice() {
  const symbol = getEnv("TOKEN_SYMBOL");
  
  let response = await axios.get(
    'https://api.binance.com/api/v1/klines?symbol=' +
      symbol +
      '&interval=1m&limit=1'
  );
  BNB_PRICE[symbol] = parseFloat(response.data[0][1]);
  return BNB_PRICE[symbol];
}

export async function getOrFetchNativePrice(symbol) {
  if (BNB_PRICE[symbol]) return BNB_PRICE[symbol];

  let response = await axios.get(
    'https://api.binance.com/api/v1/klines?symbol=' +
      symbol +
      '&interval=1m&limit=1'
  );
  BNB_PRICE[symbol] = parseFloat(response.data[0][1]);
  return BNB_PRICE[symbol];
}
