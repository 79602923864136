import CopycatLeader, {
  loadLeaderGameHarvestTokens,
  loadTokensBalance,
} from 'contracts/CopycatLeader';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useBnbPrice } from 'hooks/useBnbPrice';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyListData } from 'views/MasterList/copyListData';
import BigNumber from 'bignumber.js';
import CopycatToken from 'contracts/CopycatToken';
import { fromWei, toWei } from 'utils/unitConversion';
import { capitalize, cloneDeep, debounce } from 'lodash';
import { formatNumber4 } from 'utils/formatNumber';
import CopycatDepositer from 'contracts/CopycatDepositer';
import web3 from 'hooks/web3';
import CopygameLeader from 'contracts/CopygameLeader';
import { VscSettings } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { enableScroll } from 'utils/scrollBody';
import styled from 'styled-components';
import ButtonStyled from 'components/ButtonStyled';
import InputInvest from './InputInvest';
import { FiAlertCircle } from 'react-icons/fi';
import callbackLoadingFn from 'hooks/callbackLoadingFn';
import { api } from 'utils/api';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import useCallbackFunction from 'hooks/callbackLoadingFn';
import { getEnv } from 'utils/env';
import CopycatLeaderReader from 'contracts/CopycatLeaderReader';

const PopUpMasterGameWrapper = styled.div``;

interface Props {
  data: CopyListData;
  state: boolean;
  type?: string;
  setstate?: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: (addr: string) => Promise<any>;
  baseTokenAddress: string;
  baseTokenSymbol: string;
  depositLimit: number;
}

function PopUpMasterGame(props: Props) {
  const { account } = useActiveWeb3React();
  const bnbPrice = useBnbPrice();
  const confirm = useConfirm();
  const popup = usePopup();
  const { call } = useCallbackFunction();

  const [copycatBalance, setCopycatBalance] = useState(0);
  const [leaderTokens, setLeaderTokens] = useState([]);
  const [tokensInput, setTokensInput] = useState([]);
  const [tokensBalance, setTokensBalance] = useState([]);
  const [tokensHarvestable, setTokensHarvestable] = useState([]);
  const [approvalRemain, setApprovalRemain] = useState([]);

  const [type, setType] = useState(props.type || 'invest');
  const [tokenMode, setTokenMode] = useState('buy');

  const [bnb, _setBnb] = useState('0');
  const [share, _setShare] = useState('0');

  async function refreshTokensBalance(tokens) {
    let tokensBalance = account
      ? await loadTokensBalance(
          account,
          tokens.map((c) => c.tokenAddress),
          props.data?.address
        )
      : [];
    setTokensBalance(tokensBalance);
    // console.log("Token Balances", tokensBalance);

    // Fetch harvestable balance
    const harvestable = await new CopycatLeaderReader(account).getHarvestableTokens(props.data?.address);
    console.log(harvestable);
    setTokensHarvestable(harvestable);
  }

  useEffect(() => {
    async function f() {
      let tokens = await loadLeaderGameHarvestTokens(props.data?.address);

      setLeaderTokens(tokens);
      setTokensInput(
        tokens.map((c) => ({
          ...c,
          amount: '0',
        }))
      );

      await refreshTokensBalance(tokens);
    }

    if (props.data?.address) {
      f();
    }
  }, [account, props.data?.address]);

  async function checkTokenApproved(token, spender, amount): Promise<boolean> {
    if (amount == 0) return true;

    let allowance = await new CopycatToken(account, token).allowance(
      account,
      spender
    );

    return new BigNumber(allowance).gte(new BigNumber(amount));
  }

  // Deal with multi token approval
  useEffect(() => {
    let result = [];

    if (type == 'harvest') {
      setApproved(true);
    } else {
      if (tokenMode == 'stake') {
        // if (tokensBalance.length == leaderTokens.length) {
        //   for (let i = 1; i < tokensBalance.length; i++) {
        //     if (parseFloat(tokensInput[i].amount) > parseFloat(tokensBalance[i].balance)) {
        //       result.push({
        //         tokenAddress: tokensInput[i].tokenAddress,
        //       });
        //     }
        //   }
        // }
        // setApprovalRemain(result);
      }

      checkTokenApproved(
        getEnv("COPYCAT_TOKEN"),
        getEnv("LEADER_STORAGE_CONTRACT"),
        toWei(props.data?.depositFee)
      ).then((approved) => {
        // if (tokenMode == 'stake') {
        // setApproved((account == props.data?.owner || approved) && result.length == 0);
        // } else {
        if (account === props.data?.owner || approved) {
          if (props.baseTokenAddress === getEnv("WETH_CONTRACT")) {
            setApproved(true);
          } else {
            checkTokenApproved(
              props.baseTokenAddress,
              props.data.address,
              toWei(bnb)
            ).then((result) => setApproved(result));
          }
        } else {
          setApproved(false);
        }
        // }
      });
    }
  }, [tokensInput, bnb, leaderTokens, tokensBalance, type]);

  function setTokensInputAmount(percentage, excludeIndex = -1) {
    let input = cloneDeep(tokensInput);

    for (let i = 0; i < leaderTokens.length; i++) {
      if (i == excludeIndex) continue;
      // console.log("LEADER BALANCE", leaderTokens[i].balance);
      input[i].amount = formatNumber4(leaderTokens[i].balance * percentage);
    }

    setTokensInput(input);
  }

  const [popupSlippage, setPopupSlippage] = useState(false);
  const [slippage, setSlippage] = useState(
    parseFloat(window.localStorage.getItem('masterSlippage')) || 0.005
  );
  const [bnbBalance, setBnbBalance] = useState(0);
  const [baseTokenBalance, setBaseTokenBalance] = useState(0);
  const [shareHoldingWallet, setShareHoldingWallet] = useState(0);
  const [shareHoldingStaked, setShareHoldingStaked] = useState(0);

  useEffect(() => {
    window.localStorage.setItem('masterSlippage', slippage.toFixed(4));
  }, [slippage]);

  function setShare(value) {
    // console.log(value);
    if (parseFloat(value) < 0) {
      _setShare('0');
    } else {
      _setShare(value);
    }
  }

  function setBnb(value) {
    if (parseFloat(value) < 0) {
      _setBnb('0');
    } else {
      _setBnb(value);
    }
  }

  const [depositParameter, setDepositParameter] = useState<(string | number)[]>(
    [0, 0, 0]
  );

  const [bnbWoPI, setBnbWoPI] = useState('0');
  const [shareWoPI, setShareWoPI] = useState('0');

  const [approved, setApproved] = useState(!props.data?.depositFee);

  const [approving, setApproving] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function _changeBnbUsingShare(value) {
    let targetBnb = value * props.data?.shareRatio;

    try {
      setBnb(
        fromWei(
          await new CopycatDepositer(
            props.data?.address,
            account
          ).withdrawSimulate(toWei(value))
        ).toFixed(4)
      );
    } catch (err) {
      setBnb(targetBnb.toFixed(4));
    }
  }

  const changeBnbUsingShare = useCallback(
    debounce(_changeBnbUsingShare, 500),
    []
  );

  async function onChangeShare(value, bnbMode = false, excludeIndex = -1) {
    setShare(value);

    // Note: we will ignore 0.0001 share to prevent calculation inaccuracy bug
    // value = parseFloat(value) - 0.0001;
    value = parseFloat(value);
    let percentage = value / props.data?.totalSupply;
    let targetBnb = value * props.data?.shareRatio;

    setTokensInputAmount(percentage, excludeIndex);

    if (!bnbMode) {
      changeBnbUsingShare(value);
    }

    setBnbWoPI(targetBnb.toFixed(4));
  }

  const [shareLoading, setShareLoading] = useState(false);

  async function _changeShareUsingBnb(value) {
    let percentageFee = 1 - parseFloat(props.data?.depositPercentageFee) / 100;

    setShareLoading(true);

    try {
      let result = await new CopycatLeader(
        props.data?.address,
        account
      ).depositSimulate(toWei(value));
      let shareAmount =
        fromWei(result[0]) *
        (1 - parseFloat(props.data?.depositPercentageFee) / 100);
      setShare(shareAmount.toFixed(4));
      setDepositParameter(result);
    } catch (err) {
      onChangeShare(
        ((value / props.data?.shareRatio) * percentageFee).toFixed(4),
        true
      );
    } finally {
      setShareLoading(false);
    }
  }

  const changeShareUsingBnb = useCallback(
    debounce(_changeShareUsingBnb, 500),
    []
  );

  async function onChangeBnb(value) {
    let percentageFee =
      account == props.data?.owner
        ? 1
        : 1 - parseFloat(props.data?.depositPercentageFee) / 100;

    setBnb(value);
    value = parseFloat(value);

    onChangeShare((value * percentageFee).toFixed(4), true);
    setShareWoPI((value * percentageFee).toFixed(4));
  }

  function onChangeToken(i, amount) {
    tokensInput[i].amount = amount;

    let percentage = parseFloat(amount) / leaderTokens[i].balance || 0;

    onChangeShare(props.data.totalSupply * percentage, false, i);
  }

  async function refreshData() {
    if (account) {
      const f1 = async () => {
        setBnbBalance(fromWei(await web3.eth.getBalance(account)));
      };
      f1();

      const f2 = async () => {
        setCopycatBalance(
          fromWei(
            await new CopycatToken(
              account,
              getEnv("COPYCAT_TOKEN"),
            ).balanceOf(account)
          )
        );
      };
      f2();

      const f3 = async () => {
        setBaseTokenBalance(
          fromWei(
            await new CopycatToken(account, props.baseTokenAddress).balanceOf(
              account
            )
          )
        );
      };
      f3();

      const f4 = async () => {
        setShareHoldingWallet(
          fromWei(
            await new CopygameLeader(props.data.address, account).balanceOf(
              account
            )
          )
        );
      };
      f4();

      const f5 = async () => {
        setShareHoldingStaked(
          fromWei(
            await new CopygameLeader(
              props.data.address,
              account
            ).stakedBalance()
          )
        );
      };
      f5();

      await props.refreshData(props.data?.address);
    }
  }

  useEffect(() => {
    refreshData();
  }, [account, type]);

  useEffect(() => {
    if (type == 'deposit') {
      if (tokenMode == 'single') {
        // setDepositParameter([0, 0, 0]);
      }
      onChangeBnb(bnb);
    } else {
      onChangeShare(formatNumber4(share));
    }
  }, [type, tokenMode]);

  // console.log(parseFloat(share), parseFloat(shareWoPI), props.data.shareRatio);

  const sharePercentage =
    props.data.shareHolding > 0.0000000001
      ? parseFloat(share) / props.data.shareHolding
      : 0;
  const sharePriceImpact = !parseFloat(shareWoPI)
    ? 0
    : Math.max(1 - parseFloat(share) / parseFloat(shareWoPI), 0);
  const bnbPriceImpact = !parseFloat(bnbWoPI)
    ? 0
    : Math.max(1 - parseFloat(bnb) / parseFloat(bnbWoPI), 0);

  useEffect(() => {
    if (props.data.shareHolding <= 0.0000000001) {
      setType('invest');
    }
  }, [props.data.shareHolding]);

  const refreshAfter = async () => {
    await refreshData();
    api.post('/myleaders/' + account);
    enableScroll();
    props.setstate(false);
  };

  const Header = () => (
    <div className="w-full flex justify-between">
      <div className="flex space-x-6 text-4xl">
        <p
          onClick={() => {
            setType('invest');
            setBnb('0');
            setShare('0');
            setPopupSlippage(false);
          }}
          className={`cursor-pointer transition-all border-b-2 hover:border-secondary1 hover:text-secondary1 ${
            type === 'invest'
              ? 'border-secondary1 text-secondary1'
              : 'border-transparent'
          }`}
        >
          INVEST
        </p>
        {shareHoldingStaked + shareHoldingWallet > 0.0000000001 && (
          <p
            onClick={() => {
              setType('harvest');
              onChangeShare((shareHoldingStaked + shareHoldingWallet).toFixed(4));
              setPopupSlippage(false);
            }}
            className={`cursor-pointer transition-all border-b-2 hover:border-secondary1 hover:text-secondary1 ${
              type === 'harvest'
                ? 'border-secondary1 text-secondary1'
                : 'border-transparent'
            }`}
          >
            HARVEST
          </p>
        )}
      </div>
      <div className="flex space-x-6 text-4xl">
        <VscSettings
          onClick={() => {
            setPopupSlippage(!popupSlippage);
          }}
          className={`cursor-pointer icon-hover ${
            popupSlippage && 'icon-hover-active'
          }`}
        />

        <AiOutlineClose
          onClick={() => {
            props.setstate(false);
            enableScroll();
          }}
          className="cursor-pointer icon-hover"
        ></AiOutlineClose>
      </div>
    </div>
  );

  const onClickInvestHead = (e) => {
    setTokenMode(e);
    setBnb('0');
    setShare('0');
    setPopupSlippage(false);
  };

  const actionInvest = ['buy', 'stake', 'unstake'];

  const HeaderInvest = () => (
    <div className="flex items-center space-x-4">
      <div className="flex">
        {actionInvest.map((e) => (
          <div
            key={e}
            onClick={() => onClickInvestHead(e)}
            className={`text-3xl ${
              tokenMode === e ? 'active-btn-active' : 'active-btn'
            }`}
          >
            {capitalize(e)}
          </div>
        ))}
      </div>
    </div>
  );

  const SlipageSetting = () => (
    <div className="flex flex-col p-4 bg-bg-dark-dark rounded-md space-y-6">
      <h2 className="text-4xl text-secondary1">Slippage tolerance</h2>
      <div className="flex text-3xl">
        <div
          className={`active-btn ${slippage === 0.001 && 'active-btn-active'}`}
          onClick={() => setSlippage(0.001)}
        >
          0.1%
        </div>
        <div
          className={`active-btn ${slippage === 0.005 && 'active-btn-active'}`}
          onClick={() => setSlippage(0.005)}
        >
          0.5%
        </div>
        <div
          className={`active-btn ${slippage === 0.01 && 'active-btn-active'}`}
          onClick={() => setSlippage(0.01)}
        >
          1.0%
        </div>
        <div className="flex items-center relative">
          <input
            className="custom-input"
            value={(slippage * 100).toFixed(2)}
            onChange={(e) => setSlippage(parseFloat(e.target.value) / 100)}
          />
          <span className="absolute right-1">%</span>
        </div>
      </div>

      <ButtonStyled
        className="w-full text-3xl"
        onClick={() => setPopupSlippage(false)}
        color="secondary"
      >
        Confirm
      </ButtonStyled>
    </div>
  );

  const InvestBNB = () => (
    <React.Fragment>
      <div className="w-full flex flex-col rounded-md p-3 space-y-4">
        <InputInvest
          token={props.baseTokenSymbol}
          amount="Pay"
          inputValue={bnb}
          inputLabel="Balance"
          inputCurrency={props.baseTokenSymbol}
          labelValue={
            props.baseTokenAddress.toLowerCase() === getEnv("WETH_CONTRACT").toLowerCase()
              ? bnbBalance.toFixed(4)
              : formatNumber4(baseTokenBalance)
          }
          onChangeValue={onChangeBnb}
        />
        <div>
          <InputInvest
            token="SHARE"
            amount={'Receive'}
            inputValue={shareLoading ? 'Loading...' : share}
            inputLabel="Balance"
            inputCurrency={props.data?.tokenSymbol}
            labelValue={formatNumber4(shareHoldingStaked + shareHoldingWallet)}
            // onChangeValue={onChangeShare}
            onChangeValue={() => {}}
            autoFocus={false}
          />
        </div>
      </div>
      {props.depositLimit ? (
        <div
          className="flex items-center"
          style={{ fontSize: '1.5rem', justifyContent: 'space-between' }}
        >
          <b className="mr-2">Deposit Limit:</b>{' '}
          {formatNumber4(props.depositLimit)} {props.baseTokenSymbol}
        </div>
      ) : (
        <></>
      )}
      {account !== props.data?.owner && (
        <div
          className="flex items-center"
          style={{ marginTop: 8, marginBottom: 12 }}
        >
          Deposit Fees : {props.data?.depositFee} CPC +{' '}
          {props.data?.depositPercentageFee}% <FiAlertCircle className="ml-2" />
        </div>
      )}
      <div className="flex items-center mx-auto">
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          01
        </div>
        <div className="h-1 w-[10rem] bg-secondary2 mx-4" />
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          02
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-2">
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={approved || approving}
          onClick={async () => {
            await call(
              async () => {
                let copycatApproved = await checkTokenApproved(
                  getEnv("COPYCAT_TOKEN"),
                  getEnv("LEADER_STORAGE_CONTRACT"),
                  toWei(props.data?.depositFee)
                );

                if (!copycatApproved) {
                  await new CopycatToken(account).approve(
                    getEnv("LEADER_STORAGE_CONTRACT")
                  );
                }

                if (
                  props.baseTokenAddress.toLowerCase() != getEnv("WETH_CONTRACT").toLowerCase()
                ) {
                  await new CopycatToken(
                    account,
                    props.baseTokenAddress
                  ).approve(props.data.address);
                }
              },
              () => {
                setApproved(true);
              },
              'approve token'
            );
          }}
        >
          {approving ? 'Processing...' : 'Approve'}
        </ButtonStyled>
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={
            !approved ||
            shareLoading ||
            submitting ||
            copycatBalance < parseFloat(props.data?.depositFee)
          }
          onClick={async () => {
            await call(
              () =>
                new CopygameLeader(props.data?.address, account).deposit(
                  toWei(parseFloat(bnb) / props.data.totalSupply),
                  props.baseTokenAddress.toLowerCase() == getEnv("WETH_CONTRACT").toLowerCase()
                    ? toWei(parseFloat(bnb) * (1 + slippage))
                    : 0,
                  props.baseTokenAddress,
                  toWei(parseFloat(bnb) * (1 + slippage))
                ),
              async () => {
                setSubmitting(true);
                await refreshAfter();
              },
              `Deposit ${props.data?.leaderName} ${bnb} ${props.data?.copygameBaseTokenSymbol} for ${
                parseFloat(share)
              } ${props.data?.tokenSymbol}`
            );
          }}
        >
          {submitting
            ? 'Processing...'
            : copycatBalance < parseFloat(props.data?.depositFee)
            ? 'Insufficient Copycat'
            : props.depositLimit > 0 &&
              props.data.totalSupply + parseFloat(share) > props.depositLimit
            ? 'Sold Out'
            : 'Deposit'}
        </ButtonStyled>
      </div>
    </React.Fragment>
  );

  const InvestStake = () => (
    <React.Fragment>
      <div className="p-4 border-2 border-red-500 rounded-md text-lg">
        This feature is used for P2P trading. After you buy your share token
        from P2P market, you must stake your token in this tab in order to
        receive the reward from the share token.
      </div>

      <div>
        <InputInvest
          token={'SHARE'}
          amount="To stake"
          inputValue={share}
          inputLabel="In wallet"
          inputCurrency={props.data?.tokenSymbol}
          labelValue={formatNumber4(shareHoldingWallet)}
          onChangeValue={(val) => setShare(val)}
        />
      </div>

      <div style={{ fontSize: '1.5rem', flexDirection: 'column' }}>
        <div>
          <b>Staked:</b> {formatNumber4(shareHoldingStaked)}{' '}
          {props.data?.tokenSymbol}
        </div>
        <div>
          <b>In wallet:</b> {formatNumber4(shareHoldingWallet)}{' '}
          {props.data?.tokenSymbol}
        </div>
      </div>

      <div className="flex items-center mx-auto">
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          01
        </div>
        <div className="h-1 w-[10rem] bg-secondary2 mx-4" />
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          02
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-2">
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={true}
          onClick={async () => {
            setApproved(true);
          }}
        >
          {approving ? 'Processing...' : 'Approve'}
        </ButtonStyled>
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={submitting}
          onClick={async () => {
            await call(
              () =>
                new CopygameLeader(props.data?.address, account).stake(
                  toWei(parseFloat(share))
                ),
              refreshAfter,
              `Stake ${parseFloat(share)} ${props.data?.tokenSymbol}`
            );
          }}
        >
          {submitting ? 'Processing...' : 'Stake'}
        </ButtonStyled>
      </div>
    </React.Fragment>
  );

  const InvestUnstake = () => (
    <React.Fragment>
      <div className="p-4 border-2 border-red-500 rounded-md text-lg">
        This feature is used for P2P trading. If you want to sell your token in
        P2P market you must unstake your token first. After you have sold your
        token, you must inform buyer to stake their token in &quot;Stake&quot;
        tab in order to receive the reward from the share token.
      </div>

      <div>
        <InputInvest
          token={'SHARE'}
          amount="To unstake"
          inputValue={share}
          inputLabel="Staked"
          inputCurrency={props.data?.tokenSymbol}
          labelValue={formatNumber4(shareHoldingStaked)}
          onChangeValue={(val) => setShare(val)}
        />
      </div>

      <div style={{ fontSize: '1.5rem', flexDirection: 'column' }}>
        <div>
          <b>Staked:</b> {formatNumber4(shareHoldingStaked)}{' '}
          {props.data?.tokenSymbol}
        </div>
        <div>
          <b>In wallet:</b> {formatNumber4(shareHoldingWallet)}{' '}
          {props.data?.tokenSymbol}
        </div>
      </div>

      <div className="flex items-center mx-auto">
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          01
        </div>
        <div className="h-1 w-[10rem] bg-secondary2 mx-4" />
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          02
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-2">
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={true}
          onClick={async () => {
            setApproved(true);
          }}
        >
          {approving ? 'Processing...' : 'Approve'}
        </ButtonStyled>
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={submitting}
          onClick={async () => {
            await call(
              () =>
                new CopygameLeader(props.data?.address, account).unstake(
                  toWei(parseFloat(share))
                ),
              refreshAfter,
              `Unstake ${parseFloat(share)} ${props.data?.tokenSymbol}`
            );
          }}
        >
          {submitting ? 'Processing...' : 'Unstake'}
        </ButtonStyled>
      </div>
    </React.Fragment>
  );

  const HarvestTokens = () => (
    <React.Fragment>
      <div className="w-full">
        {/* <h3>Amount to withdraw</h3>
        <h3 style={{ textAlign: 'right' }}>{(sharePercentage * 100).toFixed(0)} %</h3>
        <h2>${(parseFloat(bnb) * bnbPrice).toFixed(0)}</h2>
        <div className="inputSize_contain">
          <InputSlider
            value={sharePercentage * 100}
            onChange={(percent) => {
              onChangeShare(((percent / 100) * props.data.shareHolding).toFixed(4));
            }}
          />
        </div>
      </div>
      <div className="input_container">
        <InputWithdraw
          token="SHARE TOKEN"
          tokenLabel="To Burn"
          valueToken={share}
          labelvalue={(sharePercentage * 100).toFixed(0) + '%'}
        />

        <hr /> */}
        <div className="w-full bg-bg-dark-dark rounded-md p-4">
          <h3 className="text-2xl mb-4">To receive</h3>

          <div
            className="w-full"
            style={{ overflowY: 'auto', maxHeight: 300, marginBottom: 24 }}
          >
            {tokensHarvestable.map((token) => (
              <div
                className="w-full flex justify-between border-b-2 border-bg-dark-light"
                key={token.tokenAddress}
              >
                <div className="text-secondary1  text-lg">
                  {token.tokenSymbol}
                </div>
                <div className="text-secondary1  text-lg">{fromWei(token.amount, token.decimals)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        {/* <p>
          Withdraw Fees : {props.data?.depositPercentageFee} CPC <FiAlertCircle className="ml-2" />
        </p> */}
      </div>

      <div className="flex items-center mx-auto">
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          01
        </div>
        <div className="h-1 w-[10rem] bg-secondary2 mx-4" />
        <div
          className={`text-xl w-14 flex justify-center items-center h-14 rounded-full transition-all ${
            !approved ? 'bg-bg-dark-light' : 'bg-secondary2'
          }`}
        >
          02
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-2">
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={true}
          onClick={async () => {
            await call(
              () =>
                new CopycatToken(account).approve(
                  getEnv("LEADER_STORAGE_CONTRACT")
                ),
              () => {},
              'approve token'
            );
          }}
        >
          {approving ? 'Processing...' : 'Approve'}
        </ButtonStyled>
        <ButtonStyled
          className="w-full text-2xl h-full"
          color="secondary"
          disabled={submitting}
          onClick={async () => {
            await call(
              () => new CopygameLeader(props.data?.address, account).harvest(),
              refreshAfter,
              `Harvesting ${props.data?.leaderName}`
            );
          }}
        >
          {submitting ? 'Processing...' : 'Harvest'}
        </ButtonStyled>
      </div>
    </React.Fragment>
  );

  return (
    <PopUpMasterGameWrapper className="popup-container">
      <Header />
      <div className="w-full my-4 h-1 bg-bg-dark-light"></div>
      {!popupSlippage && type === 'invest' && <HeaderInvest />}
      {!popupSlippage && type === 'invest' && tokenMode === 'buy' && (
        <InvestBNB />
      )}
      {!popupSlippage && type === 'invest' && tokenMode === 'stake' && (
        <InvestStake />
      )}
      {!popupSlippage && type === 'invest' && tokenMode === 'unstake' && (
        <InvestUnstake />
      )}

      {!popupSlippage && type === 'harvest' && <HarvestTokens />}

      {popupSlippage && <SlipageSetting />}
    </PopUpMasterGameWrapper>
  );
}

export default PopUpMasterGame;
