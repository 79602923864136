import React from 'react';
import { footerData } from 'config/mockdata';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaDiscord, FaFacebookF } from 'react-icons/fa';

const iconFix = {
  twitter: <AiOutlineTwitter />,
  discord: <FaDiscord />,
  facebook: <FaFacebookF />,
};

function Footer() {
  return (
    <div className="w-full flex flex-col bg-bg-dark-light pt-10">
      <div className="md:px-32 px-10">
        {/* <div className="w-full flex flex-col space-y-4 mb-10">
          <h1 className="text-3xl">{footerData.update}</h1>
          <div className="w-full flex items-center relative space-x-4 justify-center">
            <input
              placeholder="Your Mail Address"
              className="custom-input h-[5rem]"
            />
            <button className="w-[16rem] absolute right-2 rounded-md h-16 custom-gradient-primary-hover py-2 text-2xl">
              SUBSCRIBE NOW
            </button>
          </div>
        </div> */}

        <div className="w-full flex flex-col space-y-2">
          <h1 className="text-3xl">{footerData.join}</h1>
          <div className="flex space-x-4">
            {footerData.community.map((e) => (
              <div
                key={e.key + 'icon-footer'}
                className="w-16 h-16 rounded-md text-3xl flex items-center justify-center custom-gradient-secondary-hover"
              >
                {iconFix[e.key]}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="w-full grid grid-cols-2 gap-16">
          {footerData.menu.map((e, i) => (
            <ul className="w-full" key={'menu-list' + i}>
              {e.map((j) => (
                <li
                  key={'menu-item' + j.key}
                  className="text-xl my-4 hover:text-text-blue cursor-pointer"
                >
                  ◉ {j.key}
                </li>
              ))}
            </ul>
          ))}
        </div> */}
      </div>
      <div className="w-full mt-10 h-20 flex items-center justify-center text-xl bg-bg-dark-dark">
        {footerData.foot}
      </div>
    </div>
  );
}

export default Footer;
