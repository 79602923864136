import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import SideNavbar from './SideNavbar';

function Layouts({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full min-h-screen bg-bg-dark-dark relative">
      <Navbar />
      <div className="py-24 min-h-screen">{children}</div>
      <Footer />
      <SideNavbar />
    </div>
  );
}

export default Layouts;
