export function getBookmark(): string[] {
  return JSON.parse(window.localStorage.getItem('bookmarkedLeaders') || '[]');
}

export function addBookmark(address) {
  let bookmark = getBookmark();
  if (bookmark.indexOf(address) == -1) {
    bookmark.push(address);
  }

  window.localStorage.setItem('bookmarkedLeaders', JSON.stringify(bookmark));
}

export function removeBookmark(address) {
  let bookmark = getBookmark();
  if (bookmark.indexOf(address) != -1) {
    bookmark.splice(bookmark.indexOf(address), 1);
  }

  window.localStorage.setItem('bookmarkedLeaders', JSON.stringify(bookmark));
}

export function isBookmarked(address) {
  let bookmark = getBookmark();
  return bookmark.indexOf(address) !== -1;
}
