import { TokenView } from 'config/tokens';
import { usePercentageBnbValueDivide } from 'hooks/usePercentageDivide';
import React, { useState } from 'react';
import { formatNumber4 } from 'utils/formatNumber';
import TokenAsset from './TokenAsset';

function TokenAll(props: { tokens: TokenView[]; baseTokenAddress: string }) {
  let tokens: TokenView[] = [...(props.tokens || [])];

  const [distributeToken, setDistributeToken] = useState<TokenView>(
    tokens.find((x) => x.tokenAddress == props.baseTokenAddress)
  );

  const [values, percentages] = usePercentageBnbValueDivide(
    tokens.map((x) => x.bnbValue)
  );

  let tokensDivided =
    tokens.map((token, i) => ({
      key: i + 1,
      tokenDetail: {
        tokenName: token.tokenSymbol,
        tokenImage: token.logo,
        tokenAddress: token.tokenAddress,
      },
      amount: formatNumber4(token.balance || 0),
      value: values[i],
      percentage: percentages[i],
    })) || [];

  return (
    <div className="flex flex-wrap">
      {tokensDivided.map((e) => {
        return (
          <TokenAsset
            key={e.key}
            name={e.tokenDetail.tokenName}
            address={e.tokenDetail.tokenAddress}
            image={e.tokenDetail.tokenImage}
            percentage={e.percentage}
          />
        );
      })}
    </div>
  );
}

export default TokenAll;
