import axios from 'axios';
import { getEnv } from './env';

export let api = axios.create({
  baseURL: getEnv("API_SERVER"),
});

export function updateApiAxiosObject() {
  api = axios.create({
    baseURL: getEnv("API_SERVER"),
  });
}