import ButtonStyled from 'components/ButtonStyled';
import { homeData } from 'config/mockdata';
import React from 'react';
import { Link } from 'react-router-dom';

function NewLanding() {
  const gc = 'custom-gradient-text-primary';
  return (
    <div className="w-full h-[50rem] maxlg:h-[70vh] maxlg:flex-col-reverse maxlg:items-center maxlg:justify-center relative flex items-center justify-around">
      <img
        className="w-full h-full object-cover absolute left-0 top-0"
        src={homeData.top.bg}
        alt="bg"
      />

      <img
        className="lg:hidden w-[30rem] mt-[10%] relative animate-fly"
        src={homeData.top.cartoon}
        alt="bg-cartoon"
      />
      <div className="relative flex items-center pl-14 maxlg:pl-0 maxlg:pt-28">
        <div className="flex flex-col space-y-8 maxlg:items-center">
          <h1 className="text-[3rem] xl:text-[4.5rem] font-[900] maxlg:text-center">
            <span className={gc}>Enable</span> Cross-Chain
            <br /> Decentralized Guild <span className={gc}>Solutions</span>
          </h1>
          <h2 className="text-3xl font-bold maxlg:text-center">
            {homeData.top.dis}
          </h2>
          <div className="flex space-x-4">
            {homeData.top.btn.map((e) => (
              <Link to={e.link}>
                <ButtonStyled className="w-[20rem]" color={e.theme} key={e.key}>
                  {e.name}
                </ButtonStyled>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <img
        className="maxlg:hidden w-[40rem] object-cover animate-fly"
        src={homeData.top.cartoon}
        alt="bg-cartoon"
      />
      {/*  */}
    </div>
  );
}

export default NewLanding;
