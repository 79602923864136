import { useBnbPrice } from "./useBnbPrice";

export function percentageDivide(list) {
  let sum = list.reduce((a, b) => a+b, 0);
  if (sum == 0) return 0;
  return list.map(x => x/sum);
}

export function usePercentageBnbValueDivide(list) {
  const bnbPrice = useBnbPrice()
  const values = list.map(x => x * bnbPrice);
  return [values, percentageDivide(values)];
}