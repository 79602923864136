import React from 'react';

interface PropsInputinvest {
  token: string;
  amount: string;
  inputValue: string;
  onChangeValue?: (val) => any;
  inputLabel: string;
  labelValue: string;
  inputCurrency: string;
  autoFocus?: boolean;
}

export default function InputInvest(props: PropsInputinvest) {
  return (
    <div className="w-full h-[7rem] grid grid-cols-8 items-center bg-bg-dark-dark p-2 rounded-md">
      <div className="col-span-2 rounded-l-md flex flex-col px-4 justify-center text-2xl bg-secondary2 border-[1px] border-bg-light-medium border-r-0">
        <h2>{props.token}</h2>
        <h3>{props.amount}</h3>
      </div>
      <div className="col-span-6 h-full relative flex items-center">
        <input
          autoFocus={props.autoFocus === false ? false : true}
          className="custom-input h-full"
          value={Number(props.inputValue) >= 0 ? props.inputValue : ''}
          onChange={(e: any) => {
            if (props.onChangeValue) props.onChangeValue(e.target.value);
          }}
          type={'text'}
          style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
        />
        <h3 className="text-right text-lg text-secondary1 absolute right-2">
          {props.inputLabel} : {props.labelValue} {props.inputCurrency}
        </h3>
      </div>
    </div>
  );
}
