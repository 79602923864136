export const BRIDGE_DEFAULT_TOKEN = {
  3: "WBNB",
  97: "WBNB",
  1287: "WBNB",
  4002: "WBNB",
  43113: "WAVAX",
  80001: "WBNB",
}

export const BRIDGE_TOKEN = {
  "WBNB": {
    symbol: "WBNB",
    axelarSymbol: "WBNB",
    decimals: 18,
    tokenAddresses: {
      1287: "0x8d0BBbA567Ae73a06A8678e53Dc7ADD0AF6b7039",
      4002: "0x8DA729FC44366eFE36d522B865FeC34653e85F6e",
      3: "0x653044Df3e853e8FF96c8D9a7Ab7A90E34c4d484",
      43113: "0xd020f566723e8402f925A891605c02ce7AF2477F",
      80001: "0x55fDE07dEF3261a41fC59B783D27A6357e8A86Df",
      97: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    }
  },
  "WETH": {
    symbol: "WETH",
    axelarSymbol: "WETH",
    decimals: 18,
    tokenAddresses: {
      1287: "0xc40Fdaa2cB43C85eAA6D43856df42E7A80669fca",
      4002: "0x930640ef299Bf772f786Cf7E88DA951D76E33168",
      3: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
      43113: "0x3613C187b3eF813619A25322595bA5E297E4C08a",
      80001: "0xfba15fFF35558fE2A469B96A90AeD7727FE38fAE",
      97: "0x03Dc012b7851b7D65592Aebc40a6aF9A171E9315",
    }
  },
  "WAVAX": {
    symbol: "WAVAX",
    axelarSymbol: "WAVAX",
    decimals: 18,
    tokenAddresses: {
      1287: "0x64aae6319934995Bf30e67EBBBA9750256E07283",
      4002: "0x8776aDD48553518641a589C39792cc409d4C8B84",
      3: "0x72af7e1e7E0D38bCF033C541598F5a0301D051A5",
      43113: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
      80001: "0x6DD60c05FdA1255A44Ffaa9A8200b5b179A578D6",
      97: "0x1B29EC62efC689c462b4E0512457175793cEc9e6",
    }
  },
  "WMATIC": {
    symbol: "WMATIC",
    axelarSymbol: "WMATIC",
    decimals: 18,
    tokenAddresses: {
      1287: "0xde3dB4FD7D7A5Cc7D8811b7BaFA4103FD90282f3",
      4002: "0x3C12d813bb36295A8361C4740A732Bb700df6Db0",
      3: "0xEAE61FD42A56F435a913d1570fF301a532d027b2",
      43113: "0xB923E2374639D0605388D91CFedAfCeCE03Cfd8f",
      80001: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      97: "0x920fA0DbB65cE928C29103AeC7B5c188bbea2f24",
    }
  }
}