import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useBnbPrice } from 'hooks/useBnbPrice';
import React, { useEffect, useState } from 'react';
import {
  CopyListData,
  resolveLeaderTypeFrontend,
  formatCopyListData,
} from './copyListData';
import { getBookmark } from 'utils/bookmark';
import { api } from 'utils/api';
import {
  loadDepositedLeaderAddresses,
  loadLastLeaderAddresses,
  loadOwnedLeaderAddresses,
} from 'contracts/CopycatLeaderFactory';
import { loadLeaders } from 'contracts/CopycatLeader';
import { uniq } from 'lodash';
import useInterval from 'hooks/useInterval';
import Loading from 'components/Loading';
import MasterItemFull from 'components/MasterItemFull';
import { AiFillStar, AiOutlineUser } from 'react-icons/ai';
import { GiLaurelsTrophy, GiPlug } from 'react-icons/gi';

const ranking = [
  {
    name: 'Amateur',
    key: 'amateur',
    icon: <AiOutlineUser />,
    level: 0,
  },
  {
    name: 'Professional',
    key: 'professional',
    icon: <GiLaurelsTrophy />,
    level: 1,
  },
  {
    name: 'Star',
    key: 'star',
    icon: <AiFillStar />,
    level: 2,
  },
  {
    name: 'My Master',
    key: 'copyprofile',
    icon: <GiPlug />,
    level: null,
  },
];

function MasterList(props) {
  const [loading, setLoading] = useState(true);
  const bnbPrice = useBnbPrice();
  const [type, setType] = useState(props.type || 'amateur');
  const [copyListData, setCopyListData] = useState<CopyListData[]>([]);
  const [popupAddress, setPopupAddress] = useState('');
  const { account, active } = useActiveWeb3React();

  // Filtor
  const [pnlMode, setPnlMode] = useState('24h-usd');
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  const handleClickType = (e: string) => {
    setType(e);
  };

  function formatCopyListData2(data, apiData: any = {}) {
    let result = formatCopyListData(data, { bnbPrice, ...apiData }, pnlMode);
    result.type =
      type === 'professional' || type === 'amateur'
        ? resolveLeaderTypeFrontend(Math.floor(data.tier / 10))
        : type;
    return result;
  }

  async function loadCopyListData() {
    let hasAccount = active;

    let rawCopyListData;
    let apiData = [];

    let bookmark = getBookmark();

    if (search) {
      apiData = (await api.get('/cache/leaders/search?q=' + search)).data;
    } else {
      if (type === 'amateur') {
        apiData = (
          await api.get(
            '/cache/leaders?type=amateur&bookmark=' +
              bookmark.join(',') +
              (search ? '&search=' + search : '')
          )
        ).data;
      } else {
        apiData = (
          await api.get(
            '/cache/leaders?type=professional&bookmark=' +
              bookmark.join(',') +
              (search ? '&search=' + search : '')
          )
        ).data;
      }
    }

    switch (type) {
      case 'amateur':
      case 'professional': {
        let apiAddresses = apiData.map((c) => c.address);
        let eventAddresses =
          apiAddresses.length < 50 ? await loadLastLeaderAddresses() : [];
        let addresses = uniq([...apiAddresses, ...eventAddresses]);
        rawCopyListData = await loadLeaders(addresses, account);
        break;
      }

      case 'copyprofile': {
        apiData = (await api.get('/cache/myleaders/' + account)).data;
        let apiAddresses = apiData.map((c) => c.address);
        let eventAddresses = await loadDepositedLeaderAddresses(account);
        let addresses = uniq([...apiAddresses, ...eventAddresses]);
        rawCopyListData = await loadLeaders(addresses, account);
        break;
      }

      case 'masterboard': {
        apiData = (await api.get('/cache/mymasters/' + account)).data;
        let apiAddresses = apiData.map((c) => c.address);
        let eventAddresses = await loadOwnedLeaderAddresses(account);
        let addresses = uniq([...apiAddresses, ...eventAddresses]);
        rawCopyListData = await loadLeaders(addresses, account);
        break;
      }
    }

    rawCopyListData = rawCopyListData.filter((x) => x.leaderType == 'game');

    if (search) {
      rawCopyListData = rawCopyListData.filter(
        (c) =>
          c.tokenName.indexOf(search) != -1 ||
          c.description.indexOf(search) != -1
      );
    }

    let formattedCopyListData: CopyListData[] = [];

    // Prevent racing condition
    if (!hasAccount && account) return;

    for (let data of rawCopyListData) {
      // console.log(apiData.find((c) => c.address == data.address));
      formattedCopyListData.push(
        formatCopyListData2(
          data,
          apiData ? apiData.find((c) => c.address == data.address) || {} : {}
        )
      );
    }

    setCopyListData(formattedCopyListData);
  }

  async function refreshSingleCopyListData(address) {
    setPopupAddress(address);

    let rawCopyListData = (await loadLeaders([address], account))[0];
    let apiData = (await api.get('/cache/leaders/' + address)).data;

    for (let i in copyListData) {
      if (copyListData[i].address == address) {
        let level = copyListData[i].level;
        // rawCopyListData.tier = level * 10;
        copyListData[i] = formatCopyListData2(rawCopyListData, apiData);
        // copyListData[i].level = level;
        break;
      }
    }

    setCopyListData([...copyListData]);
  }

  async function performSearch(e) {
    if (e && e.preventDefault) e.preventDefault();
    setSearch(searchText);
  }

  useEffect(() => {
    setLoading(true);
    loadCopyListData().then(() => {
      setLoading(false);
    });

    setTimeout(() => {
      loadCopyListData().then(() => {
        setLoading(false);
      });
    }, 1000);

    return () => {
      setCopyListData([]);
    };
  }, [account, type, search, pnlMode]);

  useInterval(loadCopyListData, 12000);
  let indexStabi = null;
  copyListData.forEach((val, index) => {
    if (val.address === '0xaf596EBEAF7b06571B39A5F88674B65832eaa6b8') {
      indexStabi = index;
      return;
    }
  });

  const copyListDataClone = [...copyListData];
  if (indexStabi) {
    const stabilizerElement = copyListDataClone[indexStabi];
    copyListDataClone.splice(indexStabi, 1);
    copyListDataClone.unshift(stabilizerElement);
  }

  return (
    <div className="w-full relative md:px-32 px-10 my-20 flex flex-col items-center space-y-10">
      <h2 className="text-5xl mb-2 text-center">Master List</h2>
      <div className="w-full py-2 border-y-2 border-bg-light-medium flex flex-col space-y-4">
        <b className="text-3xl">Trending Now</b>
        <div className="w-full flex flex-wrap">
          {ranking.map((e) => {
            return (
              <div
                key={e.name}
                className={`text-2xl active-btn flex mb-2 ${
                  e.key === type && 'active-btn-active'
                }`}
                onClick={() => {
                  setType(e.key);
                }}
              >
                <div className="w-[14rem] flex items-center justify-center">
                  <div className="text-4xl mr-2 py-3">{e.icon}</div>
                  <span>{e.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full my-2 min-h-[60rem] p-5 rounded-md bg-bg-dark-medium relative">
        {loading ? (
          <Loading absolute={true} />
        ) : (
          <div
            className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 items-center 
      place-content-center gap-y-10 mt-8"
          >
            {copyListDataClone.map((e) => {
              return (
                <MasterItemFull
                  canCopy={
                    e.address !== '0xaf596EBEAF7b06571B39A5F88674B65832eaa6b8'
                  }
                  onlyShow={false}
                  popup={popupAddress === e.address}
                  key={e.address}
                  profit={e.profit}
                  leaderName={e.leaderName}
                  level={e.level.toString()}
                  property={'+'}
                  description={e.description}
                  depositFee={e.depositFee}
                  depositPercentageFee={e.depositPercentageFee}
                  tvl={(e.tvl * bnbPrice).toFixed(0)}
                  avatar={e.avatar}
                  data={e}
                  refreshData={refreshSingleCopyListData}
                  hidePopup={() => setPopupAddress('')}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default MasterList;
