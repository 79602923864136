export const ENV = {
  97: {
    CHAIN_NAME : "Binance Testnet",
    BLOCK_PAGINATION : 1000,
    WETH_CONTRACT : "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    COPYCAT_TOKEN : "0xEdf965764104E92Fd2590F7d0f459Ef22DB40DE4",
    DUMMY_LEADER_TOKEN_CONTRACT : "0xc2669b74E0c42ADDCCD4520b09eCf4579DbF7D82",
    LEADER_STORAGE_CONTRACT : "0x588766Dd84F5725BB28FEE85e00083D69E975D17",
    LEADER_CONTRACT : "0xF6afB8806174a60986ba7fF0b1e2d201820f5fb5",
    GAME_LEADER_CONTRACT : "0x978916C8BAEA4BB88aAa112109C4CbCe95fe078C",
    GAME_LEADER_MIRROR_CONTRACT : "0x1456CdC21f2f4004457Ca1779dCD860F3C748ED5",
    GAME_STAKER_CONTRACT : "0x635d941d1a0a3842B8be767e6EF04772098D8954",
    LEADER_FACTORY_CONTRACT : "0x48EeB8b42C674473d84c84B6Bbcd49Ab359335d1",
    BRIDGE_PLUGIN_CONTRACT : "0x3689753C29bF2538224C00545d2f7f332adb87BE",
    BRIDGE_AXELAR_CONTRACT : "0x24627d4ff8602CaB1F026c76fe2051143730DA9A",
    MASTERCHEF_ADAPTER_CONTRACT : "0x3657e38321c0dE93568Ee611a0ea3D8b7BE67F35",
    MASTERCHEF_STAKING_ADAPTER_CONTRACT : "0x3D01c3f219d8b4F5366C9571041E8AeeEEa79C7b",
    SMARTCHEF_ADAPTER_CONTRACT : "0xF31CEd94895A6F620F13e3CbeA0c6B79b11B8827",
    MASTERCHEF_ADAPTER_FACTORY_CONTRACT : "0x070c937929d8EcFa77874AbbC646FA352b807e81",
    RWMANAGER_AUTOCOMPOUND_CONTRACT : "0x878BBc84EeC47df06FF19e4562A30A026b0919BA",
    LEADER_READER_CONTRACT : "0x498e245e031290793396B18e924F4C7f59D6DC52",
    DEPOSITER_CONTRACT : "0xCcF81dA62821F82F77f9261f1a4057f204a99b22",
    MULTICALL_ADDRESS : "0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042",
    RPC_URL : "https://data-seed-prebsc-2-s1.binance.org:8545",
    API_SERVER : "https://api-xcopycat.copycat.finance/2/chain/97",
    TOKEN_SYMBOL : "BNBUSDT",
  },
  43113: {
    CHAIN_NAME : "Avalanche Fuji Testnet",
    BLOCK_PAGINATION : 1000,
    WETH_CONTRACT : "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
    COPYCAT_TOKEN : "0x2e167e257071025BCBdC74EeaAF69889076803F7",
    DUMMY_LEADER_TOKEN_CONTRACT : "0x7D6cDB23CDA6ac0D97ad0F8776Fef87E492e0521",
    LEADER_STORAGE_CONTRACT : "0x9Af8aE5575c7C7092BD656F0d5458FF77Cac7410",
    LEADER_CONTRACT : "0x9D7F3F4CB567beF27855F818D6Fef6C7a8ae4c6F",
    GAME_LEADER_CONTRACT : "0xEA5DBEFDC8FA50274c18748B6acbED9eaD894d12",
    GAME_LEADER_MIRROR_CONTRACT : "0x8Fa339B17ff4C71C6d84B6368001897191e504e7",
    GAME_STAKER_CONTRACT : "0xb712B15c4fB74712B4d540245daC22E1c5DDe5f2",
    LEADER_FACTORY_CONTRACT : "0x479e4c358AfA3B7f0FcC163A0bC1C4346E8af757",
    BRIDGE_PLUGIN_CONTRACT : "0x39066865fc4Eb87FC6FDc5d867498d4DC1104e9d",
    BRIDGE_AXELAR_CONTRACT : "0x2973250Ef722a20B39Ea2341fa9bb47605159061",
    MASTERCHEF_ADAPTER_CONTRACT : "0xDe44Fb57E001BFD3D0125dC3822Ca5Ad733B0BD8",
    MASTERCHEF_STAKING_ADAPTER_CONTRACT : "0x005CAa0D721961357075FC34e06Aa6E29f83949a",
    SMARTCHEF_ADAPTER_CONTRACT : "0x43D3586A9Fa702F88a2c616adcA9B11A00e69118",
    MASTERCHEF_ADAPTER_FACTORY_CONTRACT : "0x6D504060C3D78a88472957aD0563197e2275FC77",
    RWMANAGER_AUTOCOMPOUND_CONTRACT : "0x301D47e800cb7A5F6a4dE78909BC3131fE30C42f",
    LEADER_READER_CONTRACT : "0x60e382aFCf239AaE959d72BcA020CBdda6E815e1",
    DEPOSITER_CONTRACT : "0x4607d4eb3e993E2C8F7DF58681cD356e647DaE06",
    MULTICALL_ADDRESS : "0x3D015943d2780fE97FE3f69C97edA2CCC094f78c",
    RPC_URL : "https://api.avax-test.network/ext/bc/C/rpc",
    API_SERVER : "https://api-xcopycat.copycat.finance/2/chain/43113",
    TOKEN_SYMBOL : "AVAXUSDT",
  },
  80001: {
    CHAIN_NAME : "Polygon Mumbai Testnet",
    BLOCK_PAGINATION : 1000,
    WETH_CONTRACT : "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    COPYCAT_TOKEN : "0xb9701b99AC4bC9FDB38604048A18d2E32A52Af69",
    DUMMY_LEADER_TOKEN_CONTRACT : "0xa4AFaEB4b7B41B16696F06259C91EA6726C99E75",
    LEADER_STORAGE_CONTRACT : "0x348d96e43e08AFb998D7b3934e3d1110be27F3fE",
    LEADER_CONTRACT : "0x0165F6Ce6E2A17936376F21e6c2ca2Ae46108FA0",
    GAME_LEADER_CONTRACT : "0xF4307B91aFFcA54ea160B394f27c9E7c6beE55Ae",
    GAME_LEADER_MIRROR_CONTRACT : "0xd16Ba886292f20c29d5c31F9756cf40827da40a0",
    GAME_STAKER_CONTRACT : "0x7b466c840552A4B0Fc4b63C089356877f56aFC0d",
    LEADER_FACTORY_CONTRACT : "0x184588Fe4BFDCdBC0FDbb0769d68484c27844F37",
    BRIDGE_PLUGIN_CONTRACT : "0x69566f897c9BE84F866226C0ed633A2E69Ec1065",
    BRIDGE_AXELAR_CONTRACT : "0x1F8FDB5A2EA4f7A5a87DdB682de5690E0859A035",
    MASTERCHEF_ADAPTER_CONTRACT : "0x1D205f2511ea24e2949011a92cd1E2F9592bFA0C",
    MASTERCHEF_STAKING_ADAPTER_CONTRACT : "0xeF4bb1B08a172A47c90426e8dEE4E2ba9ACA33AC",
    SMARTCHEF_ADAPTER_CONTRACT : "0xFB5D7B5E92E80b3E2a088b0739b5c9B292b80a3B",
    MASTERCHEF_ADAPTER_FACTORY_CONTRACT : "0x422170e7e3b7032266a109042Eb54Ba226e671C4",
    RWMANAGER_AUTOCOMPOUND_CONTRACT : "0xf66C83a58B5102d8E658c4a04e538383B7258633",
    LEADER_READER_CONTRACT : "0xE6351cEe01F2Fe55841Ed89bE57174C8d3e822bd",
    DEPOSITER_CONTRACT : "0x5aFA4E670291362B7d1A103Fd1BE64487A3568c6",
    MULTICALL_ADDRESS : "0xe9939e7Ea7D7fb619Ac57f648Da7B1D425832631",
    RPC_URL : "https://matic-mumbai.chainstacklabs.com",
    API_SERVER : "https://api-xcopycat.copycat.finance/2/chain/80001",
    TOKEN_SYMBOL : "MATICUSDT",
  },
}