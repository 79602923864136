import { useMemo } from 'react';

import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { Contract } from '@ethersproject/contracts';
import { getMulticallAddress } from 'utils/addressHelper';
import multiCallAbi from '../config/abi/Multicall.json';
import { getContract } from 'utils';

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
): Contract | null {
  const { library, account } = useActiveWeb3React();
  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useMulticallContract(): Contract | null {
  return useContract(getMulticallAddress(), multiCallAbi, false);
}
