import Web3 from 'web3';
import web3 from 'hooks/web3';
import CopycatLeaderReaderABI from './abi/CopycatLeaderReader.json';
import { getEnv } from 'utils/env';

export default class CopycatLeaderReader {
  contract: any;
  from: any;

  constructor(from, address?) {
    const COPYCAT_LEADER_READER_ADDRESS = getEnv("LEADER_READER_CONTRACT");

    address = address || COPYCAT_LEADER_READER_ADDRESS;

    this.contract = new web3.eth.Contract(
      CopycatLeaderReaderABI as any,
      address
    );
    this.from = from;
  }

  async getShareRatioSingle(leader, token, balance) {
    return await this.contract.methods
      .getShareRatioSingle(leader, token, balance)
      .call();
  }

  async getShareRatio(leader) {
    return await this.contract.methods.getShareRatio(leader).call();
  }

  async getLeaderData(leader, holder) {
    return await this.contract.methods.getLeaderData(leader, holder).call();
  }

  async getTokenData(leader, token) {
    return await this.contract.methods.getTokenData(leader, token).call();
  }

  async getLeaderTokens(leader) {
    return await this.contract.methods.getLeaderTokens(leader).call();
  }

  async getCopygameBaseToken(leader) {
    return await this.contract.methods.getCopygameBaseToken(leader).call();
  }

  async getMultipleCopygameBaseToken(leaders) {
    return await this.contract.methods.getMultipleCopygameBaseToken(leaders).call();
  }

  async getHarvestableTokens(leader) {
    return await this.contract.methods.getHarvestableTokens(leader).call({ from: this.from });
  }
}
