import React, { useEffect, useState } from 'react';
import bglanding from 'assets/images/bg-landing.jpeg';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'utils/api';
import {
  LeaderViewExtended,
  loadLeaders,
  loadLeaderTokens,
} from 'contracts/CopycatLeader';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import web3 from 'hooks/web3';
import { fromWei } from 'utils/unitConversion';
import usePopup from 'hooks/usePopup';
import Loading from 'components/Loading';
import { AiFillStar, AiOutlineUser } from 'react-icons/ai';
import { GiLaurelsTrophy } from 'react-icons/gi';
import MasterItemFull from 'components/MasterItemFull';
import { useBnbPrice } from 'hooks/useBnbPrice';
import { formatCopyListData } from 'views/MasterList/copyListData';
import TokenAll from 'components/MasterItemFull/Details/TokenAll';
import MasterChart from 'components/MasterItemFull/Details/MasterChart';
import { FaHeart } from 'react-icons/fa';
import { addBookmark, isBookmarked, removeBookmark } from 'utils/bookmark';
import MasterDistribute from 'components/MasterItemFull/Details/MasterDistribute';
import MasterStatus from 'components/MasterItemFull/Details/MasterStatus';

const ranking = {
  0: {
    name: 'Amateur',
    icon: <AiOutlineUser />,
    level: 0,
    fee: 200,
  },
  1: {
    name: 'Professional',
    icon: <GiLaurelsTrophy />,
    level: 1,
    fee: 2000,
  },
  2: {
    name: 'Star',
    icon: <AiFillStar />,
    level: 2,
    fee: 100000,
  },
};

function MasterById() {
  const { leaderAddress } = useParams();
  const navigate = useNavigate();
  const popupToast = usePopup();

  const [leaderData, setLeaderData] = useState<LeaderViewExtended | null>(null);
  const [popup, setPopup] = useState(false);
  const { account } = useActiveWeb3React();
  const [loading, setLoading] = useState(true);
  const bnbPrice = useBnbPrice();
  const [bookmarked, setBookmarked] = useState(isBookmarked(leaderAddress));

  const [chartData, setChartData] = useState([]);

  const handleClickBookmark = () => {
    if (bookmarked) {
      removeBookmark(leaderData.address);
    } else {
      addBookmark(leaderData.address);
    }
    setBookmarked(!bookmarked);
  };

  async function refreshData() {
    let hasAccount = !!account;
    let rawCopyListData = (await loadLeaders([leaderAddress], account))[0];
    // console.log(rawCopyListData);
    // let tokenList = await loadUniswapV2AdapterTokens(await loadLeaderAdapterAddresses(leaderAddress));
    let tokenList = await loadLeaderTokens(leaderAddress);
    let bnbBalance = fromWei(await web3.eth.getBalance(leaderAddress));

    let apiData;
    try {
      apiData = (await api.get('/cache/leaders/' + leaderAddress)).data;
    } catch (err) {
      popupToast.error({
        text: 'Can not find Master Data',
        title: 'Master Details',
      });
      navigate('/master');
    }

    let histories = [];
    if (apiData) {
      histories = apiData.histories;
    }

    // Prevent racing condition
    if (!hasAccount && account) return;

    setLeaderData({
      ...rawCopyListData,
      tokens: tokenList,
      bnbBalance: bnbBalance,
      histories,
      apiData: apiData
        ? formatCopyListData(rawCopyListData, apiData || {})
        : null,
    });
    setChartData(
      histories?.map((c) => ({
        date: new Date(c.u).getTime(),
        val: c.s * c.b,
      })) || []
    );
  }

  useEffect(() => {
    const call = async () => {
      setLoading(true);
      await refreshData();
      setLoading(false);
    };
    call();
  }, [account]);

  return (
    <div className="w-full relative">
      {loading ? (
        <Loading absolute={true} />
      ) : (
        <div className="w-full">
          <div
            className={`w-full flex flex-col items-center justify-center bg-bg-dark-medium space-y-8 h-[15rem] mt-5 relative overflow-hidden bg-cover bg-center`}
            style={{ backgroundImage: `url(${bglanding})` }}
          >
            <h1 className="text-5xl relative text-primary1">
              {leaderData.tokenName}
            </h1>
            <h2 className="text-3xl relative flex items-center">
              {ranking[(leaderData?.tier / 10).toString()].icon}
              <span className="ml-4">
                {ranking[(leaderData?.tier / 10).toString()].name} Rank{' '}
              </span>
            </h2>
            <div className="w-[90%] text-center text-lg relative">
              {leaderData.description}
            </div>
          </div>
          <div className="relative w-full grid grid-cols-3 md:px-[4%] px-10 my-20 ">
            <div className="w-full col-span-2 p-4 flex flex-col space-y-2">
              <h1 className="text-3xl">{leaderData.tokenName}</h1>
              <MasterStatus leaderData={leaderData} />
              <MasterChart data={chartData} />
            </div>
            <div className="w-full col-span-1 relative items-center space-y-10 px-4">
              <div className="flex flex-col w-full bg-bg-dark-medium rounded-md p-4 space-y-3">
                <MasterItemFull
                  canCopy={
                    leaderData.address !==
                    '0xaf596EBEAF7b06571B39A5F88674B65832eaa6b8'
                  }
                  popup={popup}
                  isdetail={true}
                  data={leaderData.apiData}
                  onlyShow={false}
                  leaderName={leaderData.tokenName}
                  property={'+'}
                  description={leaderData.description}
                  depositFee={leaderData.depositCopycatFee.toString()}
                  depositPercentageFee={leaderData.depositPercentageFee.toString()}
                  tvl={
                    leaderData ? (leaderData.tvl * bnbPrice).toFixed(0) : '0%'
                  }
                  avatar={leaderData.avatar}
                  refreshData={refreshData}
                  hidePopup={() => setPopup(false)}
                />
                {leaderData.owner === account && (
                  <MasterDistribute
                    data={leaderData.apiData}
                    tokens={leaderData.tokens}
                    baseTokenAddress={
                      leaderData.copygameBaseTokenAddress
                    }
                    baseTokenSymbol={leaderData.copygameBaseTokenSymbol}
                  />
                )}
                <TokenAll
                  tokens={leaderData.tokens}
                  baseTokenAddress={leaderData.copygameBaseTokenAddress}
                />

                <div
                  className={`w-full flex items-center py-3 cursor-pointer rounded-lg justify-center text-3xl text-main transition-all hover:opacity-80 ${
                    bookmarked
                      ? 'bg-bg-dark-light border-[1px] border-white'
                      : 'bg-primary1 border-[1px] border-primary1'
                  }`}
                  onClick={handleClickBookmark}
                >
                  <FaHeart className={`text-3xl cursor-pointer hearth mr-4`} />
                  Bookmark
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MasterById;
