import styled from 'styled-components';

export const SelectBoxWrapper = styled.div`
  width: 100%;
  box-sizing: content-box;
  font-size: 1.8rem;
  transition: all 0.5s;
  cursor: pointer;
  input::placeholder {
    color: red;
  }
  .ant-select {
    width: 100%;
    box-sizing: content-box;
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 1rem;
    height: 5rem;
    border: 0.1rem solid #ffffff1e;
    border-right-width: 0.1rem !important;
    background-color: #011e30;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: normal;
    margin: auto 0;
  }
  color: #011e30;
  .ant-select .ant-select-selection-placeholder {
    font-size: 1.4rem;
  }
  .ant-select .ant-select-focused,
  .ant-select .ant-select-single,
  .ant-select .ant-select-show-arrow,
  .ant-select .ant-select-open {
    border: 0.1rem solid #ffffff1e;
  }
  .ant-select-item-option-active .ant-select-item-option-content {
    font-size: 1.8rem;
    color: #011e30;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #ffffff1e;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ffffff1e;
    border-right-width: 0.1rem !important;
  }
  .ant-select:not(.ant-select-disabled):focus .ant-select-selector {
    border-color: #ffffff1e;
    border-right-width: 0.1rem !important;
  }
  .ant-select .ant-select-single .ant-select-show-arrow {
  }
  .ant-select-.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:not(:hover) {
    border-right-width: 0.1rem !important;
  }
`;
