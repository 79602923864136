import { ENV } from "config/envData";
import { updateApiAxiosObject } from "./api";
import { fetchBnbPrice } from "./bnbPrice";

export let ACTIVE_CHAIN_ID = window.localStorage.getItem('ACTIVE_CHAIN_ID') || process.env.REACT_APP_CHAIN_ID;

export function getEnv(name: string, chainId?: string): string {
  if (!chainId) {
    try {
      chainId = ACTIVE_CHAIN_ID || window.localStorage.getItem('ACTIVE_CHAIN_ID') || process.env.REACT_APP_CHAIN_ID;
    } catch (err) {
      chainId = window.localStorage.getItem('ACTIVE_CHAIN_ID') || process.env.REACT_APP_CHAIN_ID;
    }
  }

  // console.log('REACT_APP_' + chainId + '_' + name, process.env['REACT_APP_' + chainId + '_' + name])

  console.log('CHAINID', chainId);

  // return process.env['REACT_APP_' + chainId + '_' + name] || '';
  return ENV[chainId][name];
}

export function setActiveChainId(chainId: string) {
  ACTIVE_CHAIN_ID = chainId;
  window.localStorage.setItem("ACTIVE_CHAIN_ID", chainId);
  updateApiAxiosObject();
  fetchBnbPrice();
}
