// IMPORTANT: Check lower upper case of address from bscscan before

export interface TokenView {
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  logo: string;
  swapAddress: string;
  pairId?: number;
  adapterId?: number;
  adapterType?: string;
  adapterAddress?: string;
  balance?: number;
  bnbValue?: number;
  decimals?: number;
  tradingRouteBuy?: string[];
  tradingRouteSell?: string[];
}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key].toLowerCase()]: item,
    };
  }, initialValue);
};

export const swapAddress2Name = {
  '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3': 'Kiemtien360',
  '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7': 'ApeSwap',
  '0x10ED43C718714eb63d5aA57B78B54704E256024E': 'PancakeSwap V2',
  '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F': 'PancakeSwap V1',
  '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8': 'Biswap',
  '0xcde540d7eafe93ac5fe6233bee57e1270d3e330f': 'Bakery Swap',
};

export const allowedSwapPairs: TokenView[] = [
  {
    "tokenAddress": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "tokenName": "Binance Coin",
    "tokenSymbol": "BNB",
    "logo": "/pair/binance2.svg",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 48,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xd635b32688f36ee4a7fe117b4c91dd811277acb6",
    "tokenName": "Copycat Token",
    "tokenSymbol": "COPYCAT",
    "logo": "/icon/cat_face.svg",
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 1,
    "decimals": 18,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "tokenName": "BUSD Token",
    "tokenSymbol": "BUSD",
    "logo": "/tokens/56/0xe9e7cea3dedca5984780bafc599bd69add087d56.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 2,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x55d398326f99059ff775485246999027b3197955",
    "tokenName": "Tether USD",
    "tokenSymbol": "USDT",
    "logo": "/tokens/56/0x55d398326f99059ff775485246999027b3197955.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 3,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    "tokenName": "USD Coin",
    "tokenSymbol": "USDC",
    "logo": "/tokens/56/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 4,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
    "tokenName": "Mobox",
    "tokenSymbol": "MBOX",
    "logo": "/tokens/56/0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 5,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    "tokenName": "BTCB Token",
    "tokenSymbol": "BTCB",
    "logo": "/tokens/56/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 6,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "tokenName": "Ethereum Token",
    "tokenSymbol": "ETH",
    "logo": "/tokens/56/0x2170ed0880ac9a755fd29b2688956bd959f933f8.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 7,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    "tokenName": "PancakeSwap Token",
    "tokenSymbol": "Cake",
    "logo": "/tokens/56/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 8,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
    "tokenName": "Polkadot Token",
    "tokenSymbol": "DOT",
    "logo": "/tokens/56/0x7083609fce4d1d8dc0c979aab8c869ea2c873402.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 9,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x8f0528ce5ef7b51152a59745befdd91d97091d2f",
    "tokenName": "AlpacaToken",
    "tokenSymbol": "ALPACA",
    "logo": "/tokens/56/0x8f0528ce5ef7b51152a59745befdd91d97091d2f.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 10,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
    "tokenName": "Cardano Token",
    "tokenSymbol": "ADA",
    "logo": "/tokens/56/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 11,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
    "tokenName": "ChainLink Token",
    "tokenSymbol": "LINK",
    "logo": "/tokens/56/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 12,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
    "tokenName": "Uniswap",
    "tokenSymbol": "UNI",
    "logo": "/tokens/56/0xbf5140a22578168fd562dccf235e5d43a02ce9b1.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 13,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x8595f9da7b868b1822194faed312235e43007b49",
    "tokenName": "BitTorrent",
    "tokenSymbol": "BTT",
    "logo": "/tokens/56/0x8595f9da7b868b1822194faed312235e43007b49.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 14,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    "tokenName": "Venus",
    "tokenSymbol": "XVS",
    "logo": "/tokens/56/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 15,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
    "tokenName": "XRP Token",
    "tokenSymbol": "XRP",
    "logo": "/tokens/56/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 16,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
    "tokenName": "TRON",
    "tokenSymbol": "TRX",
    "logo": "/tokens/56/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 17,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    "tokenName": "Coin98",
    "tokenSymbol": "C98",
    "logo": "/tokens/56/0xaec945e04baf28b135fa7c640f624f8d90f1c3a6.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 18,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x715d400f88c167884bbcc41c5fea407ed4d2f8a0",
    "tokenName": "Axie Infinity Shard",
    "tokenSymbol": "AXS",
    "logo": "/tokens/56/0x715d400f88c167884bbcc41c5fea407ed4d2f8a0.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 19,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
    "tokenName": "Dogecoin",
    "tokenSymbol": "DOGE",
    "logo": "/tokens/56/0xba2ae424d960c26247dd6c32edc70b295c744c43.png",
    "decimals": 8,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 20,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
    "tokenName": "AlphaToken",
    "tokenSymbol": "ALPHA",
    "logo": "/tokens/56/0xa1faa113cbe53436df28ff0aee54275c13b40975.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 21,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x947950bcc74888a40ffa2593c5798f11fc9124c4",
    "tokenName": "SushiToken",
    "tokenSymbol": "SUSHI",
    "logo": "/tokens/56/0x947950bcc74888a40ffa2593c5798f11fc9124c4.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 22,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x9f589e3eabe42ebc94a44727b3f3531c0c877809",
    "tokenName": "Tokocrypto Token",
    "tokenSymbol": "TKO",
    "logo": "/tokens/56/0x9f589e3eabe42ebc94a44727b3f3531c0c877809.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 23,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xac51066d7bec65dc4589368da368b212745d63e8",
    "tokenName": "ALICE",
    "tokenSymbol": "ALICE",
    "logo": "/tokens/56/0xac51066d7bec65dc4589368da368b212745d63e8.png",
    "decimals": 6,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 24,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x2222227e22102fe3322098e4cbfe18cfebd57c95",
    "tokenName": "Alien Worlds Trilium",
    "tokenSymbol": "TLM",
    "logo": "/tokens/56/0x2222227e22102fe3322098e4cbfe18cfebd57c95.png",
    "decimals": 4,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 25,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2",
    "tokenName": "DODO bird",
    "tokenSymbol": "DODO",
    "logo": "/tokens/56/0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 26,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51",
    "tokenName": "Bunny Token",
    "tokenSymbol": "BUNNY",
    "logo": "/tokens/56/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 27,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
    "tokenName": "Litecoin Token",
    "tokenSymbol": "LTC",
    "logo": "/tokens/56/0x4338665cbb7b2485a8855a139b75d5e34ab0db94.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 28,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x4b0f1812e5df2a09796481ff14017e6005508003",
    "tokenName": "Trust Wallet",
    "tokenSymbol": "TWT",
    "logo": "/tokens/56/0x4b0f1812e5df2a09796481ff14017e6005508003.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 29,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x8519ea49c997f50ceffa444d240fb655e89248aa",
    "tokenName": "RAMP DEFI",
    "tokenSymbol": "RAMP",
    "logo": "/tokens/56/0x8519ea49c997f50ceffa444d240fb655e89248aa.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 30,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18",
    "tokenName": "Band Protocol Token",
    "tokenSymbol": "BAND",
    "logo": "/tokens/56/0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 31,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xa7f552078dcc247c2684336020c03648500c6d9f",
    "tokenName": "Ellipsis",
    "tokenSymbol": "EPS",
    "logo": "/tokens/56/0xa7f552078dcc247c2684336020c03648500c6d9f.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 32,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xeca41281c24451168a37211f0bc2b8645af45092",
    "tokenName": "TokenPocket Token",
    "tokenSymbol": "TPT",
    "logo": "/tokens/56/0xeca41281c24451168a37211f0bc2b8645af45092.png",
    "decimals": 4,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 33,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x9d173e6c594f479b4d47001f8e6a95a7adda42bc",
    "tokenName": "CryptoZoon",
    "tokenSymbol": "ZOON",
    "logo": "/tokens/56/0x9d173e6c594f479b4d47001f8e6a95a7adda42bc.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 34,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x339c72829ab7dd45c3c52f965e7abe358dd8761e",
    "tokenName": "Wanaka Farm",
    "tokenSymbol": "WANA",
    "logo": "/tokens/56/0x339c72829ab7dd45c3c52f965e7abe358dd8761e.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 35,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb",
    "tokenName": "SafePal Token",
    "tokenSymbol": "SFP",
    "logo": "/tokens/56/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb.png",
    "decimals": 18,
    "swapAddress": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "pairId": 36,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xa184088a740c695e156f91f5cc086a06bb78b827",
    "tokenName": "AUTOv2",
    "tokenSymbol": "AUTO",
    "logo": "/tokens/56/0xa184088a740c695e156f91f5cc086a06bb78b827.png",
    "decimals": 18,
    "swapAddress": "0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F",
    "pairId": 37,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95",
    "tokenName": "ApeSwapFinance Banana",
    "tokenSymbol": "BANANA",
    "logo": "/tokens/56/0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 38,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x52ce071bd9b1c4b00a0b92d298c512478cad67e8",
    "tokenName": "Compound Coin",
    "tokenSymbol": "COMP",
    "logo": "/tokens/56/0x52ce071bd9b1c4b00a0b92d298c512478cad67e8.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 39,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x545f90dc35ca1e6129f1fed354b3e2df12034261",
    "tokenName": "NEWB",
    "tokenSymbol": "NEWB",
    "logo": "/tokens/56/0x545f90dc35ca1e6129f1fed354b3e2df12034261.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 40,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
    "tokenName": "Matic Token",
    "tokenSymbol": "MATIC",
    "logo": "/tokens/56/0xcc42724c6683b7e57334c4e856f4c9965ed682bd.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 41,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x3d6545b08693dae087e957cb1180ee38b9e3c25e",
    "tokenName": "Ethereum Classic",
    "tokenSymbol": "ETC",
    "logo": "/tokens/56/0x3d6545b08693dae087e957cb1180ee38b9e3c25e.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 42,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xfb6115445bff7b52feb98650c87f44907e58f802",
    "tokenName": "Aave Token",
    "tokenSymbol": "AAVE",
    "logo": "/tokens/56/0xfb6115445bff7b52feb98650c87f44907e58f802.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 43,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xad29abb318791d579433d831ed122afeaf29dcfe",
    "tokenName": "Fantom",
    "tokenSymbol": "FTM",
    "logo": "/tokens/56/0xad29abb318791d579433d831ed122afeaf29dcfe.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 44,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab",
    "tokenName": "CryptoBlades Skill Token",
    "tokenSymbol": "SKILL",
    "logo": "/tokens/56/0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 45,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x1fa4a73a3f0133f0025378af00236f3abdee5d63",
    "tokenName": "NEAR Protocol",
    "tokenSymbol": "NEAR",
    "logo": "/tokens/56/0x1fa4a73a3f0133f0025378af00236f3abdee5d63.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 46,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0xd9025e25bb6cf39f8c926a704039d2dd51088063",
    "tokenName": "Coinary Token",
    "tokenSymbol": "CYT",
    "logo": "/tokens/56/0xd9025e25bb6cf39f8c926a704039d2dd51088063.png",
    "decimals": 18,
    "swapAddress": "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    "pairId": 47,
    "adapterType": "uniswapv2"
  },
  {
    "tokenAddress": "0x965f527d9159dce6288a2219db51fc6eef120dd1",
    "tokenName": "Biswap",
    "tokenSymbol": "BSW",
    "logo": "/tokens/56/0x965f527d9159dce6288a2219db51fc6eef120dd1.png",
    "decimals": 18,
    "swapAddress": "0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8",
    "pairId": 48,
    "adapterType": "uniswapv2"
  }
]



export const allowedSwapPairsMap: { [k: string]: TokenView } = convertArrayToObject(allowedSwapPairs, 'tokenAddress');

export const allowedTokensMap: { [k: string]: TokenView } = convertArrayToObject([...allowedSwapPairs], 'tokenAddress');
