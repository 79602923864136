import CopycatToken from 'contracts/CopycatToken';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import web3 from 'hooks/web3';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fromWei, toWei } from 'utils/unitConversion';
import imgBanner from 'assets/images/master_bg.png';
import InputStyled from 'components/InputStyled';
import { AiFillStar, AiOutlineUser } from 'react-icons/ai';
import { GiLaurelsTrophy } from 'react-icons/gi';
import TokenImg from 'components/TokenImg';
import AvatarSelect from './AvatarSelect';
import CheckboxStyled from 'components/CheckboxStyled';
import ButtonStyled from 'components/ButtonStyled';
import localLoadingFn from 'utils/localLoadingFn';
import CopycatLeaderFactory from 'contracts/CopycatLeaderFactory';
import usePopup from 'hooks/usePopup';
import useConfirm from 'hooks/useConfirm';
import { getEnv } from 'utils/env';

const ranking = [
  {
    name: 'Amateur',
    icon: <AiOutlineUser />,
    level: 0,
    fee: 200,
  },
  {
    name: 'Professional',
    icon: <GiLaurelsTrophy />,
    level: 1,
    fee: 2000,
  },
  {
    name: 'Star',
    icon: <AiFillStar />,
    level: 2,
    fee: 100000,
  },
];

const copygameTokens = {
  56: [
    {
      tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      symbol: 'BNB',
    },
    {
      tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      symbol: 'BUSD',
    },
    {
      tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
      symbol: 'USDT',
    },
    {
      tokenAddress: '0xd635B32688F36ee4a7FE117b4C91DD811277ACB6',
      symbol: 'COPYCAT',
    },
  ],
  3: [
    {
      tokenAddress: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      symbol: 'ETH',
    },
    {
      tokenAddress: '0x653044Df3e853e8FF96c8D9a7Ab7A90E34c4d484',
      symbol: 'axlWBNB',
    },
    {
      tokenAddress: '0x72af7e1e7E0D38bCF033C541598F5a0301D051A5',
      symbol: 'axlWAVAX',
    },
    {
      tokenAddress: '0xEAE61FD42A56F435a913d1570fF301a532d027b2',
      symbol: 'axlWMATIC',
    },
  ],
  97: [
    {
      tokenAddress: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      symbol: 'BNB',
    },
    {
      tokenAddress: '0x03Dc012b7851b7D65592Aebc40a6aF9A171E9315',
      symbol: 'axlWETH',
    },
    {
      tokenAddress: '0x1B29EC62efC689c462b4E0512457175793cEc9e6',
      symbol: 'axlWAVAX',
    },
    {
      tokenAddress: '0x920fA0DbB65cE928C29103AeC7B5c188bbea2f24',
      symbol: 'axlWMATIC',
    },
  ],
  43113: [
    {
      tokenAddress: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
      symbol: 'AVAX',
    },
    {
      tokenAddress: '0x3613C187b3eF813619A25322595bA5E297E4C08a',
      symbol: 'axlWETH',
    },
    {
      tokenAddress: '0xd020f566723e8402f925A891605c02ce7AF2477F',
      symbol: 'axlWBNB',
    },
    {
      tokenAddress: '0xB923E2374639D0605388D91CFedAfCeCE03Cfd8f',
      symbol: 'axlWMATIC',
    },
  ],
  80001: [
    {
      tokenAddress: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
      symbol: 'MATIC',
    },
    {
      tokenAddress: '0xfba15fFF35558fE2A469B96A90AeD7727FE38fAE',
      symbol: 'axlWETH',
    },
    {
      tokenAddress: '0x55fDE07dEF3261a41fC59B783D27A6357e8A86Df',
      symbol: 'axlWBNB',
    },
    {
      tokenAddress: '0x6DD60c05FdA1255A44Ffaa9A8200b5b179A578D6',
      symbol: 'axlWAVAX',
    },
  ],
};

function ApplyMaster({ copygame = false }) {
  const navigate = useNavigate();
  const { account, chainId } = useActiveWeb3React();
  const [depositCPCFee, setDepositCPCFee] = useState('0');
  const [depositPercentageFee, setDepositPercentageFee] = useState('0');
  const [level, setLevel] = useState(0);
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(
    'https://copycat.finance/avatar/blackCat.svg'
  );
  const [avatarKey, setAvatarKey] = useState('blackCat');
  const [approved, setApproved] = useState(false);
  const [baseTokenApproved, setBaseTokenApproved] = useState(false);
  const [approving, setApproving] = useState(false);
  const [applying, setApplying] = useState(false);
  const [acceptTos, setAcceptTos] = useState(false);

  const [bnbBalance, setBnbBalance] = useState(0);
  const [copycatBalance, setCopycatBalance] = useState(0);

  const [registrationFee, setRegistrationFee] = useState(200);

  const [baseToken, setBaseToken] = useState(copygameTokens[chainId][0].symbol);
  const [maximumDeposit, setMaximumDeposit] = useState(0);

  const popup = usePopup();
  const confirm = useConfirm();

  async function loadApproval() {
    if (account) {
      setApproved(
        await new CopycatToken(account).checkAllowance(
          account,
          getEnv("LEADER_STORAGE_CONTRACT"),
          toWei(Math.max(registrationFee, 1000))
        )
      );

      setCopycatBalance(
        fromWei(
          await new CopycatToken(
            account,
            getEnv("COPYCAT_TOKEN")
          ).balanceOf(account)
        )
      );
      setBnbBalance(fromWei(await web3.eth.getBalance(account)));
    }
  }

  useEffect(() => {
    loadApproval();
  }, [account]);

  async function loadCopygameApproval() {
    if (account && copygame) {
      const baseTokenAddress = copygameTokens[chainId].find(
        (c) => c.symbol == baseToken
      ).tokenAddress;

      if (baseTokenAddress === getEnv("WETH_CONTRACT")) {
        setBaseTokenApproved(true);
      } else {
        setBaseTokenApproved(
          await new CopycatToken(account, baseTokenAddress).checkAllowance(
            account,
            getEnv("LEADER_FACTORY_CONTRACT"),
            toWei(0.0001)
          )
        );
      }
    }
  }

  useEffect(() => {
    loadCopygameApproval();
  }, [account, baseToken, chainId]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col items-center justify-center space-y-8 h-[20rem] mt-5 relative overflow-hidden">
        <img
          src={imgBanner}
          alt="applymaster-img"
          className="w-full h-full object-cover absolute left-0 bottom-[-10%]"
        />
        <h1 className="text-5xl relative">Apply Master</h1>
        <h2 className="text-3xl relative">Be Leader of CopyGame</h2>
      </div>
      <div className="w-full relative md:px-[20%] px-10 my-20 flex flex-col items-center space-y-10">
        <div className="w-full flex flex-col space-y-8">
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Master Name</b>
            <InputStyled
              value={tokenName}
              placeholder={'Input Master Name'}
              maxlength={`MAX ${tokenName.length} / 20`}
              onChange={(e) => {
                let value = e.target.value.slice(0, 20);

                setTokenName(value);
                setTokenSymbol(
                  'CPC' +
                    value
                      .toUpperCase()
                      .replace(/[^A-Z]/gi, '')
                      .slice(0, 6)
                );
              }}
            />
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Description</b>
            <textarea
              className="custom-input"
              placeholder={'Description'}
              onChange={(e) => setDescription(e.target.value)}
              name="description"
              id="description"
              cols={20}
              rows={5}
            ></textarea>
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Select your Rank of Master</b>
            <div className="w-full flex">
              {ranking.map((e) => {
                return (
                  <div
                    key={e.name}
                    className={`text-2xl active-btn flex ${
                      e.level === level && 'active-btn-active'
                    }`}
                    onClick={() => {
                      setLevel(e.level);
                      setRegistrationFee(e.fee);
                    }}
                  >
                    <div className="w-[10rem] flex flex-col items-center">
                      <div className="text-4xl mb-2">{e.icon}</div>
                      <span>{e.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div className="mt-2 text-xl font-bold">
                <div>
                  <b className="text-secondary1">
                    ** Registration fee: {registrationFee} $COPYCAT
                  </b>
                </div>
                <div>{/* <h3>Initial deposit: 0.3 BNB (Required)</h3> */}</div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Select Depositing Token</b>
            <div className="w-full flex">
              {copygameTokens[chainId].map((e) => {
                return (
                  <div
                    key={e.tokenAddress}
                    className={`text-2xl active-btn flex ${
                      e.symbol === baseToken && 'active-btn-active'
                    }`}
                    onClick={() => {
                      setBaseToken(e.symbol);
                    }}
                  >
                    <div className="w-[10rem] flex flex-col items-center">
                      <div
                        className={`w-20 h-20 flex items-center justify-center mb-2 p-2 rounded-full ${
                          e.symbol === baseToken
                            ? 'bg-bg-dark-dark'
                            : 'bg-bg-dark-light'
                        }`}
                      >
                        <TokenImg name={e.symbol} address={e.tokenAddress} />
                      </div>
                      <p>{e.symbol}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Select Avatar</b>
            <AvatarSelect
              select={avatarKey}
              onChange={(newImageUrl, key) => {
                setImageUrl(newImageUrl);
                setAvatarKey(key);
              }}
            />
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Deposit Fees ($COPYCAT)</b>
            <div className="w-full flex">
              <InputStyled
                value={depositCPCFee === '0' ? '' : depositCPCFee}
                type="number"
                maxlength={`Max 1000 COPYCAT`}
                placeholder={'0'}
                onChange={(e) => {
                  if (isNaN(parseInt(e.target.value))) {
                    setDepositCPCFee('0');
                  }
                  if (parseInt(e.target.value) <= 1000) {
                    setDepositCPCFee(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">Deposit Fees (%)</b>
            <div className="w-full flex">
              <InputStyled
                type="number"
                maxlength={`Max 20%`}
                value={depositPercentageFee === '0' ? '' : depositPercentageFee}
                placeholder={'0'}
                onChange={(e) => {
                  if (isNaN(parseInt(e.target.value))) {
                    setDepositPercentageFee('0');
                  }
                  if (parseInt(e.target.value) <= 20) {
                    setDepositPercentageFee(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="w-full flex flex-col space-y-4">
            <b className="text-3xl">
              Maximum deposit (leave blank for unlimited)
            </b>
            <div className="w-full flex">
              <InputStyled
                type="number"
                maxlength={baseToken}
                value={maximumDeposit === 0 ? '' : maximumDeposit.toString()}
                placeholder={'Unlimited'}
                onChange={(e) => {
                  setMaximumDeposit(parseFloat(e.target.value) || 0);
                }}
              />
            </div>
          </div>
          {/*  */}
          <CheckboxStyled
            onChange={(e) => setAcceptTos(e.target.checked)}
            text="Accept the T&amp;Cs to become a COPYCAT crew"
          />

          {/*  */}
          <div className="grid grid-cols-2 gap-2">
            {account ? (
              <>
                <ButtonStyled
                  className="w-full text-2xl h-full"
                  color="secondary"
                  disabled={
                    (approved && (!copygame || baseTokenApproved)) || approving
                  }
                  onClick={async () => {
                    if (!approved) {
                      await localLoadingFn(
                        () =>
                          new CopycatToken(account).approve(
                            getEnv("LEADER_STORAGE_CONTRACT")
                          ),
                        setApproving
                      );
                    }

                    if (copygame) {
                      const baseTokenAddress = copygameTokens[chainId].find(
                        (c) => c.symbol == baseToken
                      ).tokenAddress;

                      if (
                        baseTokenAddress != getEnv("WETH_CONTRACT")
                      ) {
                        await localLoadingFn(
                          () =>
                            new CopycatToken(account, baseTokenAddress).approve(
                              getEnv("LEADER_FACTORY_CONTRACT")
                            ),
                          setApproving
                        );
                      }

                      setBaseTokenApproved(true);
                    }

                    setApproved(true);
                  }}
                >
                  {approving ? 'PROCESSING...' : 'APPROVE'}
                </ButtonStyled>

                <ButtonStyled
                  className="w-full text-2xl h-full"
                  color="secondary"
                  disabled={
                    !(approved && (!copygame || baseTokenApproved)) ||
                    applying ||
                    (false && bnbBalance < 0.32) ||
                    (false && copycatBalance < registrationFee) ||
                    !tokenName
                  }
                  onClick={async () => {
                    if (!acceptTos) {
                      popup.error({
                        text: 'You must accept the T&Cs to become a COPYCAT crew',
                        title: 'Apply Master',
                      });
                      return;
                    }

                    await localLoadingFn(
                      () =>
                        copygame
                          ? new CopycatLeaderFactory(account).deployLeaderGame(
                              web3.utils.toWei(
                                parseFloat(depositCPCFee).toString()
                              ),
                              web3.utils.toWei(
                                (
                                  parseFloat(depositPercentageFee) / 100
                                ).toString()
                              ),
                              level,
                              tokenName,
                              tokenSymbol,
                              description,
                              imageUrl,
                              copygameTokens[chainId].find((c) => c.symbol == baseToken)
                                .tokenAddress,
                              web3.utils.toWei(maximumDeposit.toString())
                            )
                          : new CopycatLeaderFactory(account).deployLeader(
                              web3.utils.toWei(
                                parseFloat(depositCPCFee).toString()
                              ),
                              web3.utils.toWei(
                                (
                                  parseFloat(depositPercentageFee) / 100
                                ).toString()
                              ),
                              level,
                              tokenName,
                              tokenSymbol,
                              description,
                              imageUrl
                              // migrateFrom,
                            ),
                      setApplying
                    );

                    popup.success({
                      title: 'Apply Master',
                      text: 'Apply Master Success!',
                    });

                    navigate('/dashboard');
                  }}
                >
                  {applying
                    ? 'PROCESSING...'
                    : false && bnbBalance < 0.32
                    ? 'INSUFFICIENT BNB'
                    : false && copycatBalance < registrationFee
                    ? 'INSUFFICIENT COPYCAT'
                    : 'APPLY NOW'}
                </ButtonStyled>
              </>
            ) : (
              <ButtonStyled
                onClick={() => {
                  navigate('/wallet');
                }}
                className="w-full text-2xl h-full"
                color="secondary"
                disabled={false}
              >
                Connect Wallet
              </ButtonStyled>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyMaster;
