import web3 from 'hooks/web3';
import CopycatLeaderABI from './abi/CopycatLeader.json';
import CopycatLeaderReaderABI from './abi/CopycatLeaderReader.json';
import ERC20ABI from './abi/erc20.json';
import multicall from 'utils/multicall';
import { fromWei, toWei } from 'utils/unitConversion';
import { TokenView, allowedTokensMap } from 'config/tokens';
import CopycatLeaderReader from './CopycatLeaderReader';
import CopycatLeaderStorage from './CopycatLeaderStorage';
import CopycatDepositer from './CopycatDepositer';
import { CopyListData } from 'views/MasterList/copyListData';
import { ACTIVE_CHAIN_ID, getEnv } from 'utils/env';
import { BRIDGE_TOKEN } from 'components/BridgeTokenSelect/config';
import { getBnbPrice, getOrFetchNativePrice } from 'utils/bnbPrice';

export default class CopycatLeader {
  contract: any;
  address: any;
  from: any;

  constructor(address, from) {
    this.contract = new web3.eth.Contract(CopycatLeaderABI as any, address);
    this.address = address;
    this.from = from;
  }

  async balanceOf(address) {
    return await this.contract.methods.balanceOf(address).call();
  }

  async symbol() {
    return await this.contract.methods.symbol().call();
  }

  async owner() {
    return await this.contract.methods.owner().call();
  }

  async totalSupply() {
    return await this.contract.methods.totalSupply().call();
  }

  async getAdaptersBnb() {
    return await this.contract.methods.getAdaptersBnb().call();
  }

  async getAdapters(): Promise<string[]> {
    return await this.contract.methods.getAdapters().call();
  }

  async adapters(adapterId) {
    return await this.contract.methods.adapters(adapterId).call();
  }

  async approve(spender) {
    return await this.contract.methods
      .approve(spender, '10000000000000000000000000')
      .send({ from: this.from });
  }

  async getShareRatio() {
    return await new CopycatLeaderReader(this.from).getShareRatio(this.address);
  }

  async depositSimulate(amount) {
    const depositer = new CopycatDepositer(this.address, this.from);

    let shareRatio = fromWei(await this.getShareRatio());
    let totalSupply = fromWei(await this.totalSupply());
    let baseAmount = fromWei(amount) / shareRatio;
    let basePercentage = baseAmount / totalSupply;
    let percentage = basePercentage;

    while (percentage > basePercentage / 2) {
      try {
        await depositer.depositSimulate(
          amount,
          toWei(percentage),
          toWei(percentage / 2)
        );
        console.log(percentage);
        break;
      } catch (err) {
        console.error(err);
        percentage = percentage * 0.95;
        continue;
      }
    }

    let lPercentage = percentage * 0.98;
    let uPercentage = Math.min(basePercentage, (percentage / 0.95) * 1.02);

    while ((uPercentage - lPercentage) / uPercentage > 0.002) {
      let mPercentage = (lPercentage + uPercentage) / 2;

      try {
        await depositer.depositSimulate(
          amount,
          toWei(mPercentage),
          toWei(mPercentage / 2)
        );
        lPercentage = mPercentage;
        // console.log(percentage);
        // break;
      } catch (err) {
        console.error(err);
        uPercentage = mPercentage;
        continue;
      }
    }

    // console.log(percentage, lPercentage);

    try {
      await depositer.depositSimulate(
        amount,
        toWei(lPercentage),
        toWei(lPercentage)
      );
      return [
        toWei(lPercentage * totalSupply),
        toWei(lPercentage),
        toWei(lPercentage),
      ];
    } catch (err) {}

    let lFinalPercentage = lPercentage * 0.75;
    let uFinalPercentage = lPercentage;

    // console.log(percentage, basePercentage, lFinalPercentage);

    while ((uFinalPercentage - lFinalPercentage) / uFinalPercentage > 0.002) {
      let mFinalPercentage = (lFinalPercentage + uFinalPercentage) / 2;

      try {
        await depositer.depositSimulate(
          amount,
          toWei(lPercentage),
          toWei(mFinalPercentage)
        );
        lFinalPercentage = mFinalPercentage;
        // console.log(mFinalPercentage, (uFinalPercentage - lFinalPercentage) / uFinalPercentage);
      } catch (err) {
        console.error(err);
        uFinalPercentage = mFinalPercentage;
        continue;
      }
    }

    return [
      toWei(lFinalPercentage * totalSupply),
      toWei(lPercentage),
      toWei(lFinalPercentage),
    ];
  }

  async withdrawSimulate(amount, minBnb = 0) {
    return await this.contract.methods
      .withdraw(amount, minBnb)
      .call({ from: this.from });
  }

  async deposit(
    percentage,
    bnbAmount,
    refToken: any = getEnv("WETH_CONTRACT"),
    maxRefAmount = '1000000000000000000000000000'
  ) {
    return await this.contract.methods
      .depositTo(this.from, percentage, refToken, maxRefAmount)
      .send({
        from: this.from,
        value: bnbAmount,
      });
  }

  async withdraw(
    amount,
    refToken: any = getEnv("WETH_CONTRACT"),
    minRefToken: any = 0
  ) {
    return await this.contract.methods
      .withdrawTo(this.from, amount, refToken, minRefToken, false)
      .send({ from: this.from });
  }

  async toAdapter(adapterId, amount, minReceived: any = 0) {
    return await this.contract.methods
      .toAdapter(adapterId, amount, minReceived)
      .send({ from: this.from });
  }

  async toLeader(adapterId, percentage, minReceived: any = 0) {
    return await this.contract.methods
      .toLeader(adapterId, percentage, minReceived)
      .send({ from: this.from });
  }

  async upgrade(amount) {
    return await this.contract.methods
      .upgrade(amount)
      .send({ from: this.from });
  }

  async disable() {
    return await this.contract.methods.disable().send({ from: this.from });
  }

  async addToken(tokenAddress) {
    return await this.contract.methods
      .addToken(tokenAddress)
      .send({ from: this.from });
  }
}

export interface LeaderView {
  address: string;
  tier: number;
  tokenName: string;
  tokenSymbol: string;
  description: string;
  avatar: string;
  depositCopycatFee: number;
  depositPercentageFee: number;
  shareRatio: number;
  tvl: number;
  totalSupply: number;
  shareHolding: number;
  leaderHolding: number;
  owner: string;
  leaderType: string;

  copygameBaseTokenAddress?: string;
  copygameBaseTokenName?: string;
  copygameBaseTokenSymbol?: string;
  copygameBaseTokenDecimals?: number;
  copygameDepositLimit?: number;
}

export interface LeaderViewExtended extends LeaderView {
  tokens: TokenView[];
  bnbBalance: number;
  histories?: any[];
  apiData?: CopyListData;
}

export async function loadLeadersShareRatio(leaderAddresses) {
  const COPYCAT_LEADER_READER_ADDRESS = getEnv("LEADER_READER_CONTRACT");

  let calls: any[] = [];

  for (let leaderAddress of leaderAddresses) {
    calls.push([
      COPYCAT_LEADER_READER_ADDRESS,
      'getShareRatio',
      [leaderAddress],
    ]);
  }

  let response: any[] = await multicall(CopycatLeaderReaderABI, calls);

  let result = {};

  for (let i in response) {
    result[leaderAddresses[i]] = fromWei(response[i][0].toString());
  }

  return result;
}

export async function loadLeaders(
  leaderAddresses,
  wallet = '0x0000000000000000000000000000000000000000'
) {
  const COPYCAT_LEADER_READER_ADDRESS = getEnv("LEADER_READER_CONTRACT");
  
  let calls: any[] = [];
  // const CALL_LENGTH = 1;

  for (let leaderAddress of leaderAddresses) {
    let callsLocal = [
      [COPYCAT_LEADER_READER_ADDRESS, 'getLeaderData', [leaderAddress, wallet]],
    ];

    calls = calls.concat(callsLocal);
  }

  // console.log(calls);

  let response: any[] = await multicall(CopycatLeaderReaderABI, calls);

  // console.log(response);

  let result: LeaderView[] = [];

  for (let i = 0; i < leaderAddresses.length; i++) {
    let data = response[i][0];

    if (data.disabled) continue;

    let leaderAddress: string = leaderAddresses[i];

    result.push({
      address: leaderAddress,
      tier: parseInt(data.level.toString()) * 10,
      tokenName: data.tokenName,
      tokenSymbol: data.tokenSymbol,
      description: data.description,
      avatar: data.avatar,
      depositCopycatFee: fromWei(data.depositCopycatFee.toString()),
      depositPercentageFee: fromWei(data.depositPercentageFee.toString()),
      shareRatio: fromWei(data.shareRatio.toString()),
      tvl:
        fromWei(data.shareRatio.toString()) *
        fromWei(data.totalSupply.toString()),
      totalSupply: fromWei(data.totalSupply.toString()),
      shareHolding:
        wallet !== '0x0000000000000000000000000000000000000000'
          ? fromWei(data.shareHolding.toString())
          : 0,
      leaderHolding: fromWei(data.leaderHolding.toString()),
      owner: data.owner,
      leaderType: data.leaderType,
    });
  }

  result.sort((a, b) => (a.tier === b.tier ? b.tvl - a.tvl : b.tier - a.tier));

  // Filter all copygame leader
  const copygameLeaderAddresses = [];
  for (let i = 0; i < result.length; i++) {
    if (result[i].leaderType == 'game' || result[i].leaderType == 'game_mirror') {
      copygameLeaderAddresses.push([result[i].address, i]);
    }
  }

  // Load copygame base token information
  if (copygameLeaderAddresses.length > 0) {
    const copygameBaseTokens = await new CopycatLeaderReader(wallet).getMultipleCopygameBaseToken(copygameLeaderAddresses.map(x => x[0]));

    for (let i = 0; i < copygameBaseTokens.length; i++) {
      const data = result[copygameLeaderAddresses[i][1]]
  
      data.copygameBaseTokenAddress = copygameBaseTokens[i].tokenAddress;
      data.copygameBaseTokenName = copygameBaseTokens[i].tokenName;
      data.copygameBaseTokenSymbol = copygameBaseTokens[i].tokenSymbol;
      data.copygameBaseTokenDecimals = copygameBaseTokens[i].decimals;
      data.copygameDepositLimit = copygameBaseTokens[i].depositLimit;
  
      if (data.copygameBaseTokenAddress.toLowerCase() == getEnv("WETH_CONTRACT").toLowerCase()) {
        data.copygameBaseTokenSymbol = data.copygameBaseTokenSymbol.substring(1);
      } else {
        // Fix copygame TVL
        for (let tokenSymbol in BRIDGE_TOKEN) {
          if (BRIDGE_TOKEN[tokenSymbol].tokenAddresses[ACTIVE_CHAIN_ID].toLowerCase() == data.copygameBaseTokenAddress.toLowerCase()) {
            switch (tokenSymbol) {
              case 'WBNB':
                data.tvl = data.totalSupply * await getOrFetchNativePrice('BNBUSDT') / await getBnbPrice();
                break;

              case 'WETH':
                data.tvl = data.totalSupply * await getOrFetchNativePrice('ETHUSDT') / await getBnbPrice();
                break;

              case 'WAVAX':
                data.tvl = data.totalSupply * await getOrFetchNativePrice('AVAXUSDT') / await getBnbPrice();
                break;

              case 'WMATIC':
                data.tvl = data.totalSupply * await getOrFetchNativePrice('MATICUSDT') / await getBnbPrice();
                break;
            }
          }
        }
      }
    }
  }

  return result;
}

export async function loadLeaderTokens(leaderAddress) {
  let response: any[] = await new CopycatLeaderReader(null).getLeaderTokens(
    leaderAddress
  );

  console.log(response);

  let result: TokenView[] = [];

  for (let i = 0; i < response.length; i++) {
    let data = response[i];

    console.log(data);

    let tokenFromDb: any = allowedTokensMap[data.tokenAddress.toLowerCase()];

    if (!tokenFromDb) {
      tokenFromDb = {
        tokenAddress: data.tokenAddress,
        tokenName: 'LP',
        tokenSymbol: 'LP',
        logo: null,
        decimals: 18,
      };
    }

    result.push({
      ...tokenFromDb,
      adapterAddress: data.tokenAddress,
      adapterType: 'uniswapv2',
      adapterId: i,
      swapAddress: data.routerAddress,
      tokenAddress: data.tokenAddress,
      pairId: 0,
      bnbValue: fromWei(data.bnbValue),
      balance: fromWei(data.balance, tokenFromDb.decimals),
      tokenName: data.tokenName,
      tokenSymbol: data.tokenSymbol,
      decimals: data.decimals,
      logo:
        tokenFromDb.logo ||
        'https://assets.trustwalletapp.com/blockchains/smartchain/assets/' +
          data.tokenAddress +
          '/logo.png',
    });
  }

  return result;
}

export async function loadLeaderGameHarvestTokens(leaderAddress) {
  let response: any[] = await new CopycatLeaderReader(null).getLeaderTokens(
    leaderAddress
  );

  console.log(response);

  let result: TokenView[] = [];

  for (let i = 0; i < response.length; i++) {
    let data = response[i];

    console.log(data);

    let tokenFromDb: any = allowedTokensMap[data.tokenAddress.toLowerCase()];

    if (!tokenFromDb) {
      tokenFromDb = {
        tokenAddress: data.tokenAddress,
        tokenName: 'LP',
        tokenSymbol: 'LP',
        logo: null,
        decimals: 18,
      };
    }

    result.push({
      ...tokenFromDb,
      adapterAddress: data.tokenAddress,
      adapterType: 'uniswapv2',
      adapterId: i,
      swapAddress: data.routerAddress,
      tokenAddress: data.tokenAddress,
      pairId: 0,
      bnbValue: fromWei(data.bnbValue),
      balance: fromWei(data.balance, tokenFromDb.decimals),
      tokenName: data.tokenName,
      tokenSymbol: data.tokenSymbol,
      decimals: data.decimals,
      logo:
        tokenFromDb.logo ||
        'https://assets.trustwalletapp.com/blockchains/smartchain/assets/' +
          data.tokenAddress +
          '/logo.png',
    });
  }

  return result;
}

export async function loadTokensBalance(account, tokenAddresses, spender) {
  let calls: any[] = [];
  const CALL_LENGTH = 3;

  for (let tokenAddress of tokenAddresses) {
    let callsLocal = [
      [tokenAddress, 'balanceOf', [account]],
      [tokenAddress, 'decimals', []],
      [tokenAddress, 'allowance', [account, spender]],
    ];

    calls = calls.concat(callsLocal);
  }

  let response: any[] = await multicall(ERC20ABI, calls);

  console.log(response);

  let result: { tokenAddress: string; balance: number; allowance: number }[] =
    [];

  for (let i = 0; i < tokenAddresses.length; i++) {
    result.push({
      tokenAddress: tokenAddresses[i],
      balance: fromWei(
        response[i * CALL_LENGTH][0].toString(),
        parseInt(response[i * CALL_LENGTH + 1][0].toString())
      ),
      allowance: fromWei(
        response[i * CALL_LENGTH + 2][0].toString(),
        parseInt(response[i * CALL_LENGTH + 1][0].toString())
      ),
    });
  }

  return result;
}

export async function loadLeaderAdapterAddresses(
  leaderAddress,
  adapterType = null
) {
  let result = adapterType
    ? await new CopycatLeaderStorage(null).getAdaptersSpecificType(
        leaderAddress,
        adapterType
      )
    : await new CopycatLeaderStorage(null).getAdapters(leaderAddress);
  result = result.filter(
    (c) => c != '0x0000000000000000000000000000000000000000'
  );

  return result;
}
