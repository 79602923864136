import { Spin } from "antd";
import SelectBox from "components/SelectBox/SelectBox";
import { LeaderView, loadLeaders } from "contracts/CopycatLeader";
import { loadOwnedLeaderAddresses } from "contracts/CopycatLeaderFactory";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { uniq } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "utils/api";
import { ACTIVE_CHAIN_ID } from "utils/env";
import { getLocal, removeLocal, setLocal } from "utils/localStorage";
import { SUPPORTED_CHAIN_LIST } from "utils/supportedChains";

export default function ChainSelect({ onChange }) {
  const { account } = useActiveWeb3React();
  const [selectedChain, setSelectedChain] = useState(ACTIVE_CHAIN_ID);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      console.log(selectedChain)
      onChange(selectedChain);
    }
  }, [selectedChain, loading])

  if (loading) {
    return (
      <Spin size="large"></Spin>
    )
  }

  return (
    <SelectBox 
      placeholder={loading ? "Loading..." : "Select chain"}
      option={
        SUPPORTED_CHAIN_LIST.map(chain => ({
          value: chain.chainId.toString(),
          label: chain.chainName,
        }))
      }
      onChange={(chainId) => setSelectedChain(chainId)}
      value={selectedChain}
    />
  )
}